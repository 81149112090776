import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./PrivacyPolicy.css";
import vectorImage from "../../assets/images/Vector.png"

function PrivacyPolicy() {
  const [activeNumber, setActiveNumber] = useState(2);
  const [expanded, setExpanded] = useState([]);

  const handleToggle = (index) => {
    setExpanded((prevExpanded) => {
      const newExpanded = [...prevExpanded];
      newExpanded[index] = !newExpanded[index];
      return newExpanded;
    });
    setActiveNumber(index + 1);
  };

  const termData = [
    {
      text: "Privacy Policy",
      content:
        "At Xcelsz, we are devoted to keeping our users's and client's information private. This Privacy Policy explains how we collect, use, disclose, and protect the information we receive from you when you use our products and services.",
    },
    {
      text: "Usage Information",
      content: (
        <div className="service-description">
          Usage information includes information about your interactions with
          our products and services, such as the features you use, the pages you
          visit, and the content you view or download. This information helps us
          understand how our users use our products and services, and improve
          them accordingly.
        </div>
      ),
    },

    {
      text: "Information We Collect",
      content: (
        <div className="eligibility-description">
          {" "}
          Personal Information: Personal information includes information that
          can be used to identify you, such as your name, address, email
          address, phone number, and other contact information. We collect this
          information when you create an account with us, subscribe to our
          services, or engage with us through our website or customer support
          channels.
        </div>
      ),
    },

    {
      text: "Technical Information",
      content: (
        <div className="use-of-website-description">
          Technical information includes information about your device and
          network, such as your device type, operating system, IP address, and
          other technical information. This information helps us diagnose
          problems and provide better support.
        </div>
      ),
    },

    {
      text: "Location Information",
      content: (
        <div className="user-content-description">
          Location information includes information about your location, such as
          your city, state, or country. This information helps us provide you
          with more relevant content and services.
        </div>
      ),
    },

    {
      text: "How We Use Your Information",
      content: (
        <div className="intellectual-property-description">
          To provide and improve our products and services: We use your
          information to provide you with the products and services you request,
          and to improve and personalize those products and services.
        </div>
      ),
    },

    {
      text: "To communicate with you",
      content: (
        <div className="disclaimers-description">
          We use your information to communicate with you about your account and
          our products and services and to respond to your questions and
          requests
        </div>
      ),
    },

    {
      text: "To comply with legal obligations",
      content: (
        <div className="limitation-description">
          We may use your information to comply with applicable laws and
          regulations and to respond to lawful requests from government
          authorities.
        </div>
      ),
    },

    {
      text: "Disclosure of Your Informarion",
      content: (
        <div className="indemnification-description">
          To Third-Party Service Providers: We may share your information with
          third-party service providers who perform services on our behalf, such
          as hosting and maintaining our website, processing payments, and
          providing customer support. These service providers are required to
          maintain your information's confidentiality and may only use it to
          offer their services.
        </div>
      ),
    },

    {
      text: "To Business Partners",
      content: (
        <div className="terms-conditions-description">
          We may share your information with our business partners for marketing
          and i promotional purposes, but only with your consent.
        </div>
      ),
    },

    {
      text: "In connection with a Business Transaction",
      content: (
        <div className="governing-law-description">
          If we merge with another company, or if we are acquired by another
          company, your information i may be transferred to the new owner as a
          part of the transaction.
        </div>
      ),
    },

    {
      text: "As Required by Law",
      content: (
        <div className="dispute-description">
          We may disclose your information as required by law, such as in
          response to a subpoena, court order, or another legal process.
        </div>
      ),
    },

    {
      text: "Data Security",
      content: (
        <div className="miscellaneous-description">
          We take precautions that are reasonable to prevent unauthorized
          access, use, or disclosure of personal information. We cannot,
          however, guarantee the security of your information because no
          security mechanism is faultless. Please get in touch with us right
          once if you have any cause to think that your information is no longer
          safe.
        </div>
      ),
    },

    {
      text: "Your Choices",
      content: (
        <div className="choices-description">
          Access and Correction: You have the right to access and correct your
          personal information. You can do this by logging into your account, or
          by contacting us through our customer support channels.
        </div>
      ),
    },

    {
      text: "Deletion",
      content: (
        <div className="deletion-description">
          You have the right to delete your personal information, subject to
          certain exceptions prescribed by law. If you would like to delete your
          information, please contact us through our customer support channels.
        </div>
      ),
    },

    {
      text: "Marketing Communications",
      content: (
        <div className="marketing-description">
          These terms and conditions constitute the entire agreement between you
          and the company with respect to your use of the website. The other
          terms and conditions shall remain in full force and effect if any
          provision of these terms and conditions is determined to be defective
          or unenforceable.
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="backgroundo1"></div>
      <section className="privacy-top">

        <div className="dpd-background-contain1">
          <img src={vectorImage} alt="Vector" className="dpd-background-imag" />
        </div>
        <div className="privacy-top-left">
          <h1 className="privacy-heading">Privacy Policy</h1>
          <h1 className="privacy_text">
              Protecting your privacy is
            <br />important to us
          </h1>
        </div>
      </section>

      <section className="types">
        <div className="types_border">
          <h2>Select Our Terms Type</h2>

          <div className="numbers">
            <Link
              to="./terms"
              className={`num ${activeNumber === 1 ? "active" : ""}`}
            >
              1
            </Link>
            <Link
              to="./privacyPolicy"
              className={`num ${activeNumber === 2 ? "active" : ""}`}
            >
              2
            </Link>
            <Link
              to="./cookies"
              className={`num ${activeNumber === 3 ? "active" : ""}`}
            >
              3
            </Link>
          </div>
        </div>
      </section>

      <p className="below_text">Who We Are and Scope of this Privacy Policy</p>

      {termData.map((term, index) => (
        <React.Fragment key={index}>
          <div className={`term_border ${expanded[index] ? "expanded" : ""}`}>
            <div className="expand-btn" onClick={() => handleToggle(index)}>
              {expanded[index] ? (
                <span className="minus">-</span>
              ) : (
                <span className="plus">+</span>
              )}
            </div>
            <span className="text">{term.text}</span>
            {expanded[index] && (
              <div
                className={`dropdown_border ${
                  index === 1 ? "service-description-dropdown" : ""
                } ${index === 2 ? "eligibility-description-dropdown" : ""} ${
                  index === 3 ? "use-of-website-description-dropdown" : ""
                } ${index === 4 ? "user-content-description-dropdown" : ""} ${
                  index === 5
                    ? "intellectual-property-description-dropdown"
                    : ""
                } ${index === 6 ? "disclaimers-description-dropdown" : ""} ${
                  index === 7 ? "limitation-description-dropdown" : ""
                } ${
                  index === 8 ? "indemnification-description-dropdown" : ""
                } ${
                  index === 9 ? "terms-conditions-description-dropdown" : ""
                } ${index === 10 ? "governing-law-description-dropdown" : ""} ${
                  index === 11 ? "dispute-description-dropdown" : ""
                } ${index === 12 ? "miscellaneous-description-dropdown" : ""} ${
                  index === 13 ? "choices-description-dropdown" : ""
                } ${index === 14 ? "deletion-description-dropdown" : ""}${
                  index === 15 ? "marketing-description-dropdown" : ""
                }`}
              >
                <span className="the_text">{term.content}</span>
              </div>
            )}
          </div>
          <div className="line"></div>
          {index === 1 && (
            <p className="information-text">Information we collect about you</p>
          )}

          {index === 4 && (
            <p className="information-text">How we use your data</p>
          )}
        </React.Fragment>
      ))}
    </>
  );
}

export default PrivacyPolicy;
