import './FinancialInstitutions.css';
import { useState, useRef } from "react";

import userData from '../../assets/images/user-data 1.svg';
import building from '../../assets/images/buildings 1.svg';
import virtual from '../../assets/images/virtual 1.svg';
import assist from '../../assets/images/assistance 2.svg';
import dArrow from '../../assets/images/double-arrow.webp';
import standing from '../../assets/images/standing.png';
import close from '../../assets/images/closeBtn.png';
import navArrow from '../../assets/images/bi_arrow-right-circle-fill.svg';
import dropDownArrow from '../../assets/images/yellow-dropdown.svg';
import referFriend from '../../assets/images/refer-a-friend-bro.svg';
import rectangle_why_us_fin from '../../assets/images/Rectangle-fin-why-us.png';
import loan_why_us_fin from '../../assets/images/loan-why-us-fin.png';
import why_us_get_offer_fin from '../../assets/images/fin-why-us-get-offer-img.png';

import emailjs from '@emailjs/browser';


function FinancialInstitutions () {

    const [index, setIndex] = useState(0);
    const [emailValid, setEmailValid] = useState();
    const [location, setLocation] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [copyLink, setCopyLink] = useState();
    const [subConfirm, setSubConfirm] = useState();
    const [dropDown, setDropDown] = useState(0);

    const email = useRef();
    const text = useRef();
    const country = useRef();

    const pageOrigin = window.location.origin

    // Button slider
    const setFirstSlide = () => {
        setIndex(0)
    }

    const setSecondSlide = () => {
        setIndex(1)
    }

    const setThirdSlide = () => {
        setIndex(2)
    }

    const setFourthSlide = () => {
        setIndex(3)
    }


     //Next Btn function
     const nextSlide = () => {
        
        if (index === 3){
            setIndex(0)
        }
        else if (index < 3){
            setIndex(index+1)
        }   
    }
    

    //prev Btn function
    const prevSlide = () => {

        if (index > 0 ){
            setIndex(index-1)
        }
        else if (index === 0) {
            setIndex(3)
        }
    }

    const closeSubConfirm = (e) =>{
        e.preventDefault();
        console.log("Subscription confirm closed");
        setSubConfirm();
    }


    const ValidateEmail = (input) => {

        const validEmail = /^[A-Za-z\._\-0-9]*[@][A-Za-z]*[\.][a-z]{2,4}$/;
        const emailtxt = document.querySelector(".fin-inst-email");
        const error = document.querySelector(".fin-inst-error");

        if (input === "" || input.match(validEmail)) {
            error.style.display = "none";
            // emailtxt.style.outline = '1px solid #fac710';
            emailtxt.style.border = '2px solid #fac710';
            setEmailValid(1);
            // return true;
        } else {

          console.log("Invalid email address!");
          error.style.display = "block";
        //   emailtxt.style.outline = "2px solid red";
          emailtxt.style.border = '2px solid red';
          setEmailValid(0);
        //   return false;

        }

      }

    const clearField = () => {
        email.current.value = '';
        text.current.value = '';
        country.current.value = 'Select Location';
    }

    const form = useRef();

    const submitFinInstForm = (e)=>{
        e.preventDefault();

        if (emailValid){

            emailjs.sendForm('service_lrekcyz', 'template_0sgyoak', form.current, 'cm_1OMdDgstBwtAkp')
            .then((result) => {
                console.log('sent');
                setSubConfirm(1);
                // clearField();
                setLocation('');

            }, (error) => {
                console.log(error);
                console.log('not sent');
            });

        }else{
            console.log("Invalid email")
        }

    }

    const locDropDown = () => {
        if (dropDown === 0){
            setDropDown(1);
        }else {
            setDropDown(0)
        }
    }

    const selectLocation = (i) => {
        if (i === 0){
            setLocation('Ghana');
        }
        else if (i === 1) {
            setLocation('United Kingdom');
        }
        else if (i === 2) {
            setLocation('Nigeria');
        }
        setDropDown(0);
    }

    const subForm = () => {
        if (emailValid && location /* && textArea */ ){
            setSubConfirm(1);
            setLocation('');
        }
    }

    // Disappearing Copied Link Alert
    const copyReferral = () => {
        navigator.clipboard.writeText(pageOrigin + '/fdOwners');
        setCopyLink(1);
        window.setTimeout(
            
            function removethis()
            {
                setCopyLink(0)
            }, 4000);          
    }

    return(
        <>

            <div className="fin-inst-wrapper"> 
                <section className="fin-inst-top">
                    <div className="fin-inst-top-left">
                        <h1 className="fin-inst-heading">
                            Hassle Free Debt Recovery
                            Made <span className="yellow"> Easy</span>  
                        </h1>
                        <h3>
                        Your perfect solution to increasing customer satisfaction available right away.
                        </h3>
                        <div className="fin-inst-container">
                            <img src={userData} alt=""/>
                            <p>Avoid legal disputes over financial recovery</p>
                        </div>
                        <div className="fin-inst-container">
                            <div>
                                <img src={building} style={{borderRadius: '5px'}} alt=""/>
                            </div>
                            <p>No hassle to you</p>
                        </div>
                        <div className="fin-inst-container">
                            <img src={virtual} alt=""/>
                            <p>No more defaulted finances and court fees</p>
                        </div>
                    </div>
                    <div className="fin-inst-background">

                    </div>
                </section>

                <section className="fin-inst-slide-section">
                    <div className="fin-inst-head">
                        <h2>
                            Direct Buy, Lease Option, Guaranteed Rent, whatever fits your situation
                            <span className="yellow"> ...</span>..
                        </h2>
                    </div>
                    <div className='fin-inst-slider'>
                        {
                            index === 0 &&
                            <div className='fin-inst-slider-headers'>
                                <div className="fin-inst-slider-head">
                                    <p>You know someone who can use the Financial Institution Solution?</p>
                                </div>
                                <div className="fin-inst-slider-head" style={{background: '#fac710'}}>
                                    <p style={{width: '660px', fontSize: '35px', lineHeight: '42px'}}>Get them on the Financial Institutions'  VIP List Now</p>
                                </div>
                            </div>
                        }
                        {
                            index === 1 &&
                            <div className='fin-inst-slider-headers'>
                                <div className="fin-inst-slider-head" style={{background: '#fac710'}}>
                                <p style={{fontSize: '60px', lineHeight: '72px'}}>Why Choose TPV</p>
                                </div>
                                <div className="fin-inst-slider-head">
                                    <p style={{fontSize: '30px'}}>We take on any property, any location, any condition</p>
                                </div>
                            </div>
                        }
                        {
                            index > 1 &&
                            <div className='fin-inst-slider-headers'>
                                <div className="fin-inst-slider-head" style={{background: '#fac710'}}>
                                <p style={{fontSize: '41px', width: '100%'}}>Whatever the circumstance may be, Stop Repossession</p>
                                </div>
                                <div className="fin-inst-slider-head">
                                    <p style={{fontSize: '36px', width: '712px'}}>You do not need to wait until you repossess your customers Property</p>
                                </div>
                            </div>
                        }
                        <div className="fin-inst-main-slider">
                            <div className="fin-inst-slide-container" >
                                <div className="fin-inst-slide-inner-container">
                                    {
                                        index === 0 &&
                                        <div style={{width: 'content', height: 'content'}}>
                                            {
                                                !subConfirm &&
                                                <div className='fin-inst-customer-review-card'  style={{height: '429px', display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
                                                    <form className='fin-inst-form' ref={form} onSubmit={submitFinInstForm}>
                                                        <div className='email-subject' style={{display: 'none'}}>
                                                            <input type='text' name='header' value={'Financial Institution VIP'} readOnly />
                                                        </div>
                                                        <div className='fin-inst-input-field'>
                                                            <div className='fin-inst-case-number'  style={{width: '80px', height: '60px'}}>1</div>
                                                            
                                                            {/* Default Dropdown Menu */}
                                                            {/* <select name='location' onChange={(e)=>setLocation(e.target.value)} required> 
                                                                <option value=''>Select Location</option>
                                                                <option value='Ghana'>Ghana</option>
                                                                <option value='United Kingdom'>United Kingdom</option>
                                                                <option value='Nigeria'>Nigeria</option>
                                                            </select> */}
                                                            
                                                            {/* Customized Dropdown Menu */}
                                                            <div className='fin-select-field'>
                                                                <input type='text' name='location' className='select-input' value={location} onClick={locDropDown} placeholder='Select Location' readOnly required/>
                                                                <img id="fin-inst-img-drop-down" className={dropDown === 1 ? 'activee' : ''} onClick={locDropDown} src={dropDownArrow} alt='dropdown' />
                                                                {
                                                                    dropDown === 1 &&
                                                                    <div className='fin-inst-select'>
                                                                        <ul className='select'>
                                                                            <li className='select-option' onClick={(e)=>{selectLocation(0)}}>Ghana</li>
                                                                            <li className='select-option' onClick={(e)=>{selectLocation(1)}}>United Kingdom</li>
                                                                            <li className='select-option' onClick={(e)=>{selectLocation(2)}}>Nigeria</li>
                                                                        </ul>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className='fin-inst-input-field'>
                                                            <div className='fin-inst-case-number' style={{width: '80px', height: '60px'}}>2</div>
                                                            <input type='email' name='user_email' className='fin-inst-email' placeholder='Input Your Email' onChange={(e)=>setUserEmail(e.target.value)} onKeyUp={(e)=>ValidateEmail(e.target.value)} required />
                                                        </div>
                                                        <span className="fin-inst-error">please enter a valid email: example@domain.com</span>
                                                        {/* <div className='fin-inst-input-field'>
                                                            <div className='fin-inst-case-number' style={{width: '80px', height: '60px'}}>3</div>
                                                            <textarea type='textarea' name='about_situation' placeholder='About you and thier Situations' onChange={(e)=>{setTextArea(e.target.value)}} required></textarea>
                                                        </div> */}
                                                        {/* Uncomment line below to submit form to submit form directly to tpv@xcelsz.com*/}
                                                        <input type='submit' value='Send' className='fin-inst-submit'/>
                                                    </form>
                                                    {/* Comment this out when using form to submit directly to tpv@xcelsz.com */}
                                                    {/* <a style={{pointerEvents: `${emailValid && location && userEmail ? '' : 'none'}`}} href={`mailto:tpv@xcelsz.com?subject=Financial%20Institution%20VIP&body=Location:%20${location}%20Email:%20${userEmail}`} rel="noreferrer" target="_blank">
                                                        <button onClick={subForm} className={`fin-inst-btn ${emailValid && location && userEmail ? 'fin-inst-btn-h' : ''}`} type='submit'>Send</button>
                                                    </a> */}
                                                </div>
                                            }

                                            {
                                                subConfirm &&
                                                <div className='fin-inst-confirm-wrapper'>
                                                    <div className='fin-inst-cfm'>
                                                        <div className='fin-inst-cfm-inner'>
                                                            <button type="reset" onClick={closeSubConfirm}>
                                                                <img className="close-confirm" src={close} alt="cancel"/>
                                                            </button>
                                                            <p>Thank you for submitting your request. You'll hear from us with a free no obligation offer within 48 hours.</p>
                                                        </div>
                                                    </div>
                                                    <div className='fin-inst-cfm-img'>
                                                        <img src={standing} alt='' />
                                                    </div>

                                                </div>
                                            }
                                            
                                        </div>                                     
                                        
                                    }
                                    {
                                        index === 1 &&
                                        <div className='fin-inst-second-slide-main'>
                                            <div className="fin-inst-second-slide-div"style={{display: 'flex', alignItems: 'center'}}>
                                                <div className='fin-inst-second-slide'>
                                                    <div className='fin-inst-ss-head'>
                                                        <div>
                                                            <img src={dArrow} alt='' />
                                                        </div>
                                                    </div>
                                                    <p>
                                                        Increase your customer’s experience
                                                    </p>                                                
                                                </div>
                                                <div className='fin-inst-second-slide-img'>
                                                    <img src={assist} alt='' />
                                                </div>
                                                <div className='fin-inst-second-slide'>
                                                    <div className='fin-inst-ss-head'>
                                                        <div>
                                                            <img src={dArrow} alt='' />
                                                        </div> 
                                                    </div>
                                                    <p style={{width: '222px'}}>
                                                        We work according to your timeline
                                                    </p>                                                
                                                </div> 
                                            </div>
                                            
                                            <div className='fin-inst-second-slide'>
                                                <div className='fin-inst-ss-head'>
                                                    <div>
                                                        <img src={dArrow} alt='' />
                                                    </div> 
                                                </div>
                                                <p>
                                                    We have a tailored solution to meet your needs
                                                </p>                                                
                                            </div>
                                        </div>
                                    }
                                    {
                                        index === 2 &&
                                        <div className='fin-inst-third-slide-main'>
                                            <div className='fin-inst-third-slide'>
                                                <div className='fin-inst-ss-head'>
                                                    <p>Tailored Solutions</p> 
                                                </div>
                                                <p>
                                                    Superb solutions Tailored to You and Your Customers Needs
                                                </p>                                                
                                            </div>
                                            <div className='fin-inst-third-slide'>
                                                <div className='fin-inst-ss-head'>
                                                    <p>Tailored Offers</p> 
                                                </div>
                                                <p>
                                                    Amazing Offers Tailored to You and Your Customers Needs
                                                </p>                                                
                                            </div> 
                                        </div>
                                    }

                                    {
                                        index === 3 &&
                                        <div className='fin-inst-f-slide-main'>
                                            <div className='fin-inst-f-img'>
                                                <img src={referFriend} alt='' />                                                  
                                            </div>
                                            <div className='fin-inst-f-slide'>
                                                <div className='fin-inst-ss-head'>
                                                     
                                                </div>
                                                <button className='fin-inst-f-circle' onClick={copyReferral}>
                                                    <span>
                                                        Refer Now
                                                    </span>
                                                </button>                           
                                                <p>
                                                    It's easy to refer your customers! Simply click to copy referral link and share via email, social media, or messaging apps
                                                </p>                                                
                                            </div> 
                                        </div>
                                    }
                                    
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="fin-inst-slide-btn">
                        <button className={index === 0 ? 'activee' : ''} onClick={setFirstSlide}></button>
                        <button className={index === 1 ? 'activee' : ''} onClick={setSecondSlide}></button>
                        <button className={index === 2 ? 'activee' : ''} onClick={setThirdSlide}></button>
                        <button className={index === 3 ? 'activee' : ''} onClick={setFourthSlide}></button>
                    </div>

                    <button className="fin-inst-control-previous" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev" onClick={prevSlide}>
                        <img alt="previous" id="fin-inst-leftslider"className="leftSlider" src={navArrow}/>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="fin-inst-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next" onClick={nextSlide}>
                        <img alt="next" className="fin-inst-rightslider" src={navArrow}/>
                        <span className="visually-hidden">Next</span>
                    </button>
                </section>
                <section className='fin-inst-why-us-only-mb'>
                    <h2> Why Us?</h2>
                    <div className='fin-inst-why-us-bg-white'>
                        <div className='fin-inst-why-us-rectangle'>
                            <img src={rectangle_why_us_fin} className='fin-inst-why-us-img'></img>
                            <img src={loan_why_us_fin} className='fin-inst-why-us-img-loan'></img>
                            <p className='fin-inst-why-us-p'>We are professional independent homebuyers 
                            and  relocation specialist, and because we make direct to you the Home Owner, we can present 
                            you with great offers you can't miss, with <span className='fin-inst-why-us-span'>ZERO</span> fees to you.</p>
                            <p className='fin-inst-why-us-readmore'>
                                <a href='/about'>READ MORE</a>
                            </p>
                        </div>
                        <div className='fin-inst-why-us-get-offer'>
                                    <img src={why_us_get_offer_fin} className='fin-inst-why-us-get-offer-img'></img>
                                    <p className='fin-inst-why-us-get-offer-p'> Get offer within 48 hours</p>
                        </div>
                        <div className='fin-inst-why-us-get-offer'>
                        <img src={why_us_get_offer_fin} className='fin-inst-why-us-get-offer-img'></img>
                        <p className='fin-inst-why-us-get-offer-p'> Choose completion date suitable to YOU.</p>

                        </div>

                    </div>
                </section>

                {
                    copyLink && 
                    <div className='fin-inst-referral'>Link Successfully Copied</div>
                }

            </div>
        </>
    );

}

export default FinancialInstitutions;