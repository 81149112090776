const InputField = ({ number, inputTag, inputClass="input-field", colorClass="number-field" }) => {
    return (
        <div className={inputClass}>
            <span className={colorClass}>
                {number}
            </span>
            {inputTag}
        </div>
    )
}

export default InputField
