import { useState, useRef } from 'react'
import './MakeMeAnOffer.css'
import './my_make_offer.css'
import cardImage1 from '../../assets/images/user-data 1.svg'
import cardImage2 from '../../assets/images/buildings 1.svg'
import cardImage3 from '../../assets/images/virtual 1.svg'
import ellipse from '../../assets/images/Ellipse 30.svg'
import rightSectionImage from '../../assets/images/illustration.svg'
import backgroundPearShape from '../../assets/images/pear-shaped.svg'
import { useFormik } from 'formik';
import CustomSelect from '../stopRepossession/CustomSelect'
import emailjs from '@emailjs/browser';
import mobilePear from "../../assets/images/mobile-pear.svg"

//imported images
import Eurosign from '../../assets/images/Euro-sign.png'
import Cedissign from '../../assets/images/Cedis-sign.png'
import Nairasign from '../../assets/images/Naira-sign.png'
import RepossessionCard from '../stopRepossession/RepossessionCard';
import options from "../../config/SelectData"
import OfferCta from './OfferCta';

function MakeMeAnOffer() {
    const [currency, setCurrency] = useState(Eurosign);
    const [warning, setWarning] = useState(false)
    const [formIndex, setFormIndex] = useState(0)
    const iAmRef = useRef()
    const uploadImageRef = useRef()
    const uploadDocsRef = useRef()
    const formik = useFormik({
        initialValues: {
            offerType: "",
            clientType: "",
            country: "",
            propertyType: "",
            purchaseDate: "",
            numOfBedrooms: "",
            propertyState: "",
            lastRenovation: "",
            buildYear: "",
            onTheMarket: "",
            sellingSoon: "",
            sellingFast: "",
            address: "",
            streetName: "",
            region: "",
            district: "",
            area: "",
            name: "",
            email: "",
            propertyImage: null,
            propertyDocuments: null,
            viewDate: "",
            findOutAboutUs: ""
        },
    })
    const handleDataCollection = () => {
        if (formIndex === 3) {
            if (formik.values.offerType === "") {
                console.log("prop image", formik.values.propertyImage)
                setWarning(true)
                setTimeout(() => {
                    setWarning(false)
                }, 3000);
                iAmRef.current.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
                return
            } else if (uploadImageRef.current.state.files.length === 0) {
                const imageUpload = document.getElementById('image-upload')
                imageUpload.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
                imageUpload.setAttribute("style", "border-color: red")
                setTimeout(() => {
                    imageUpload.setAttribute('style', 'border-color: rgba(0, 0, 0, 0.08)')
                }, 1000);
                return
            } else if (uploadDocsRef.current.state.files.length === 0) {
                const docsUpload = document.getElementById('docs-upload')
                docsUpload.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
                docsUpload.setAttribute("style", "border-color: red")
                setTimeout(() => {
                    docsUpload.setAttribute('style', 'border-color: rgba(0, 0, 0, 0.08)')
                }, 1000);
                return
            }
            const templateParams = {
                offerType: formik.values.offerType,
                clientType: formik.values.clientType,
                country: formik.values.country,
                propertyType: formik.values.propertyType,
                numOfBedrooms: formik.values.numOfBedrooms,
                propertyState: formik.values.propertyState,
                lastRenovation: formik.values.lastRenovation,
                buildYear: formik.values.buildYear,
                onTheMarket: formik.values.onTheMarket,
                sellingSoon: formik.values.sellingSoon,
                sellingFast: formik.values.sellingFast,
                address: formik.values.address,
                streetName: formik.values.streetName,
                region: formik.values.region,
                district: formik.values.district,
                name: formik.values.name,
                email: formik.values.email,
                propertyImage: formik.values.propertyImage,
                propertyDocuments: formik.values.propertyDocuments,
                viewDate: formik.values.viewDate,
                findOutAboutUs: formik.values.findOutAboutUs,
            }
            emailjs.send('service_lrekcyz', 'template_r2bnnhn', templateParams, 'cm_1OMdDgstBwtAkp')
                .then((result) => {
                    console.log('SUCCESS!', result.status, result.text)
                    setFormIndex(formIndex + 1)
                }, (error) => {
                    console.log('FAILED...', error);
                });
            console.log(formik.values)
        }
        else {

            setFormIndex(formIndex + 1)
            if (formIndex < 2) {
                switch (formik.values.country) {
                    case 'Ghana':
                        setCurrency(Cedissign)
                        break;
                    case 'UK':
                        setCurrency(Eurosign)
                        break;
                    case 'NigersetFormIndexia':
                        setCurrency(Nairasign)
                        break;
                    default:
                        break;
                }
            }
        }
    }
    const reverseStep = () => {
        setFormIndex(formIndex - 1)
    }
    return (
        <>
            <section className='mobile-first-section'>
                <h1 className='heading max-content'>Get a Free no obligation <span className='yellow-text'>Offer</span>.</h1>
                <img src={rightSectionImage} alt='woman stressed in image' />
                <h5 className='sub-heading'>At absolutely no cost you can request an offer on your home at The Property Vendee (TPV).</h5>
                <RepossessionCard image={cardImage1} text={"Provide Personal Details"} backColor={"var(--white-background)"} foreColor={"var(--navbar-color)"} />
                <RepossessionCard image={cardImage2} text={"Provide Property Details"} backColor={"var(--navbar-color)"} foreColor={"var(--white-background)"} extra={ellipse} />
                <RepossessionCard image={cardImage3} text={"Arrange Virtual Viewing"} backColor={"#FFECB2"} foreColor={"#111111"} />
                <div className='offer-sr-bg-pear'>
                    <img src={mobilePear} alt='bg-pear' />
                </div>
            </section>
            <section className="first-section offer-page">
                <div className='left-section'>
                    <h1 className='heading max-content'>Get a Free no obligation <span className='yellow-text'>Offer</span>.</h1>
                    <h5 className='sub-heading'>At absolutely no cost you can request an offer on your home at The Property Vendee (TPV).</h5>
                    <RepossessionCard image={cardImage1} text={"Provide Personal Details"} backColor={"var(--white-background)"} foreColor={"var(--navbar-color)"} />
                    <RepossessionCard image={cardImage2} text={"Provide Property Details"} backColor={"var(--navbar-color)"} foreColor={"var(--white-background)"} extra={ellipse} />
                    <RepossessionCard image={cardImage3} text={"Arrange Virtual Viewing"} backColor={"#FFECB2"} foreColor={"#111111"} />
                </div>
                <div className='right-section'>
                    <img src={rightSectionImage} alt='woman stressed in image' />
                </div>
                <div className='offer-sr-bg-pear'>
                    <img src={backgroundPearShape} alt='bg-pear' />
                </div>
            </section>
            <section className='repossession-cta offer-page'>
                <div className='offer-card'>
                    <div className='drop-down' ref={iAmRef}>
                        <CustomSelect
                            placeholder={"Select Offer Type"}
                            options={options.offerType}
                            handleChange={(selectedValue) => formik.setFieldValue('offerType', selectedValue.value)}
                            classes={"i-am-dropdown"}
                            value={formik.values.offerType}
                            override
                        />
                        {warning && <div className='required-warning'>Please select the type of offer</div>}
                    </div>
                    <div className='cta'>
                        <div className='cta-heading'>
                            <span>Selling your home made easy.....</span>
                        </div>
                        <div className='cta-black-bg'>
                            <OfferCta
                                handleDataCollection={handleDataCollection}
                                formIndex={formIndex}
                                formik={formik}
                                reverseStep={reverseStep}
                                uploadImageRef={uploadImageRef}
                                uploadDocsRef={uploadDocsRef}
                            />
                        </div>
                    </div>
                </div>
                <div className='background-tiny-house'>

                </div>
            </section>
        </>
    );
}

export default MakeMeAnOffer;
