import Select, { components } from "react-select"
import yellowArrow from "../../assets/images/yellow-dropdown.svg"
import blackArrow from "../../assets/images/dropdown-arrow.svg"

const CustomIndicator = (props) => {
    // Determines if the dropdown indicator should be black or yellow
    return (
        <components.DropdownIndicator {...props}>
            {props.override ? <img src={blackArrow} alt="dropdown-arrow" /> : <img src={yellowArrow} alt="dropdown-arrow" />}
        </components.DropdownIndicator>
    )
}


const CustomSelect = ({ options, classes, handleChange, value, placeholder, disableTag, override, backgroundColor="--org-primary-color", borders="10px 10px 0 0" }) => {
    const getValue = (options, value) => {
        // Updates the value currently showing in the select element to the selected value
        return options.find((option) => option.value === value) || ""
    }
    const colorStyles = {
        // Determines the style of the select element itself. i.e what you see when the page loads
        control: (styles) => (
            {
                ...styles,
                backgroundColor: override ? `var(${backgroundColor})` : "var(--navbar-color)",
                borderRadius: override ? `${borders}` : "10px",
                border: "0",
                boxShadow: 0,
                fontSize: "1.2em",
                fontWeight: "600",
            }
        ),
        // Styles for each option in the list of options
        option: (styles, state) => {
            return {
                ...styles,
                color: state.isFocused ? "var(--org-primary-color)" : "var(--white-background)",
                backgroundColor: "var(--navbar-color)",
                borderBottom: "2px solid white",
            }
        },
        //Single value refers to the currently selected value
        singleValue: (styles) => {
            return {
                ...styles,
                color: override ? "var(--navbar-color)" : "var(--white-background)"
            }
        },
        // Styles of the menu
        menu: (styles) => (
            {
                ...styles,
                borderWidth: "10px",
                backgroundColor: "var(--org-primary-color)",
                padding: "10px 5px 5px 5px",
            }
        ),
        indicatorSeparator: (styles) => (
            {
                ...styles,
                display: "none"
            }
        ),
        indicatorsContainer: (styles) => (
            {
                ...styles,
                position: "absolute",
                right: "3%",
                top: "0",
                bottom: "0"

            }
        ),
        // Responsible for the animation that occurs when the menu is open
        dropdownIndicator: (styles, state) => (
            {
                ...styles,
                transition: 'transform .2s ease',
                transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null
            }
        ),
        placeholder: (styles) => (
            {
                ...styles,
                color: override ? "var(--navbar-color)" : "#808080"
            }
        )
    }
    return (
        <Select
            styles={colorStyles}
            isDisabled={disableTag}
            classNamePrefix={"react-select"}
            options={options}
            placeholder={disableTag ? "Not Available" : placeholder}
            components={{ DropdownIndicator: ({...rest}) => CustomIndicator({...rest, override: override}) }}
            className={classes}
            onChange={(selectedValue) => handleChange(selectedValue)}
            value={getValue(options, value)}
            maxMenuHeight={"300px"}
            isSearchable={false}
            required
        />
    )
}

export default CustomSelect
