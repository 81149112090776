import "./SuccessStories.css";
import { useParams, Link } from "react-router-dom";
import { useState } from "react";
import query from "../../assets/images/support/query.png";
import homework from "../../assets/images/support/homework.png";
import review from "../../assets/images/support/review.png";
import navArrow from '../../assets/images/bi_arrow-right-circle-fill.svg';
import CaseStudiesCard from "../../components/caseStudiesCard/CaseStudiesCard";
import CustomerReviewCard from "../../components/customerReviewCard/CustomerReviewCard";
import { pageHeaders, pageData } from '../../config/successStoriesData'


const page = ["caseStudies", "customerReviews", "beforeAndAfter"]

function SuccessStories () {

    const { subUrl } = useParams();

    const [index, setIndex] = useState(page.indexOf(subUrl));
    const [sliders, setSliders] = useState(pageData[index].data);
    const [slideIndex, setSlideIndex] = useState(0);

    const lastIndex = sliders.length - 1;


    const setCaseStudiesPage = () => {
        setSliders(pageData[0].data)
        if(index === 0){
            setIndex(0)
            setSlideIndex(0)
        }
        else if (index > 0 && index === 1){
                setIndex(index-1)
                setSlideIndex(0)
            }else{
                setIndex(index-2)
                setSlideIndex(0)
            }
        }
       
    
    const setCustomerReviewPage = () => {
        setSliders(pageData[1].data)
        if (index === 1){
            setIndex(1)
            setSlideIndex(0)
        }
        else if (index > 1){
                setIndex(index-1)
                setSlideIndex(0)
            }
            else{
                setIndex(index+1)
                setSlideIndex(0)
            }
        }
    
    const setBeforeAndAfterPage = () => {
        setSliders(pageData[2].data)
        if(index === 2){
            setIndex(2)
            setSlideIndex(0)
        }
        else if (index < 2 && index === 1){
                setIndex(index+1)
                setSlideIndex(0)
            }
            else{
                setIndex(index+2)
                setSlideIndex(0)
            }
        }

     //Next Btn function
     const nextSlide = () => {
        
        if (slideIndex === lastIndex-1){
            subUrl === "caseStudies" ? setSlideIndex(0) : setSlideIndex(slideIndex + 1)
        }
        else if (slideIndex < lastIndex){
            subUrl === "caseStudies" ? setSlideIndex(slideIndex + 2) : setSlideIndex(slideIndex + 1)
        }
        else if (slideIndex === lastIndex) {
            setSlideIndex(0)
        }    
    }
    

    //prev Btn function
    const prevSlide = () => {

        if (slideIndex > 0 ){
            subUrl === "caseStudies" ? setSlideIndex(slideIndex - 2) : setSlideIndex(slideIndex - 1)
        }
        else if (slideIndex === 0) {
            subUrl === "caseStudies" ? setSlideIndex(lastIndex - 1): setSlideIndex(lastIndex)
        }
    }

    const getTransitionClass = (nextIndex) => {
        if (nextIndex > slideIndex) {
            return "next-slide" 
        }
        else if (nextIndex < slideIndex) {
           return "previous-slide"          
        }
        return "";
    };
 

    return(
        <>

            <div className="success-stories-wrapper"> 
                <section className="success-stories-top">
                    <div className="success-top-left">
                        <h1 className="success-heading">
                            Making the Right Choice{" "}
                            <span className="yellow">
                            ....
                            </span>
                        </h1>
                        <h3>
                            An absolute guide on making the right choice for your distressed home.
                        </h3>
                        <div className="success-stories-container">
                            <img src={query} alt=""/>
                            <p>Check Before and After</p>
                        </div>
                        <div className="success-stories-container">
                            <img src={homework} style={{background: '#ffffff', borderRadius: '5px'}} alt=""/>
                            <p>Read Case Studies</p>
                        </div>
                        <div className="success-stories-container">
                            <img src={review} alt=""/>
                            <p>View Feedbacks</p>
                        </div>
                    </div>
                    <div className="success-stories-background">

                    </div>
               </section>

                <section className="success-stories-bottom">
                    <div className="success-stories-head">
                        <h2>
                        {pageHeaders[index] && pageHeaders[index].heading}
                        <span className="yellow">....</span>....
                        </h2>
                    </div>
                    <div className="success-slider-head">
                        <div className="select-page-head">
                            <p>Select Page</p>
                            <Link to="/successStories/caseStudies"><button type="button" className={`${index} & ${index === 0 ? "activee" : ""}`} onClick={setCaseStudiesPage} key={0}>1</button></Link>
                            <Link to="/successStories/customerReviews"><button type="button" className={`${index} & ${index === 1 ? "activee" : ""}`} onClick={setCustomerReviewPage} key={1}>2</button></Link>
                            {/* <Link to="/successStories/beforeAndAfter"><button type="button" className={`${index} & ${index === 2 ? "activee" : ""}`} onClick={setBeforeAndAfterPage} key={2}>3</button></Link> */}
                            {/* <button type="button" className={`${index} & ${index === 2 ? "activee" : ""}`} key={2}>3</button> */}
                        </div>
                        <p>{pageHeaders[index] && pageHeaders[index].title}</p>
                    </div>
                    
                    <div className="success-main-slider">
                        <div className="success-slide-container" >
                            <div className="ss-slide-inner-container">
                                {
                                    sliders.map((slide, i) => {
                                        const transitionClass = getTransitionClass(i);
                                        
                                        return (
                                            <div className={`slider ${transitionClass} ${i === slideIndex ? "activee" : ""}`} key={i}>
                                            
                                                {subUrl === "caseStudies" && <CaseStudiesCard slide1={slide} slide2={sliders[slideIndex+1]} />}
                                                {subUrl === "customerReviews" && <CustomerReviewCard slide={slide} /> }
                                            
                                            </div>
                                        )
                                    })
                                }          
                            </div>
                            
                            <button className="success-control-previous" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev" onClick={prevSlide}>
                                <img alt="previous" className="leftSlider" src={navArrow}/>
                                <span className="visually-hidden">Previous</span>
                            </button>
                            <button className="success-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next" onClick={nextSlide}>
                                <img alt="next" className="rightSlider" src={navArrow}/>
                                <span className="visually-hidden">Next</span>
                            </button>
                            
                        </div>
                        <div className="ss-slide-btn">
                            {
                                subUrl === "caseStudies" &&
                                    sliders.map((btn, i) => {
                                        return (
                                            
                                            <button id={i % 2 !==0 ? 'hide' : ''} className={slideIndex === i ? 'activee' : ''} ></button>
                                            
                                        )
                                    })
                            }
                            {
                                subUrl === "customerReviews" &&
                                sliders.map((btn, i) =>{
                                    return (
                                        <button className={slideIndex === i ? 'activee' : ''} ></button>
                                    )
                                })
                            }
                        </div>
                        
                    </div>
                </section>

            </div>
        </>
    );

}

export default SuccessStories;