import "./WhoFor.css";
import React, { useState } from "react";
import leftAndRight from "../../assets/images/bi_arrow-right-circle-fill.svg";
import { Link } from "react-router-dom";
import broker from "../../assets/images/broker.webp";
import property_owner from "../../assets/images/property_owner.webp";
import lenders from "../../assets/images/lenders.webp";
import landlord from "../../assets/images/landlord.webp";
import property_developers from "../../assets/images/property_developers.webp";
import home_seller from "../../assets/images/home_seller.webp";
import double_arrow from "../../assets/images/double-arrow.webp";

function WhoFor() {
  const innerData = [
    {
      title: "You can fall in any of the below three categories",
      leftContent: "Property Owners | Home Sellers | Landlords",
      secondContent: "Financial Difficulty (FD)\n Owner",
      thirdContent:
        "Businesses Property Developer | Property Professionals | Financial Institutions",
    },
    {
      title: "FD Owners",
      leftContent: "Additional\nCharge",
      secondContent: "Financial Difficulty (FD)\n Owner",
      thirdContent:
        "Businesses Property Developer | Property Professionals | Financial Institutions",
    },
    {
      title: "Property Owners",
      leftContent: "Within 3-6 months or more",
      secondContent: "Financial Difficulty (FD)\n Owner",
      thirdContent:
        "Businesses Property Developer | Property Professionals | Financial Institutions",
    },
    {
      title: "Businesses",
      leftContent: "Additional Charge, roughly equal to 5-10% of Rental Income",
      secondContent: "Financial Difficulty (FD)\n Owner",
      thirdContent:
        "Businesses Property Developer | Property Professionals | Financial Institutions",
    },
  ];
  const [sliders] = useState(innerData);
  const [index, setIndex] = useState(0);
  const lastIndex = sliders.length - 1;

  const nextSlide = () => {
    if (index < lastIndex) {
      setIndex(index + 1);
    } else if (index === lastIndex) {
      setIndex(0);
    }
  };

  const prevSlide = () => {
    if (index > 0) {
      setIndex(index - 1);
    } else if (index === 0) {
      setIndex(lastIndex);
    }
  };

  const getTransitionClass = (nextIndex) => {
    if (nextIndex > index) {
      return "carousel-item-next";
    } else if (nextIndex < index) {
      return "carousel-item-prev";
    }
    return "";
  };

  return (
    <>
      <div className="who-for-container">
        <div className="who-for">
          <h1 className="heading-who-for">
            Experience our <span style={{ color: "#fac710" }}>Fast</span>
            <br /> Problem Property <br />
            Solution
          </h1>
        </div>
        <div className="wf-mobile-image"></div>
        <p className="second-text">
          Our seamless and competitive <br />
          distressed owners solution is <br /> designed to suit you.
        </p>

        <section className="card-master-border">
          <div className="child-border-1">
            <img src={broker} alt="form" />
            <h2 className="box-text">Property Professionals</h2>
          </div>

          <div className="child-border-2">
            <img src={home_seller} alt="form" />
            <h2>
              Financial <br /> Difficulty (FD) Owners
            </h2>
          </div>

          <div className="child-border-3">
            <img src={property_developers} alt="form" />
            <h2>
              Property <br /> Developers
            </h2>
          </div>

          <div className="child-border-4">
            <img src={lenders} alt="form" />
            <h2>Financial Institutions</h2>
          </div>

          <div className="child-border-5">
            <img src={landlord} alt="form" />
            <h2>Landlords</h2>
          </div>

          <div className="child-border-6">
            <img src={property_owner} alt="form" />
            <h2>Home Sellers</h2>
          </div>
        </section>

        <section className="find-out-now">
          <div className="find-out-box">
            <h3 className="get-to-know-mv">Get to know the value of your property</h3>
            <a href="./makeMeAnOffer" className="who-btn">
              <p className="find-out-offer">Find out NOW</p>
            </a>
          </div>
        </section>

        <div className="who-for-line-segment"></div>
        <section className="section">
          <h2 className="wf-benefits">
            The Benefits For You<span style={{ color: "#fac710" }}>....</span>
          </h2>
        </section>
      </div>

      <div className="wf-clear">
        Clear Benefits To You...<span style={{ color: "#fac710" }}>..</span>
      </div>
      <div className="box-tpvv">
                        Who uses TPV?

                        </div>


      <div className="who-for-main-compare">
        <div className="who-for-compare-head">
          {sliders[index] && sliders[index].title}
        </div>

        <div className="carousel slide compare-container">
          <div className="carousel-inner">
            {sliders.map((slide, i) => {
              const transitionClass = getTransitionClass(i);
              const { title, leftContent, secondContent, thirdContent } = slide;
              return (
                <div
                  className={`carousel-item ${transitionClass} ${
                    i === index ? "active" : ""
                  }`}
                  key={title}
                >
                  <div className="who-for-compare-inner">
                    <div className="compare-box">
                      <div className="detail">
                        <div className="text-arrow">
                          <img className="first-arrow"
                            src={double_arrow}
                            alt="form"
                          />
                          <h5>
                            <span className="individual-text1">
                              {leftContent}
                            </span>
                          </h5>
                        </div>

                        <div className="text-arrow">
                          <img className="second-arrow"
                            src={double_arrow}
                            alt="form"
                          />
                          <h5>
                            <span className="individual-text2">
                              {secondContent}
                            </span>
                          </h5>
                        </div>

                        <div className="text-arrow">
                          <img className="third-arrow"
                            src={double_arrow}
                            alt="form"
                          />
                          <h5>
                            <span className="individual-text3">
                              {thirdContent}
                            </span>
                          </h5>
                          
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                
              );
            })}
          </div>

          <button
            className="carousel-control-prev"
            type="button"
            onClick={prevSlide}
          >
            <img alt="previous" className="leftSlider" src={leftAndRight} />
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            onClick={nextSlide}
          >
            <img alt="next" className="rightSlider" src={leftAndRight} />
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </>
  );
}

export default WhoFor;
