import { useState, useEffect, useRef } from "react";
import { Link } from 'react-router-dom';
import "./Footer.css";

// import logo from "../../assets/images/TPV_LOGO_f1.svg";
import logo from '../../assets/images/tpv logo svg-01.svg'
import logo2 from "../../assets/images/logo.svg";
import sendEmail from "../../assets/images/send-email.png";
import arrowUp from "../../assets/images/arrow-up.png";
import realtor from "../../assets/images/realtor.png";
import greda from "../../assets/images/greda.png";
import copyR from "../../assets/images/copyR.png";
import gareb from "../../assets/images/gareb.jpg";
import shareIcon from "../../assets/images/shareicon.png";
import facebook from "../../assets/images/facebook.png";
import telegram from "../../assets/images/telegram.png"
import telegramIcon from '../../assets/images/telegram_icon.png';
import close from '../../assets/images/closeBtn.png';

import emailjs from '@emailjs/browser';

import About from "../about/About";

function Footer() {
    const currentPage = window.location.href;


  const handleScrollUp = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }


  const initialTime = {
    days: 20,
    hours: 23,
    minutes: 59,
    seconds: 59
  };

  const [time, setTime] = useState(initialTime);

  useEffect(() => {
    const savedTime = localStorage.getItem('countdownTime');
    const parsedTime = savedTime ? JSON.parse(savedTime) : initialTime;

    setTime(parsedTime);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(prevTime => {
        let { days, hours, minutes, seconds } = prevTime;

        if (seconds === 0) {
          if (minutes === 0 && hours === 0) {
            days = 20;
            hours = 23;
            minutes = 59;
          } else if (minutes === 0) {
            hours -= 1;
            minutes = 59;
          } else {
            minutes -= 1;
          }

          seconds = 59;
        } else {
          seconds -= 1;
        }

        const updatedTime = { days, hours, minutes, seconds };
        localStorage.setItem('countdownTime', JSON.stringify(updatedTime));
        return updatedTime;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);


//   MAIN FOOTER VARIBLES AND FUNCTIONS

    const [subConfirm, setSubConfirm] = useState();
    const [emailValid, setEmailValid] = useState();
    const userEmail = useRef();

    const clearEmailField = () => {
        userEmail.current.value = "";
    }


    // const testAlert = (e) =>{
    //     e.preventDefault();
    //     if (emailValid){
    //         console.log("Subscription sent");
    //         setSubConfirm(1);
    //         clearEmailField();
    //     }else{
    //         console.log("Invalid email")
    //     }
    //     // console.log("Subscription sent");
    //     // setSubConfirm(1);
    //     // clearEmailField();
    // }

    const closeSubConfirm = (e) =>{
        e.preventDefault();
        console.log("Subscription confirm closed");
        setSubConfirm();
    }

    const ValidateEmail = (input) => {

        const validEmail = /^[A-Za-z\._\-0-9]*[@][A-Za-z]*[\.][a-z]{2,4}$/;
        const emailtxt = document.querySelector("#emailtxt");
        const error = document.querySelector(".error");

        if (input === "" || input.match(validEmail)) {
            error.style.display = "none";
            emailtxt.style.outline = "revert-layer";
            setEmailValid(1);
            // return true;
        } else {

          console.log("Invalid email address!");
          error.style.display = "block"
          emailtxt.style.outline = "2px solid red";
          setEmailValid(0);
        //   return false;

        }

      }

    const form = useRef();

    const submitSubscription = (e)=>{
        e.preventDefault();

        if (emailValid){

            emailjs.sendForm('service_lrekcyz', 'template_0sgyoak', form.current, 'cm_1OMdDgstBwtAkp')
            .then((result) => {
                console.log('sent');
                setSubConfirm(1);
                clearEmailField();

            }, (error) => {
                console.log(error);
                console.log('not sent');
            });

        }else{
            console.log("Invalid email")
        }

    }

  return (
    <>

        {/* <About/> */}

    <div className="countdown">
        <div className="countdown-text">Money in your Pocket in:</div>
        <div className="countdown-boxes">
            <div className="countdown-box">
                <div className="countdown-box-value">{time.days}</div>
                <div className="countdown-box-label">Days</div>
            </div>
            <div className="countdown-box">
                <div className="countdown-box-value">{time.hours}</div>
                <div className="countdown-box-label">Hours</div>
            </div>
            <div className="countdown-box">
                <div className="countdown-box-value">{time.minutes}</div>
                <div className="countdown-box-label">Mins</div>
            </div>
            <div className="countdown-box">
                <div className="countdown-box-value">{time.seconds}</div>
                <div className="countdown-box-label">Secs</div>
            </div>
        </div>

        <button className="countdown-button btn-block"><a href="makeMeAnOffer" style={{color:"#ffffff"}}>Start now</a></button>

        <div className="p-2 m-2 pull-right share-icon text-center">
            <div className="dropdown">
                <button className="btn-secondary bg-white dropdown-toggle share-icon-btn" data-toggle="tooltip" data-placement="top" title="Share Now" type="button" id="share-button" data-bs-toggle="dropdown" aria-expanded="false">
                    <img src={shareIcon} alt="Share Icon" />
                </button>
                <ul className="dropdown-menu" aria-labelledby="share-button">
                    <li>
                        <a className="dropdown-item" target={"__blank"} rel={"noreferrer"} href={"https://telegram.me/share/url?url=" + currentPage}>
                            Telegram
                            <img className="wp" height={20} width={20}  src={telegramIcon} alt="Telegram icon"></img>
                        </a>
                    </li>
                    <li>
                        <a className="dropdown-item" target={"__blank"} rel={"noreferrer"} href={"https://wa.me/?text=" + currentPage}>
                            WhatsApp
                            <img className="wp" height={20} width={20}  src="https://www.sharethis.com/wp-content/uploads/2017/05/WhatsApp.png" alt="WhatsApp"></img>
                        </a>
                    </li>
                    <li>
                        <a className="dropdown-item" href="#copy-to-clipboard" onClick={() => {navigator.clipboard.writeText(currentPage)}}>Copy Link</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>

    {/* START OF MAIN FOOTER */}
      <div className="main-footer-section">
            <div className="footer-section">
                <div className="footer-head" style={{ position: "relative"}}>
                      {/* <div className='nav-logo' style={{ position: "absolute", left:32, top:32 }}>
                          <a href="/" class="logo"><img src={logo} alt="LOGO" /></a>
                      </div> */}
                    <div className="flogo"><a href="/"><img src={logo} alt="LOGO" /></a></div>
                    <div className="fhead">Making Problem Properties Work <br /> For <span>YOU</span>...</div>
                    {/* <div className="fhead">The <span>Property</span> Vendee (TPV)</div> */}
                </div>

                {/* <div className="flegend">Making Problems Properties work for you</div> */}

                <div className="fcards-container">
                    <div className="dashedLine"></div>
                    <div className="fcards">
                        <div className="fcard">
                            <div className="card-main">
                                <div className="number">1</div>
                                <div className="card-content">
                                    <h5>Expert<br />Solution </h5>
                                    <p>Experience over 15+ years combined experts in property.</p>
                                </div>
                            </div>
                        </div>

                        <div className="fcard">
                            <div className="card-main">
                                <div className="number">2</div>
                                <div className="card-content">
                                    <h5>Hassel-Free<br />Support</h5>
                                    <p>Your problem property is fully taken care off, so that you can have a peace of mind.</p>
                                </div>
                            </div>
                        </div>

                        <div className="fcard">
                            <div className="card-main">
                                <div className="number">3</div>
                                <div className="card-content">
                                    <h5>Tailored<br />Solution</h5>
                                    <p>There is no one size fit it all solution, you get an offer tailored to your situation.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="subEmail">
                    <div className="emailDesc">Subscribe to monthly TPV insights</div>
                    {
                    subConfirm &&
                    <div className="confirm-subcription">
                        <button type="reset" onClick={closeSubConfirm}>
                            <img className="close-confirm" src={close} alt="cancel"/>
                        </button>
                        <h3>Thank you</h3>
                        <p>Your subscription has been confirmed. You’ve been added to our list and will hear from us soon.</p>
                    </div>
                    }
                    <div className="emailField">
                        <div className="sendEmail"><img src={sendEmail} alt="sendEmail" /></div>
                        <form className="email-input" ref={form} onSubmit={submitSubscription}>
                            <input type="email" id="emailtxt" name="user_email" placeholder="Enter your email" ref={userEmail} onKeyUp={(e)=>ValidateEmail(e.target.value)} required/>
                            <input type="submit" className="subBtn" value="GO"/>
                            {/* <a href="#" onClick={submitSubscription1} className="subBtn" id="subBtn">GO</a> */}
                        </form>
                        <span className="error">please enter a valid email: example@domain.com</span>
                    </div>
                </div>

            </div>

            <div className="socials">
                <div className="sponsor">
                    <img src={realtor} alt="sponsor" />
                </div>

                <div className="social">
                    <span>Follow us on</span>
                    <a href='https://www.facebook.com/XcelszPropertySolutions' rel="noreferrer" target="_blank"><img src={facebook} className="footer-fb"alt="sponsor" /></a>
                    <a href='https://t.me/+VVWxJnP0ffw6eXNs' rel="noreferrer" target="_blank"><img src={telegram} alt="sponsor" /></a>
                </div>
            </div>

            <div className="sponsors">
                <img src={realtor} alt="sponsor" />
                {/* <img src={gareb} alt="sponsor" /> */}
                {/* <img src={greda} alt="sponsor" /> */}
            </div>

            {/* <div className="foot-terms">
               <Link to="./terms">Terms</Link>
                <Link to="./cookies">Cookies</Link>
                <Link to="./privacyPolicy">Privacy</Link>
                <Link to='./about'>About</Link>
                <Link to='#'>Sitemap</Link>
            </div> */}


            <div className="subfoot">
                <div className="subfoot-brand">
                    TPV IS AN
                    <a href="https://www.xcelsz.com" rel="noreferrer" target="_blank"><img src={logo2} alt="" /></a>
                    SOLUTION
                </div>


                <div className="foot-terms">
                    <Link to="/terms">Terms</Link>
                    <Link to="/cookies">Cookies</Link>
                    <Link to="/privacyPolicy">Privacy</Link>
                    <Link to='/about'>About</Link>
                    <Link to='#'>Sitemap</Link>
                    {/* <a href="/terms">Terms</a>
                    <a href="/cookies">Cookies</a>
                    <a href="/privacyPolicy">Privacy</a>
                    <a href='/about'>About</a>
                    <a href='#'>Sitemap</a> */}
                </div>

                <div className="copyright">
                    <img src={copyR} alt="copyright" />
                    <span>2020 - {new Date().getFullYear()}</span>
                    <a href="https://www.xcelsz.com" rel="noreferrer" target="_blank">Xcelsz.com</a>
                </div>
            </div>
        </div>

        {/* <div className="top-btn" onClick={handleScrollUp}><img src={arrowUp} alt="" id="upBTN" /></div> */}
        <div className="cookies">
            <p>This website uses cookies</p>
            <p>We use cookies to personalise content and ads, to provide social media features and to analyse our traffic. You consent to our cookies if you continue to use our website. For more details read our cookie policy.</p>
            <div className="checkboxes">
                <div className="control">
                    <label for="check">Necessary</label>
                    <input type="checkbox" name="check1" id="checkN" value="Necessary" checked disabled />
                </div>

                <div className="control">
                    <label for="check">Prefrences</label>
                    <input type="checkbox" name="check1" id="check1" checked />
                </div>

                <div className="control">
                    <label for="check">Statistics</label>
                    <input type="checkbox" name="check1" id="check2" checked />
                </div>

                <div className="control">
                    <label for="check">Marketing</label>
                    <input type="checkbox" name="check1" id="check3" checked />
                </div>

                <a href="#Accept" id="accept">Accept</a>
            </div>
        </div>
    </>

  );


}

export default Footer;
