import RepossessionCard from "../stopRepossession/RepossessionCard";
import backgroundPearShape from "../../assets/images/pear-shaped.svg";
import cardImage1 from "../../assets/images/eviction 1.svg";
import cardImage2 from "../../assets/images/tenant.svg";
import cardImage3 from "../../assets/images/stress-management 1.svg";
import rightSectionImage from "../../assets/images/Waiting-bro 3(1).svg";
import ellipse from "../../assets/images/Ellipse 30(2).svg";
import "./landlord.css";
import LandLordCta from "./LandLordCta";
const LandLord = () => {
  return (
    <>
      <section className="first-section landlord-section">
        <div className="left-section">
          <h1 className="heading max-content">
            Hassle Free Letting Made <span className="yellow-text">Easy</span>
          </h1>
          <h5 className="sub-heading break">
            Your perfect Tenant right away at{"\n"}absolutely no hassle to you.
          </h5>
          <div className="cards">
            <RepossessionCard
              image={cardImage1}
              text={"No maintenance, eviction, commission costs"}
              backColor={"var(--white-background)"}
              foreColor={"var(--navbar-color)"}
            />
            <RepossessionCard
              image={cardImage2}
              text={"No late monthly rent payment"}
              backColor={"var(--navbar-color)"}
              foreColor={"var(--white-background)"}
              extra={ellipse}
            />
            <RepossessionCard
              image={cardImage3}
              text={"No stress to you"}
              backColor={"#FFECB2"}
              foreColor={"#111111"}
            />
          </div>
        </div>
        <div className="right-section">
          <img src={rightSectionImage} alt="man waiting in image" />
        </div>
        <div className="bg-pear">
          <img src={backgroundPearShape} alt="bg-pear" />
        </div>
      </section>
      <section className="landlord-second-section">
        <h2 className="landlord-heading">
          Great Offers Which Fit Whatever Your {"\n"}Situation Is .....{" "}
        </h2>
        <LandLordCta />
      </section>
    </>
  );
};

export default LandLord;
