import { useEffect, useState } from "react";

import "./GettingStarted.css";
import checklist from "../../assets/images/checklist.webp";
import negotiate from "../../assets/images/negotiate.webp";
import price from "../../assets/images/price.webp";
import todolist from "../../assets/images/to-do-list.webp";
import housing from "../../assets/images/housing.webp";
import savannah from "../../assets/images/savannah.webp";
import contract from "../../assets/images/contract.webp";
import fee from "../../assets/images/fee.webp";
import customer from "../../assets/images/customer.webp";


function GettingStarted() {
  return (
    <>
      <div className="container-fluid-gs-wrapper">
        <section className="getting_started">
          <h1 className="heading_started">
            Our Process is{" "}
            <span className="blink_me" style={{ color: "#fac710" }}>
              Easy..
            </span>..
          </h1>
          <p className="gs-rqo">
            Requesting an offer on your home from The Property Vendee (TPV) is easy, fast and secured
          </p>
          <p className="gs-rqo-mv">
            Requesting an offer <br /> on your home made <br /> easy, fast and <br />secured
          </p>
          <div className="gs-mv-bg">

          </div>
          <p className="rent_or_text">
            Rent or Sell your home in three simple steps
          </p>

          <div className="container gs-container">
            <div className="gs-form-picture">
              <a href="/makeMeAnOffer" style= {{ color: "black" }}>
                <div
                  className="process gs-process"
                  style={{ position: "relative", right: "3.5rem" }}
                >
                  <img src={checklist} alt="form" />
                  <h2 className="box-header">Complete Form</h2>
                  <p>
                    Call or fill in the Make <br /> me an Offer Form online
                  </p>
                </div>
              </a>
            </div>

            <div className="negotiation">
              <div className="process gs-process">
                <img src={negotiate} alt="negotiation" />
                <h2 className="box-header">Get Offer</h2>
                <p>
                  Receive an offer for your <br /> property within 72 hours
                </p>
              </div>
            </div>

            <div className="gs-price">
              <div
                className="process gs-process"
                style={{ position: "relative", right: "-3.2rem" }}
              >
                <img src={price} alt="price" />
                <h2 className="box-header">Accept Offer</h2>
                <p>
                  Accept Offer, sit back, <br /> enjoy money in the bank.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="gs-make-me-an-offer">
          <div className="offer-box">
            <h3>
              Getting a quick, fair and guaranteed offer on your house is
              just a click away
            </h3>
            <a href="/makeMeAnOffer" className="btn">
              <p className="gs-offer">Make Me An Offer</p>
            </a>
          </div>
        </section>

        <section className="gs-choice">
          <h1
            style={{
              textAlign: "center",
              fontWeight: "bold",
              fontFamily: "Arial, Helvetica, sans-serif",
            }}
          >
            Why{" "}
            <span
              className=""
              style={{ color: "#fac710", textAlign: "center" }}
            >
              Choose
            </span>{" "}
            Us
          </h1>

          <div className="gs-master-container">
            <div
              className="card-border"
              // style={{ position: "relative", left: "-18px" }}
            >
              <div className="gs-number">1</div>
              <div className="card-image">
                <img src={todolist} alt="to-do" />
              </div>
              <div className="image-text">
                Complete within <br /> 7 - 21 days
              </div>
            </div>

            <div
              className="card-border"
              // style={{
              //   borderColor: "white",
              //   position: "relative",
              //   left: "15px",
              // }}
            >
              <div className="gs-number">2</div>
              <div className="card-image">
                <img src={housing} alt="housing" />
              </div>
              <div className="image-text">
                Any Property, Any
                <br />
                Condition
              </div>
            </div>

            <div
              className="card-border"
              // style={{ position: "relative", left: "39px" }}
            >
              <div className="gs-number">3</div>
              <div className="card-image">
                <img src={savannah} alt="savannah" />
              </div>
              <div className="image-text">
                Any Location, even
                <br /> in rural areas
              </div>
            </div>
          </div>

          <div className="gs-second">
            <div
              className="card-border"
              // style={{ position: "relative", left: "-18px" }}
            >
              <div className="gs-number">4</div>
              <div className="card-image">
                <img src={contract} alt="to-do" />
              </div>
              <div className="image-text">
                We take care of any legal fees
              </div>
            </div>

            <div
              className="card-border"
              // style={{
              //   borderColor: "white",
              //   position: "relative",
              //   left: "15px",
              // }}
            >
              <div className="gs-number">5</div>
              <div className="card-image">
                <img src={fee} alt="fee" />
              </div>
              <div className="image-text">
                You Pay No <br /> Commission Fees
              </div>
            </div>

            <div
              className="card-border"
              // style={{ position: "relative", left: "39px" }}
            >
              <div className="gs-number">6</div>
              <div className="card-image">
                <img src={customer} alt="customer" />
              </div>
              <div className="image-text">
                After Care, even <br /> post Completion
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="gs-line-segment"></div>
    </>
  );
}

export default GettingStarted;
