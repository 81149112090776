const iAmData = [
    { value: "FD Owner", label: "FD Owner" },
    { value: "Financial Institution", label: "Financial Institution" },
    { value: "Property Developer", label: "Property Developer" },
    { value: "Property Professional", label: "Property Professional" },
]
const countries = [
    { value: "Ghana", label: "Ghana" },
    { value: "UK", label: "United Kingdom" },
    { value: "Nigeria", label: "Nigeria" },
]
const propertyTypes = [
    { value: "Apartment", label: "Apartment" },
    { value: "Detached Home", label: "Detached Home" },
    { value: "Semi-Detached Home", label: "Semi-Detached Home" },
    { value: "Flat", label: "Flat" },
    { value: "Bungalow", label: "Bungalow" },
    { value: "Town House", label: "Town House" },
    { value: "Other Residential", label: "Other Residential" },
    { value: "Commercial", label: "Commercial" },
    { value: "Commercial-Residential", label: "Commercial-Residential" },
    { value: "Land", label: "Land" },
    { value: "Investment Property", label: "Investment Property" },
]

const bedrooms = [
    { value: "1 Bedroom", label: "1 Bedroom" },
    { value: "2 Bedrooms", label: "2 Bedrooms" },
    { value: "3 Bedrooms", label: "3 Bedrooms" },
    { value: "4 Bedrooms", label: "4 Bedrooms" },
    { value: "5 Bedrooms", label: "5 Bedrooms" },
    { value: "6 Bedrooms", label: "6 Bedrooms" },
    { value: "7 Bedrooms", label: "7 Bedrooms" },
    { value: "8 Bedrooms", label: "8 Bedrooms" },
    { value: "9 Bedrooms", label: "9 Bedrooms" },
    { value: "10 Bedrooms", label: "10 Bedrooms" },
    { value: "11 Bedrooms", label: "11+ Bedrooms" },
]
const propertyConditions = [
    { value: "Move in state", label: "Move in state" },
    { value: "Retired Property", label: "Retired Property" },
    { value: "Light Refurb required", label: "Light Refurb required" },
    { value: "Development property", label: "Development property" },
]
const renovations = [
    { value: "less than 6 months ago", label: "less than 6 months ago" },
    { value: "6-12 months ago", label: "6-12 months ago" },
    { value: "12-24 months ago", label: "12-24 months ago" },
    { value: "24+ months ago", label: "24+ months ago" },
]

const loans = [
    { value: "0", label: "0" },
    { value: "less than 25,000", label: "less than 25,000" },
    { value: "25,000 - 50,000", label: "25,000 - 50,000" },
    { value: "50,000 - 100,000", label: "50,000 - 100,000" },
    { value: "100,000+", label: "100,000+" },
]

const offerType = [
    { value: "Sell Fast", label: "Sell Fast" },
    { value: "Rent Fast", label: "Rent Fast" },
    { value: "Assisted Fast", label: "Assisted Fast" },
]

const market = [
    { value: "No, Not Yet", label: "No, Not Yet" },
    { value: "Just One Week", label: "Just One Week" },
    { value: "Two Weeks", label: "Two Weeks" },
    { value: "Three Weeks", label: "Three Weeks" },
    { value: "One Month", label: "One Month" },
    { value: "Four Months and More", label: "Four Months and More" },
]

const sellingFast = [
    { value: "Moving Abroad or Relocating", label: "Moving Abroad or Relocating" },
    { value: "Divorce or Separation", label: "Divorce or Separation" },
    { value: "Stuck in a Chain", label: "Stuck in a Chain" },
    { value: "Inherited Bad Property", label: "Inherited Bad Property" },
    { value: "Stopping Repossession", label: "Stopping Repossession" },
    { value: "Retirement or Downsizing", label: "Retirement or Downsizing" },
    { value: "Negative Equity", label: "Negative Equity" },
    { value: "Need Quick Cash", label: "Need Quick Cash" },
]

const findOutAboutUs = [
    {value: "iMarketer", label: "iMarketer"},
    {value: "Word of Mouth", label: "Word of Mouth"},
    {value: "Google Search", label: "Google Search"},
    {value: "YouTube", label: "YouTube"},
    {value: "Facebook", label: "Facebook"},
    {value: "LinkedIn", label: "LinkedIn"},
    {value: "Instagram", label: "Instagram"},
    {value: "Twitter", label: "Twitter"},
    {value: "iReferral", label: "iReferral"},
    {value: "Tik Tok", label: "Tik Tok"},
]

export default {
    iAmData,
    countries,
    propertyTypes,
    bedrooms,
    propertyConditions,
    renovations,
    loans,
    offerType,
    market,
    sellingFast,
    findOutAboutUs
}
