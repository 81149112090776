import "./DistressedDevelopers.css";
import { useState, useRef, useEffect } from "react";
import userData from "../../assets/images/user-data 1.svg";
import building from "../../assets/images/buildings 1.svg";
import virtual from "../../assets/images/virtual 1.svg";
import image5 from "../../assets/images/image 5.svg";
import mortgage from "../../assets/images/mortgage-bro.svg";
import tenant from "../../assets/images/tenant.svg";
import assistance from "../../assets/images/assistance.svg";
import assist from "../../assets/images/assistance 2.svg";
import dArrow from "../../assets/images/double-arrow.webp";
import standing from "../../assets/images/standing.png";
import close from "../../assets/images/closeBtn.png";
import navArrow from "../../assets/images/bi_arrow-right-circle-fill.svg";
import dropDownArrow from "../../assets/images/yellow-dropdown.svg";

import vectorImage from "../../assets/images/Vector.png"

import emailjs from "@emailjs/browser";

function DistressedDevelopers() {
  const [index, setIndex] = useState(0);
  const [emailValid, setEmailValid] = useState();
  const [location, setLocation] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [textArea, setTextArea] = useState("");
  const [propertyAvailability, setPropertyAvailability] = useState("")
  const [subConfirm, setSubConfirm] = useState();
  const [dropDown, setDropDown] = useState(0);

  const email = useRef();
  const text = useRef();
  const country = useRef();

  // Button slider
  const setFirstSlide = () => {
    setIndex(0);
  };

  const setSecondSlide = () => {
    setIndex(1);
  };

  const setThirdSlide = () => {
    setIndex(2);
  };

  //Next Btn function
  const nextSlide = () => {
    if (index === 2) {
      setIndex(0);
    } else if (index < 2) {
      setIndex(index + 1);
    }
  };

  //prev Btn function
  const prevSlide = () => {
    if (index > 0) {
      setIndex(index - 1);
    } else if (index === 0) {
      setIndex(2);
    }
  };

  const closeSubConfirm = (e) => {
    e.preventDefault();
    console.log("Subscription confirm closed");
    setSubConfirm();
  };

  const ValidateEmail = (input) => {
    const validEmail = /^[A-Za-z\._\-0-9]*[@][A-Za-z]*[\.][a-z]{2,4}$/;
    const emailtxt = document.querySelector(".dpd-email");
    const error = document.querySelector(".dpd-error");

    if (input === "" || input.match(validEmail)) {
      error.style.display = "none";
      // emailtxt.style.outline = '1px solid #fac710';
      emailtxt.style.border = "2px solid #fac710";
      setEmailValid(1);
      // return true;
    } else {
      console.log("Invalid email address!");
      error.style.display = "block";
      //   emailtxt.style.outline = "2px solid red";
      emailtxt.style.border = "2px solid red";
      setEmailValid(0);
      //   return false;
    }
  };

  const clearField = () => {
    email.current.value = "";
    text.current.value = "";
    country.current.value = "Select Location";
  };

  const form = useRef();

  const submitdpdForm = (e) => {
    e.preventDefault();

    if (emailValid) {
      emailjs
        .sendForm(
          "service_lrekcyz",
          "template_0sgyoak",
          form.current,
          "cm_1OMdDgstBwtAkp"
        )
        .then(
          (result) => {
            console.log("sent");
            setSubConfirm(1);
            clearField();
          },
          (error) => {
            console.log(error);
            console.log("not sent");
          }
        );
    } else {
      console.log("Invalid email");
    }
  };

  const locDropDown = () => {
    if (dropDown === 0) {
      setDropDown(1);
    } else {
      setDropDown(0);
    }
  };

  const selectLocation = (i) => {
    if (i === 0) {
      setLocation("Ghana");
    } else if (i === 1) {
      setLocation("United Kingdom");
    } else if (i === 2) {
      setLocation("Nigeria");
    }
    setDropDown(0);
  };

  const subForm = () => {
    if (emailValid && location && textArea && propertyAvailability) {
      setSubConfirm(1);
      setLocation("");
    }
  };

  return (
    <>
      <div className="dpd-wrapper">
        <section className="dpd-top">
        <div className="dpd-background-container">
          <img src={vectorImage} alt="Vector" className="dpd-background-image" />
        </div>
          <div className="dpd-top-left">
            <h1 className="dpd-heading">
              Keeping your Investor Partners Happy Made
              <span className="yellow"> Easy</span>
            </h1>
            <div className="dpd-container">
              <img src={userData} alt="" />
              <p>Quick cash flow and no commission fees</p>
            </div>
            <div className="dpd-container">
              <div>
                <img src={building} style={{ borderRadius: "5px" }} alt="" />
              </div>
              <p>No more keeping your partners waiting</p>
            </div>
            <div className="dpd-container">
              <img src={virtual} alt="" />
              <p>Fixed rent and repayment guaranteed</p>
            </div>

            <h2 className="dpd-hero-text">
              Your perfect buyer or Tenant available right away.
            </h2>
          </div>
          <div className="dpd-background"></div>
        </section>

        <section className="dpd-slide-section">
          <div className="dpd-head">
            <h2>
              Rent Fast, Sell Fast, Assisted Rent, Assisted Sale whatever fixes
              your situation
              <span className="yellow"> ....</span>....
            </h2>
          </div>
          <div className="dpd-slider">
            {index === 0 && (
              <div className="dpd-slider-headers">
                <div className="dpd-slider-head">
                  <p>
                    You know someone who can use the Propert Developer's
                    Solution?
                  </p>
                </div>
                <div
                  className="dpd-slider-head"
                  style={{ background: "#fac710" }}
                >
                  <p
                    // style={{
                    //   width: "660px",
                    //   fontSize: "35px",
                    //   lineHeight: "42px",
                    // }}
                  >
                    Get them on the Property Developer’s VIP List Now
                  </p>
                </div>
              </div>
            )}
            {index === 1 && (
              <div className="dpd-slider-headers">
                <div
                  className="dpd-slider-head"
                  style={{ background: "#fac710" }}
                >
                  <p>
                    Why TPV is the right Choice?
                  </p>
                </div>
                <div className="dpd-slider-head">
                  <p>
                    We take on any property including under development properties
                  </p>
                </div>
              </div>
            )}
            {index === 2 && (
              <div className="dpd-slider-headers">
                <div
                  className="dpd-slider-head"
                  style={{ background: "#fac710" }}
                >
                  <p>
                    Whatever your circumstance may be, Sell Fast
                  </p>
                </div>
                <div className="dpd-slider-head">
                  <p>
                    Money in your pocket <br/> 3 Solution away<span className="yellow"> ....</span>
                  </p>
                </div>
              </div>
            )}
            <div className="dpd-main-slider">
              <div className="dpd-slide-container">
                <div className="slide-inner-container">
                  {index === 0 && (
                    <div style={{ width: "content", height: "content" }}>
                      {!subConfirm && (
                        <div
                          className="customer-review-card"
                          style={{
                            height: "429px",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <form className="dpd-form" onSubmit={submitdpdForm}>
                            <div className="dpd-input-field">
                              <div
                                className="case-number"
                                // style={{ width: "80px", height: "60px" }}
                              >
                                1
                              </div>

                              {/* Default Dropdown Menu */}
                              <select
                                name="location"
                                onChange={(e) => setLocation(e.target.value)}
                                required
                              >
                                <option value="">Select Location</option>
                                <option value="Ghana">Ghana</option>
                                <option value="United Kingdom">
                                  United Kingdom
                                </option>
                                <option value="Nigeria">Nigeria</option>
                              </select>

                              {/* Customized Dropdown Menu */}
                              <div className="select-field">
                                <input
                                  type="text"
                                  className="select-input"
                                  value={location}
                                  onClick={locDropDown}
                                  placeholder="Select Location"
                                  readOnly
                                  required
                                />
                                <img
                                  className={dropDown === 1 ? "active" : ""}
                                  onClick={locDropDown}
                                  src={dropDownArrow}
                                  alt="dropdown"
                                />
                                {dropDown === 1 && (
                                  <div className="dpd-select">
                                    <ul className="select">
                                      <li
                                        className="select-option"
                                        onClick={(e) => {
                                          selectLocation(0);
                                        }}
                                      >
                                        Ghana
                                      </li>
                                      <li
                                        className="select-option"
                                        onClick={(e) => {
                                          selectLocation(1);
                                        }}
                                      >
                                        United Kingdom
                                      </li>
                                      <li
                                        className="select-option"
                                        onClick={(e) => {
                                          selectLocation(2);
                                        }}
                                      >
                                        Nigeria
                                      </li>
                                    </ul>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="dpd-input-field">
                              <div
                                className="case-number"
                                // style={{ width: "80px", height: "60px" }}
                              >
                                2
                              </div>
                              <input
                                type="email"
                                name="email"
                                className="dpd-email"
                                placeholder="Input Your Email"
                                onChange={(e) => setUserEmail(e.target.value)}
                                onKeyUp={(e) => ValidateEmail(e.target.value)}
                                required
                              />
                            </div>
                            <span className="dpd-error">
                              please enter a valid email: example@domain.com
                            </span>
                            <div className="dpd-input-field">
                              <div
                                className="case-number"
                                // style={{ width: "80px", height: "60px" }}
                              >
                                3
                              </div>
                              <textarea
                                type="textarea"
                                name="property-availability"
                                placeholder="Select when property is available"
                                onChange={(e) => {
                                  setTextArea(e.target.value);
                                }}
                                required
                              ></textarea>
                            </div>

                            <div className="dpd-input-field">
                              <div
                                className="case-number"
                                // style={{ width: "80px", height: "60px" }}
                              >
                                4
                              </div>
                              <textarea
                                type="textarea"
                                name="about_situation"
                                placeholder="About you and thier Situations"
                                onChange={(e) => {
                                  setPropertyAvailability(e.target.value);
                                }}
                                required
                              ></textarea>
                            </div>

                            {/* Uncomment line below to submit form to submit form directly to tpv@xcelsz.com*/}
                            {/* <input type='submit' value='Send' /> */}
                          </form>
                          {/* Comment this out when using form to submit directly to tpv@xcelsz.com */}
                          <a
                            style={{
                              pointerEvents: `${
                                emailValid && location && userEmail && textArea && propertyAvailability
                                  ? ""
                                  : "none"
                              }`,
                            }}
                            href={`mailto:tpv@xcelsz.com?subject=Financial%20Difficulty%20VIP&body=Location:%20${location}%0DEmail:%20${userEmail}%0DProperty Availability:%20${propertyAvailability}%0DAbout:%20${textArea}`}
                            rel="noreferrer"
                            target="_blank"
                          >
                            <button
                              onClick={subForm}
                              className={`dpd-btn ${
                                emailValid && location && userEmail && textArea && propertyAvailability
                                  ? "dpd-btn-h"
                                  : ""
                              }`}
                              type="submit"
                            >
                              Send
                            </button>
                          </a>
                        </div>
                      )}

                      {subConfirm && (
                        <div className="dpd-confirm-wrapper">
                          <div className="dpd-cfm">
                            <div className="dpd-cfm-inner">
                              <button type="reset" onClick={closeSubConfirm}>
                                <img
                                  className="close-confirm"
                                  src={close}
                                  alt="cancel"
                                />
                              </button>
                              <p>
                                Thank you for submitting your request. You'll
                                hear from us with a free no obligation offer
                                within 48 hours.
                              </p>
                            </div>
                          </div>
                          <div className="dpd-cfm-img">
                            <img src={standing} alt="" />
                          </div>
                        </div>
                      )}
                    </div>
                  )}

                  {index > 0 && (
                    <div className="dpd-second-slide-main">
                      <div className="dpd-second-slide">
                        <div className="dpd-ss-head">
                          {index === 1 && (
                            <div>
                              <img src={dArrow} alt="" />
                            </div>
                          )}
                          {index === 2 && <p>Sell Fast</p>}
                        </div>

                        {index === 1 && (
                          <p>We have an outstanding customer conversion rate</p>
                        )}

                        {index === 2 && (
                          <p>Up to 95% of Market Sale<br/> Price</p>
                        )}
                      </div>

                      {index === 1 && (
                        <div className="dpd-second-slide-img">
                          <img src={assist} alt="" />
                        </div>
                      )}


                      {index === 2 && (
                        <div className="dpd-second1-slide-img">
                          {/* <img src={mortgage} alt="" /> */}

                          <div className="dpd-ss1-img">
                         <img src={mortgage} alt="" />
                       </div>
            
                        <div className="dpd-ss2-img">
                        <img src={tenant} alt="" />
                        </div>

                        <div className="dpd-ss3-img">
                       <img src={assistance} alt="" />
                     </div>
                        </div>
                      )}


                      {/* {index === 2 && (
                        <div className="dpd-ss-img"> */}
                          {/* <img src={image5} alt="" />
                         <div className="dpd-ss1-img">
                         <img src={mortgage} alt="" />
                       </div>
                        <div className="dpd-ss2-img">
                        <img src={tenant} alt="" />
                      </div>
                       <div className="dpd-ss3-img">
                       <img src={assistance} alt="" />
                     </div> */}
                     {/* </div>
                      )} */}

                      <div className="dpd-second-slide">
                        <div className="dpd-ss-head">
                          {index === 1 && (
                            <div>
                              <img src={dArrow} alt="" />
                            </div>
                          )}
                          {index === 2 && <p>Rent Fast</p>}
                        </div>
                        {index === 1 && (
                          <p>Property return in the same condition or even better</p>  
                        )}
                        {index === 2 && (
                          <p>Up to 95% of Market Sale<br/> Price</p>
                        )}
                      </div>
                      
                      <div className="dpd-third-slide">
                        <div className="dpd-ss3-head">
                          {index === 1 && (
                            <div>
                              <img src={dArrow} alt="" />
                            </div>
                          )}
                          {index === 2 && <p>Assisted Fast</p>}
                        </div>
                        {index === 1 && (
                          <p className="third3">We offer a chain free house sale</p> 
                        )}
                        {index === 2 && (
                          <p className="third3">Up to 90% of Market Sale<br/> Price</p>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="dpd-slide-btn">
            <button
              className={index === 0 ? "active" : ""}
              onClick={setFirstSlide}
            ></button>
            <button
              className={index === 1 ? "active" : ""}
              onClick={setSecondSlide}
            ></button>
            <button
              className={index === 2 ? "active" : ""}
              onClick={setThirdSlide}
            ></button>
          </div>

          <button
            className="dpd-control-previous"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="prev"
            onClick={prevSlide}
          >
            <img alt="previous" className="leftSlider" src={navArrow} />
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="dpd-control-next"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="next"
            onClick={nextSlide}
          >
            <img alt="next" className="rightSlider" src={navArrow} />
            <span className="visually-hidden">Next</span>
          </button>
        </section>
      </div>
    </>
  );
}

export default DistressedDevelopers;
