import React, { useState } from "react";
import { innerData } from "../../config/slideData";
import '../../views/homePage/HomePage.css'
import leftAndRight from '../../assets/images/bi_arrow-right-circle-fill.svg';
import './Slider.css'

const Slider = () => {
    const [sliders, setSliders] = useState(innerData)
    const [index, setIndex] = useState(0)
    const lastIndex = sliders.length - 1;

    //Next Btn function
    const nextSlide = () => {
        if (index < lastIndex){
            setIndex(index + 1)
        }
        else if (index === lastIndex) {
            setIndex(0)
        }
    }

    //perv Btn function
    const prevSlide = () => {
        if (index > 0){
            setIndex(index - 1)
        }
        else if (index === 0) {
            setIndex(lastIndex)
        }
    }


    // /* Automatic Sliding */
    // useEffect(() => {
    //     const lastIndex = sliders.length - 1;
    //     let slider = setInterval(() => {
    //         setIndex(index => index === lastIndex ? 0 : index + 1);
    //     }, 3000);
    //     return () => clearInterval(slider);
    // }, [sliders]);

    const getTransitionClass = (nextIndex) => {
        if (nextIndex > index) {
          return "carousel-item-next";
        }
        else if (nextIndex < index) {
          return "carousel-item-prev";
        }
        return "";
    };

    return (
        <div className="main-compare">
            <div className="compare-head">{sliders[index] && sliders[index].title}</div>
            {/* commented part was causing auto slide */}
            <div className="carousel slide compare-container">
                <div className="carousel-inner">
                    {
                        sliders.map((slide, i) => {
                            const transitionClass = getTransitionClass(i);
                            const { title, leftContent, rightContent } = slide
                            return (
                                <div className={`carousel-item ${transitionClass} ${i === index ? "active" : ""}`} key={i}>
                                    <div className="compare-inner">
                                        <div className="compare-box">
                                            <div className="caption">DIY / AGENTS / BROKERS</div>
                                            <div className="detail">
                                                <h5 className="break-content">{leftContent}</h5>
                                            </div>
                                        </div>
                                        <div className="versus">
                                            <div className="vs">VS</div>
                                        </div>
                                        <div className="compare-box">
                                            <div className="caption">The Property Vendee (TPV) </div>
                                            <div className="detail">
                                                <h5 className="break-content">{rightContent}</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>

                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev" onClick={prevSlide}>
                    {/* <span className="carousel-control-prev-icon" aria-hidden="true"></span> */}
                    <img alt="previous" className="leftSlider" src={leftAndRight}/>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next" onClick={nextSlide}>
                    {/* <span className="carousel-control-next-icon" aria-hidden="true"></span> */}
                    <img alt="next" className="rightSlider" src={leftAndRight}/>
                    <span className="visually-hidden">Next</span>
                </button>

                <div className="carousel-indicators">
                    {
                        sliders.map((slide, i) => {
                            return (
                                <button
                                    key={i}
                                    style={{backgroundColor: "#111111"}}
                                    className={`${i === index ? "active" : ""}`}
                                    type="button"
                                    data-bs-target="#carouselExampleIndicators"
                                    data-bs-slide-to={`${i}`}
                                    aria-current={i === index ? 'true' : ''}
                                    aria-label={`Slide ${i + 1}`}
                                    onClick={() => setIndex(i)}>
                                </button>
                            )
                        })
                    }
                </div>

            </div>
            <div className="background-card">
                <div className="background-card-black">

                </div>

            </div>
            <div className="background-tiny-house">

            </div>
        </div>
    )
}

export default Slider
