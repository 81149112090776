import './landlord.css'
import leftAndRight from '../../assets/images/bi_arrow-right-circle-fill.svg';
import { useState } from 'react';
import InputField from '../stopRepossession/InputField';
import LandlordCards from './LandlordCards';
import middleImg from '../../assets/images/Frame 254.svg'
import { useFormik } from 'formik';
import emailjs from '@emailjs/browser';

const LandLordCta = () => {
    const [index, setIndex] = useState(0)
    const formik = useFormik({
        initialValues: {
            location: "",
            email: "",
            situation: ""
        }
    })
    const lastIndex = 2

    const submitEmail = (event) => {
        event.preventDefault()
        const templateParams = {
            location: formik.values.location,
            email: formik.values.email,
            situation: formik.values.situation
        }

        emailjs.send('service_lrekcyz', 'template_r2bnnhn', templateParams, 'cm_1OMdDgstBwtAkp')
            .then((result) => {
                console.log('SUCCESS!', result.status, result.text)
                formik.setFieldValue("location", '')
                formik.setFieldValue("email", '')
                formik.setFieldValue("situation", '')
            }, (error) => {
                console.log('FAILED...', error);
        });

    }

    //Next Btn function
    const nextSlide = () => {
        if (index < lastIndex) {
            setIndex(index + 1)
        }
        else if (index === lastIndex) {
            setIndex(0)
        }
    }

    //perv Btn function
    const prevSlide = () => {
        if (index > 0) {
            setIndex(index - 1)
        }
        else if (index === 0) {
            setIndex(lastIndex)
        }
    }
    return (
        <div className='landlord-cta'>
            {
                index === 0 && (
                    <div>
                        <div className='landlord-cta-header-1 fontStyles'>
                            <span>You know someone who can use the Landlords Solution?</span>
                        </div>
                        <div className='landlord-cta-subheader-1 fontStyles'>
                            <span>Get them on the LandLord's VIP List Now</span>
                        </div>
                    </div>
                )
            }
            {
                index === 1 && (
                    <div className='header-wrapper'>
                        <div className='landlord-cta-header-2 fontStyles'>
                            <span>Why It Works ?</span>
                        </div>
                        <div className='landlord-cta-subheader-2 fontStyles'>
                            <span>We take on any property in any condition, any location, for any reason.</span>
                        </div>
                    </div>
                )
            }
            {
                index === 2 && (
                    <div className='header-wrapper'>
                        <div className='landlord-cta-header-2 fontStyles'>
                            <span>Whatever your circumstance may be, Rent Fast</span>
                        </div>
                        <div className='landlord-cta-subheader-2 fontStyles'>
                            <span>Your Perfect Tenant 3 Offers away</span>
                        </div>
                    </div>
                )
            }

            <div className='landlord-cta-body'>

                <div className='landlord-cta-body-inner'>
                    {
                        index === 0 && (
                            <form className='landlord-form' onSubmit={submitEmail}>
                                <InputField number={1} colorClass='landlord-form-number' inputClass={"landlord-form-field"} inputTag={<input placeholder='Select Location' name='location' value={formik.values.location} onChange={formik.handleChange} className='landlord-form-input' required></input>} />
                                <InputField number={2} colorClass='landlord-form-number' inputClass={"landlord-form-field"} inputTag={<input type='email' placeholder='Input Your Email' name='email' value={formik.values.email} onChange={formik.handleChange} className='landlord-form-input' required></input>} />
                                <InputField number={3} colorClass='landlord-form-number' inputClass={"landlord-form-field"} inputTag={<input placeholder='About you and their Situations' value={formik.values.situation} onChange={formik.handleChange} name='situation' className='landlord-form-input' required></input>} />
                                <button className='landlord-btn' type='submit'>Send</button>
                            </form>
                        )
                    }
                    {
                        index === 1 && (
                            <div className='landlord-features'>
                                <div className='top-layer'>
                                    <LandlordCards text={"We have an outstanding customer conversion rate"} />
                                    <img src={middleImg} alt='image' />
                                    <LandlordCards text={"Property return in the same condition or even better"} />
                                </div>
                                <LandlordCards text={"We offer a chain free house sale"} />
                            </div>
                        )
                    }
                    {
                        index === 2 && (
                            <div className='landlord-features'>
                                <div className='top-layer'>
                                    <LandlordCards text={"Up to 95% of Market Sale Price"} />
                                    <img src={middleImg} alt='image' />
                                    <LandlordCards text={"Up to 100% of Market Rent"} />
                                </div>
                                <LandlordCards text={"Up to 90% of Market Sale Price"} />
                            </div>
                        )
                    }
                </div>
                <button className="carousel-control-prev landlord-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev" onClick={prevSlide}>
                    {/* <span className="carousel-control-prev-icon" aria-hidden="true"></span> */}
                    <img alt="previous" className="leftSlider" src={leftAndRight} />
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next landlord-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next" onClick={nextSlide}>
                    {/* <span className="carousel-control-next-icon" aria-hidden="true"></span> */}
                    <img alt="next" className="rightSlider" src={leftAndRight} />
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
            <div className='slide-indicators'>
                {/* i add l to active because there are other pages using active and 
                your background color is affect them by kingsharp */}
                <div className={`${index === 0 && "active-l"} landlord-circle`}></div>
                <div className={`${index === 1 && "active-l"} landlord-circle`}></div>
                <div className={`${index === 2 && "active-l"} landlord-circle`}></div>
            </div>
        </div>
    )
}

export default LandLordCta
