import loan from '../../assets/images/loan(1)  2.svg';
import image from '../../assets/images/image(2) 1.png';
import "./About.css";
import loan2 from '../../assets/images/loan 2.png';

function About() {
    return(
        <section className="fdo-about-section">
            <p class="fdo-about-head">About TPV</p>
            <p class="fdo-about-head">Why Us?</p>
            <div class="fdo-about-main">
                <div class="fdo-left-img">
                    <img src={loan} alt="" />
                    <img src={loan2} alt="" />
                </div>
                <div class="fdo-right-img">
                    <p class="fdo-about">
                        We are a professional, Independent Homebuyers and Relocation Specialist, and because we market direct to you the Home Owner, we can present you with great offers you can’t miss, with <span>ZERO</span> fees to you
                    </p>
                    <a href='/about'><button>READ MORE</button></a>
                </div>
            </div>
            
            <div class="fdo-about-btm">
                <div class="fdo-about-btm-img">
                    <img src={image} alt="" /><p>Choose completion date suitable to YOU</p>
                </div>
                <div class="fdo-about-btm-img">
                    <img src={image} alt="" /><p>Get an offer within  48 hours</p>
                </div>
            </div>
        </section>
    )
}


export default About;