import './HomeSellers.css';
// import '../Homesellers(mobile)/Mobileview.css';
import { useState, useRef } from "react";

import userData from '../../assets/images/user-data 1.svg';
import building from '../../assets/images/buildings 1.svg';
import virtual from '../../assets/images/virtual 1.svg';
import negotiate from '../../assets/images/negotiate1.svg'
import dArrow from '../../assets/images/double-arrow.webp';
import standing from '../../assets/images/standing.png';
import close from '../../assets/images/closeBtn.png';
import navArrow from '../../assets/images/bi_arrow-right-circle-fill.svg';
import dropDownArrow from '../../assets/images/yellow-dropdown.svg';
import rectangle from '../../assets/images/homesellers/Rectangle.png';
import loanHand from '../../assets/images/homesellers/loan.png';
import firstContainer from '../../assets/images/homesellers/content1.svg';
import secondContainer from '../../assets/images/homesellers/content2.svg';
import thirdContainer from '../../assets/images/homesellers/content3.svg';
import buy from '../../assets/images/homesellers/content4.svg';
import referral from '../../assets/images/homesellers/referral.svg';
import sales from '../../assets/images/homesellers/content5.svg';

import emailjs from '@emailjs/browser';


function HomeSellers () {

    const [index, setIndex] = useState(0);
    const [emailValid, setEmailValid] = useState();
    const [location, setLocation] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [textArea, setTextArea] = useState('');
    const [subConfirm, setSubConfirm] = useState();
    const [dropDown, setDropDown] = useState(0);

    const email = useRef();
    const text = useRef();
    const country = useRef();


    // Button slider
    const setFirstSlide = () => {
        setIndex(0)
    }

    const setSecondSlide = () => {
        setIndex(1)
    }

    const setThirdSlide = () => {
        setIndex(2)
    }



    //Next Btn function
    const nextSlide = () => {
    
        if (index === 2){
            setIndex(0)
        }
        else if (index < 2){
            setIndex(index+1)
        }   
    }
    

    //prev Btn function
    const prevSlide = () => {

        if (index > 0 ){
            setIndex(index-1)
        }
        else if (index === 0) {
            setIndex(2)
        }
    }

    const closeSubConfirm = (e) =>{
        e.preventDefault();
        console.log("Subscription confirm closed");
        setSubConfirm();
    }


    const ValidateEmail = (input) => {

        const validEmail = /^[A-Za-z\._\-0-9]*[@][A-Za-z]*[\.][a-z]{2,4}$/;
        const emailtxt = document.querySelector(".hs-email");
        const error = document.querySelector(".hs-error");

        if (input === "" || input.match(validEmail)) {
            error.style.display = "none";
            // emailtxt.style.outline = '1px solid #fac710';
            emailtxt.style.border = '2px solid #fac710';
            setEmailValid(1);
            // return true;
        } else {

          console.log("Invalid email address!");
          error.style.display = "block";
        //   emailtxt.style.outline = "2px solid red";
          emailtxt.style.border = '2px solid red';
          setEmailValid(0);
        //   return false;

        }

      }

    const clearField = () => {
        email.current.value = '';
        text.current.value = '';
        country.current.value = 'Select Location';
    }

    const form = useRef();

    const submitHomeSellerForm = (e)=>{
        e.preventDefault();

        if (emailValid){

            emailjs.sendForm('service_lrekcyz', 'template_0sgyoak', form.current, 'cm_1OMdDgstBwtAkp')
            .then((result) => {
                console.log('sent');
                setSubConfirm(1);
                // clearField();
                setLocation('');

            }, (error) => {
                console.log(error);
                console.log('not sent');
            });

        }else{
            console.log("Invalid email")
        }

    }

    const locDropDown = () => {
        if (dropDown === 0){
            setDropDown(1);
        }else {
            setDropDown(0)
        }
    }

    const selectLocation = (i) => {
        if (i === 0){
            setLocation('Ghana');
        }
        else if (i === 1) {
            setLocation('United Kingdom');
        }
        else if (i === 2) {
            setLocation('Nigeria');
        }
        setDropDown(0);
    }

    const subForm = () => {
        if (emailValid && location && textArea ){
            setSubConfirm(1);
            setLocation('');
        }
    }

    return(
        <>

            <div className="hs-wrapper"> 
                <section className="hs-top">
                    <div className="hs-top-left">
                        <h1 className="hs-heading"> 
                            Stress Free Selling
                            Made <span className="yellow"> Easy</span>  
                        </h1>
                        <h3>
                            Your perfect buyer  right away at absolutely no hassle or cost to you.
                        </h3>

                        {/*Mobile-view writeup*/}
                        <h3 id='mobile-writeup'>Your perfect buyer available right away</h3>
                        
                        <div className="hs-container">
                            <img src={userData} alt=""/>
                            <p id='desktop-view'>No eviction, legal costs or commission fees</p>
                        
                        {/*Mobile-view writeup*/}
                            <p id='mobileview-para1'>No legal or commission fees</p>
                        </div>

                        <div className="hs-container">
                            <div>
                                <img src={building} style={{borderRadius: '5px'}} alt=""/>
                            </div>
                            <p className="para">No more chain break</p>
                        </div>
                        <div className="hs-container">
                            <img src={virtual} alt=""/>
                            <p>The house doesn’t need to be in a good state</p>
                        </div>
                    </div>
                    <div className="hs-background">

                    </div>
                </section>

                <section className="hs-slide-section">
                    <div className="hs-head">
                        <h2>
                            Whatever your situation there is an offer that fits it
                            <span className="yellow"> ...</span>..
                        </h2>

                        {/*Mobile-view writeup*/}
                        <h2 id='mobileview-heading'>
                            Access Great Offers Which Fits Your Situation
                            <span className="yellow"> ...</span>..
                        </h2>

                    </div>
                    <div className='hs-slider'>
                        {
                            index === 0 &&
                            <div className='hs-slider-headers'>
                                <div className="hs-slider-head">
                                    <p id='desktop-access'>You know someone who can use the Home Sellers Solution?</p>
                                    
                                    {/*Mobile-view writeup*/}
                                    <p id='mobile-access'>You know someone who can use the Financial Difficulty Solution?</p>
                                </div>
                                <div className="hs-slider-head" style={{background: '#fac710'}}>
                                    <p id='desktop-access' style={{width: '660px', fontSize: '35px', lineHeight: '42px'}}>Get them on the Home Seller's  VIP List Now</p>
                                    
                                    {/*Mobile-view writeup*/}
                                    <p id='mobile-access2'>Get them on the Financial Difficulty VIP List Now</p>
                                </div>
                            </div>
                        }
                        {
                            index === 1 &&
                            <div className='hs-slider-headers'>
                                <div className="hs-slider-head" style={{background: '#fac710'}}>
                                <p style={{fontSize: '60px', lineHeight: '72px'}}>Why it Works?</p>
                                </div>
                                <div className="hs-slider-head">
                                    <p style={{fontSize: '30px', width: '709px'}}>We take on any property in any condition, any location, for any reason.</p>
                                </div>

                                {/*Mobileview Codeblock*/}
                                <div className='slide-inner-container' id='mobile-writeup'
                                style={{height: '620px'}}>
                                </div> 

                            </div>
                        }
                        {
                            index === 2 &&
                            <div className='hs-slider-headers'>
                                <div className="hs-slider-head" style={{background: '#fac710'}}>
                                <p style={{fontSize: '41px', width: '100%', fontWeight: '600'}}>Whatever your circumstance may be, Sell Fast</p>
                                </div>
                                <div className="hs-slider-head">
                                    <p style={{fontSize: '36px', width: '712px'}}>Money in your pocket 3 Offer  away<span className='yellow'>...</span></p>
                                </div>

                                {/*Mobileview Codeblock*/}
                                <div className='slide-inner-container' id='mobile-writeup'
                                style={{height: '620px'}}>
                                </div>

                            </div>
                        }
                        <div className="hs-main-slider">
                            <div className="hs-slide-container" >
                                <div className="slide-inner-container">
                                    {
                                        index === 0 &&
                                        <div class='form-container' style={{width: 'content', height: 'content'}}>
                                            {
                                                !subConfirm &&
                                                <div className='customer-review-card' style={{height: '429px', display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
                                                    <form className='hs-form' ref={form} onSubmit={submitHomeSellerForm}>
                                                        <div className='email-subject' style={{display: 'none'}}>
                                                            <input type='text' name='header' value={'Home Sellers VIP'} readOnly />
                                                        </div>
                                                        <div className='hs-input-field'>
                                                            <div className='case-number' id='one' style={{width: '80px', height: '60px'}}>1</div>
                                                            
                                                            {/* Default Dropdown Menu */}
                                                            {/* <select name='location' onChange={(e)=>setLocation(e.target.value)} required> 
                                                                <option value=''>Select Location</option>
                                                                <option value='Ghana'>Ghana</option>
                                                                <option value='United Kingdom'>United Kingdom</option>
                                                                <option value='Nigeria'>Nigeria</option>
                                                            </select> */}
                                                            
                                                            {/* Customized Dropdown Menu */}
                                                            <div className='hs-select-field'>
                                                                <input type='text' name='location' className='select-input' value={location} onClick={locDropDown} placeholder='Select Location' readOnly required/>
                                                                <img className={dropDown === 1 ? 'activee' : ''} onClick={locDropDown} src={dropDownArrow} alt='dropdown' />
                                                                {
                                                                    dropDown === 1 &&
                                                                    <div className='hs-select'>
                                                                        <ul className='select'>
                                                                            <li className='select-option' onClick={(e)=>{selectLocation(0)}}>Ghana</li>
                                                                            <li className='select-option' onClick={(e)=>{selectLocation(1)}}>United Kingdom</li>
                                                                            <li className='select-option' onClick={(e)=>{selectLocation(2)}}>Nigeria</li>
                                                                        </ul>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className='hs-input-field'>
                                                            <div className='case-number' id='two' style={{width: '80px', height: '60px'}}>2</div>
                                                            <input type='email' name='user_email' className='hs-email' placeholder='Input Your Email' onChange={(e)=>setUserEmail(e.target.value)} onKeyUp={(e)=>ValidateEmail(e.target.value)} required />
                                                        </div>
                                                        <span className="hs-error">please enter a valid email: example@domain.com</span>
                                                        <div className='hs-input-field'>
                                                            <div className='case-number' id='three' style={{width: '80px', height: '60px'}}>3</div>
                                                            <textarea type='textarea' id='textarea' name='about' placeholder='About you and thier Situations' onChange={(e)=>{setTextArea(e.target.value)}} required></textarea>
                                                        </div>
                                                        {/* Uncomment line below to submit form to submit form directly to tpv@xcelsz.com*/}
                                                        <input type='submit' value='Send' />
                                                    </form>
                                                    {/* Comment this out when using form to submit directly to tpv@xcelsz.com */}
                                                    {/* <a style={{pointerEvents: `${emailValid && location && userEmail && textArea ? '' : 'none'}`}} href={`mailto:tpv@xcelsz.com?subject=Home%20Sellers%20VIP&body=Location:%20${location}%0DEmail:%20${userEmail}%0DSituation:%20${textArea}`} rel="noreferrer" target="_blank">
                                                        <button onClick={subForm} className={`hs-btn ${emailValid && location && userEmail && textArea ? 'hs-btn-h' : ''}`} type='submit'>Send</button>
                                                    </a> */}
                                                </div>
                                            }

                                            {
                                                subConfirm &&
                                                <div className='hs-confirm-wrapper'>
                                                    <div className='hs-cfm'>
                                                        <div className='hs-cfm-inner'>
                                                            <button type="reset" onClick={closeSubConfirm}>
                                                                <img className="close-confirm" src={close} alt="cancel"/>
                                                            </button>
                                                            <p>Thank you for submitting your request. You'll hear from us with a free no obligation offer within 48 hours.</p>
                                                        </div>
                                                    </div>
                                                    <div className='hs-cfm-img'>
                                                        <img src={standing} alt='' />
                                                    </div>

                                                </div>
                                            }
                                            
                                        </div>                                     
                                        
                                    }
                                    {
                                        index > 0 &&
                                        <div className='hs-second-slide-main'>
                                            <div style={{display: 'flex', alignItems: 'center'}}>
                                                <div className='hs-second-slide'>
                                                    <div className='hs-ss-head'>
                                                    {
                                                        index === 1 &&
                                                        <div>
                                                            <img src={dArrow} alt='' />
                                                        </div>
                                                    }
                                                    {
                                                        index === 2 &&
                                                        <p>Direct Buy</p>
                                                    }
                                                        
                                                    </div>
                                                    {
                                                        index === 1 &&
                                                        <p>
                                                            Different Needs, Different Solutions
                                                        </p> 
                                                    }
                                                    {
                                                        index === 2 &&
                                                        <p style={{fontSize: '30px'}}>
                                                            Up to 95% of Market Sale Price
                                                        </p> 
                                                    }
                                                                                                   
                                                </div>
                                                {
                                                    index === 1 &&
                                                    <div className='hs-second-slide-img'>
                                                        <img src={negotiate} alt='' />
                                                    </div>
                                                }
                                                {
                                                    index === 2 &&
                                                    <div className='hs-circle'>
                                                        <span>
                                                            VS
                                                        </span>
                                                    </div>
                                                }
                                                <div className='hs-second-slide'>
                                                    <div className='hs-ss-head'>
                                                    {
                                                        index === 1 &&
                                                        <div>
                                                            <img src={dArrow} alt='' />
                                                        </div>
                                                    }
                                                    {
                                                        index === 2 &&
                                                        <p>Referral Buy</p>
                                                    }
                                                        
                                                    </div>
                                                    {
                                                        index === 1 &&
                                                        <p>
                                                            Free Appraisal, No Fees to you whatsoever
                                                        </p> 
                                                    }
                                                    {
                                                        index === 2 &&
                                                        <p style={{fontSize: '30px'}}>
                                                            Up to 95% of Market Sale Price
                                                        </p> 
                                                    }
                                                                                                    
                                                </div> 
                                            </div>
                                            
                                            <div className='hs-second-slide'>
                                                <div className='hs-ss-head'>
                                                {
                                                    index === 1 &&
                                                    <div>
                                                        <img src={dArrow} alt='' />
                                                    </div>
                                                }
                                                {
                                                    index === 2 &&
                                                    <p>Assisted Sale</p>
                                                }
                                                    
                                                </div>
                                                {
                                                    index === 1 &&
                                                    <p>
                                                        Choose a closing date that works for you
                                                    </p> 
                                                }
                                                {
                                                    index === 2 &&
                                                    <p style={{fontSize: '30px'}}>
                                                        Up to 90% of Market Sale Price
                                                    </p> 
                                                }                                                
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="hs-slide-btn">
                        <button className={index === 0 ? 'activee' : ''} onClick={setFirstSlide}></button>
                        <button className={index === 1 ? 'activee' : ''} onClick={setSecondSlide}></button>
                        <button className={index === 2 ? 'activee' : ''} onClick={setThirdSlide}></button>
                    </div>

                    <button className="hs-control-previous" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev" onClick={prevSlide}>
                        <img alt="previous" className="leftSlider" src={navArrow}/>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="hs-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next" onClick={nextSlide}>
                        <img alt="next" className="rightSlider" src={navArrow}/>
                        <span className="visually-hidden">Next</span>
                    </button>
                </section>
            </div>

                

            
            {/*SLIDE 2 & 3 FOR MOBILE VIEW*/}
            {
                index ===1 &&
                <div className='slide-two-image' id='mobile-writeup'>
                    <div className='first-index-image'>
                        <img src={firstContainer} alt='writeup' className='first-img' />
                    </div>
                    <div className='first-index-image'>
                        <img src={secondContainer} alt='writeup' className='second-img' />
                    </div>
                    <div className='first-index-image'>
                        <img src={thirdContainer} alt='writeup' className='third-img' />
                    </div>
                </div>
            }
            {
                index ===2 &&
                <div className='slide-two-image' id='mobile-writeup'>
                    <div className='first-index-image'>
                        <img src={buy} alt='writeup' className='first-img' />
                    </div>
                    <div className='first-index-image'>
                        <img src={referral} alt='writeup' className='second-img' />
                    </div>
                    <div className='first-index-image'>
                        <img src={sales} alt='writeup' className='third-img' />
                    </div>
                </div>

            }

            
            {/*WHY US SECTION FOR MOBILE VIEW*/}
            <section className='whyUs-Mobileview'>
                <div className='why-us-container'>
                    <h1 className='why-us-heading'>
                        Why Us?
                    </h1>
                    <div className='why-us-inner-container'>
                        <div className='content-container'>
                            <div className='image-container'>
                            <img src={rectangle} alt='shape' className='rectangle' />
                            <img src={loanHand} alt='loan' className='loanHand'/>
                            <p className='container-text'>We are a professional, Independent <br />Homebuyers and <br />Relocation Specialist, and
                            <br />because we market direct <br />to you the Home Owner, <br />we can present you with <br />great offers you can't <br />miss, 
                            with <span className='zero'>ZERO</span> fees to <br />you</p>
                        </div>
                        <button className='readmore'>READ MORE</button>
                        </div>
                    </div>
                    
                    <div className='why-us-bottom'>
                        <img src={dArrow} id='bottom-arrow'></img>
                        <p className='bottom-text'> Get an offer within 48 hours</p>
                        </div>

                        <div className='why-us-bottom'>
                        <img src={dArrow} id='bottom-arrow'></img>
                        <p className='bottom-text'> Choose completion date suitable to YOU.</p>
                        </div> 
                </div>
            </section>
        </>
    );

}

export default HomeSellers;