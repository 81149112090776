import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./TermsConditions.css";

function TermsConditions() {
  const [activeNumber, setActiveNumber] = useState(1);
  const [expanded, setExpanded] = useState([]);

  const handleToggle = (index) => {
    setExpanded((prevExpanded) => {
      const newExpanded = [...prevExpanded];
      newExpanded[index] = !newExpanded[index];
      return newExpanded;
    });
    setActiveNumber(index + 1);
  };

  const termData = [
    {
      text: "Introduction",
      content:
        'These terms and conditions (the "Terms") govern your use of Xcelsz\'s website and all related services (the "Website"). You consent to be legally bound by these terms by using the website. You must not use the website if you disagree with these terms.',
    },
    {
      text: "Service Description",
      content: (
        <div className="service-description">
          We are Agile Digital, Coaching, and PropTech Solutions that aims to:
          <ul className="list-style">
            <br />
            <li className="lil"> Transform the way MSMEs do business </li>
            <br />{" "}
            <li className="lil">
              Make the property accessible to all, hands-off, Hassle-Free, fun
              and simple
            </li>
            <br />{" "}
            <li className="lil">
              Help organizations and individuals to embed Agile as a new way of
              thinking and working to create a greater value faster for their
              businesses, customers, team, and themselves
            </li>
            <br />{" "}
            <li className="lil">
              We develop digital products like Local Stayz that take the stress
              out of finding dream homes and workplaces
            </li>
          </ul>
        </div>
      ),
    },

    {
      text: "Eligibility",
      content: (
        <div className="eligibility-description">
          {" "}
          The Website is intended solely for individuals who are 18 or older.
          You guarantee and declare that you are at
          <br /> least 18 years old and legally able to enter into contracts by
          using the website
        </div>
      ),
    },

    {
      text: "Use of the Website",
      content: (
        <div className="use-of-website-description">
          You may only use the Website for lawful purposes and in accordance
          with these Terms. You agree not to use the website:
          <ul>
            <li>
              In any way that violates any applicable federal, state,local, or
              international law or regulation
            </li>{" "}
            <br />
            <li>
              To transmit, or procure the sending of, any advertising or
              promotional material, including any "junk mail", "chain letter",
              "spam" or any other similar solicitation
            </li>
            <li>
              To impersonate or attempt to impersonate Xcelsz, an Xcelsz
              employee, another user, or any other person or entity.
            </li>
            <li>
              To engage in any other conduct that restricts or inhibits anyone's
              use or enjoyment of the Website, or which, as determined by
              Xcelsz, may harm Xcelsz or users of the Website or expose them to
              liability
            </li>
            <li>
              By exposing them to improper content, requesting personally
              identifiable information from them, or in any other way, with the
              intention of exploiting, injuring, or attempting to exploit or
              harm children.
            </li>
          </ul>
        </div>
      ),
    },

    {
      text: "User Content",
      content: (
        <div className="user-content-description">
          The Website may allow you to submit, upload, publish, or otherwise
          make available content, including but not limited to text,
          photographs, videos, and audio (collectively, "User Content"). The
          user content you make available on the website is your sole
          responsibility and is subject to all rights that you still hold.
        </div>
      ),
    },

    {
      text: "Intellectual Property",
      content: (
        <div className="intellectual-property-description">
          The Website and its entire contents, features, and functionality
          (including but not limited to all information, software, text,
          displays, images, video, and audio, and the design, selection, and the
          arrangement thereof), are owned by Xcelsz, its licensors, or other
          providers of such material and are protected by United States and the
          international copyright, trademark, patent, trade secret, and other
          intellectual property or proprietary rights laws.
        </div>
      ),
    },

    {
      text: "Disclaimers",
      content: (
        <div className="disclaimers-description">
          The Website and its content are provided "as is" without any
          warranties of any kind, either express or implied. The company does
          not warrant that the website will be available at all times, or that
          the information contained on the website is accurate, complete, or
          up-to-date.
        </div>
      ),
    },

    {
      text: "Limitation of Liability",
      content: (
        <div className="limitation-description">
          In no event will Xcelsz, its affiliates, or their licensors, service
          providers, employees, agents, officers, or directors be liable for
          damages of any kind, under any dispute relating to or resulting from
          your use of, or inability to use the website, any websites that are
          linked to it, any information on the website, or any other websites,
          or services obtained through the Website or such other websites,
          including any direct, indirect, special, incidental, consequential, or
          punitive damages, including but not limited to, personal injury, pain,
          mental anguish, loss of money, loss of profit, loss of anticipated
          savings, loss of use, loss of goodwill, loss of data, and if due to
          tort( including, but not limited to, negligence), breach of contract.
        </div>
      ),
    },

    {
      text: "Indemnification",
      content: (
        <div className="indemnification-description">
          You agree to indemnify, defend and hold the company, its directors
          officers, employees, agents, and suppliers harmless from and against
          any and all claims, damages, liabilities, and expenses arising from
          your use of the website.
        </div>
      ),
    },

    {
      text: "Changes to the terms and conditions",
      content: (
        <div className="terms-conditions-description">
          'The company reserves the right to modify these terms and conditions
          at any time, and your continued use of the website after any
          acceptance of updates after they have been posted will constitute such
          changes.
        </div>
      ),
    },

    {
      text: "Governing Law",
      content: (
        <div className="governing-law-description">
          These terms and conditions shall be governed by and construed in
          accordance with the laws of the jurisdiction in which the company is
          incorporated, without giving effect to any principles of conflicts of
          law.
        </div>
      ),
    },

    {
      text: "Dispute resolution",
      content: (
        <div className="dispute-description">
          Any disputes arising out of or related to these terms i and conditions
          or the use of the website shall be resolved by binding arbitration in
          accordance with the rules of the country the company is being
          incorporated.
        </div>
      ),
    },

    {
      text: "Miscellaneous",
      content: (
        <div className="miscellaneous">
          These terms and conditions constitute the entire agreement between you
          and the company with respect to your use of the website. The other
          terms and conditions shall remain in full force and effect if any
          provision of these terms and conditions is determined to be defective
          or unenforceable.
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="backgroundt"></div>
      <section className="terms-top">
        <div className="terms-top-left">
          <h1 className="terms-heading">Terms & Conditions</h1>
          <h1 className="value-text">We Have Value For You</h1>
        </div>
      </section>

      <section className="tc-types">
        <div className="types-border">
          <h2>Select Our Terms Type</h2>

          <div className="numbers">
            <Link
              to="/terms"
              className={`num ${activeNumber === 1 ? "active" : ""}`}
            >
              1
            </Link>
            <Link
              to="/privacyPolicy"
              className={`num ${activeNumber === 2 ? "active" : ""}`}
            >
              2
            </Link>
            <Link
              to="/cookies"
              className={`num ${activeNumber === 3 ? "active" : ""}`}
            >
              3
            </Link>
          </div>
        </div>
      </section>

      <p className="below-text">
        Below are our terms of use of this website <br /> whether as a guest or
        a registered user
      </p>

      <div className="tcs">
        {termData.map((term, index) => (
          <React.Fragment key={index}>
            <div className={`term-border ${expanded[index] ? "tc-expanded" : ""}`}>
              <div className="expand-btn" onClick={() => handleToggle(index)}>
                {expanded[index] ? (
                  <span className="minus">-</span>
                ) : (
                  <span className="plus">+</span>
                )}
              </div>
              <span className="text">{term.text}</span>
              {expanded[index] && (
                <div
                  className={`dropdown-border ${
                    index === 0 ? "introduction-description" : ""} ${
                    index === 1 ? "service-description-dropdown" : ""
                  } ${index === 2 ? "eligibility-description-dropdown" : ""} ${
                    index === 3 ? "use-of-website-description-dropdown" : ""
                  } ${index === 4 ? "user-content-description-dropdown" : ""} ${
                    index === 5
                      ? "intellectual-property-description-dropdown"
                      : ""
                  } ${index === 6 ? "disclaimers-description-dropdown" : ""} ${
                    index === 7 ? "limitation-description-dropdown" : ""
                  } ${
                    index === 8 ? "indemnification-description-dropdown" : ""
                  } ${
                    index === 9 ? "terms-conditions-description-dropdown" : ""
                  } ${index === 10 ? "governing-law-description-dropdown" : ""} ${
                    index === 11 ? "dispute-description-dropdown" : ""
                  } ${index === 12 ? "miscellaneous-description-dropdown" : ""}`}
                >
                  <span className="the-text">{term.content}</span>
                </div>
              )}
            </div>
            <div className="tc-line"></div>
          </React.Fragment>
        ))}
      </div>
      
    </>
  );
}

export default TermsConditions;
