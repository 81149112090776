import './RepossessionCard.css'
const RepossessionCard = ({ image, text, backColor, foreColor, extra=undefined }) => {
    return (
        <div style={{ backgroundColor: backColor }} className='repo-card'>
            <span className='image-wrapper'>
                <img src={image} alt='card-1' />
                {extra !== undefined && <img className='extra-image' src={extra} alt='extra'/>}
            </span>
            <span style={{ color: foreColor }}>{text}</span>
        </div>
    )
}

export default RepossessionCard
