const DropDownMenu = ({items}) => {
    return (
        <div className="parent">
            {items.map((item) => {
                const { id, dropDownItem } = item
                return (
                    <div className="child" key={id}>
                        {dropDownItem}
                    </div>
                )
            })}
        </div>
    )
}

export default DropDownMenu
