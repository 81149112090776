import { useState, useRef } from 'react'
import './StopRepossession.css'
import './my_stop_repossession.css'
import Eurosign from '../../assets/images/Euro-sign.png'
import Cedissign from '../../assets/images/Cedis-sign.png'
import Nairasign from '../../assets/images/Naira-sign.png'
import Cta from './Cta';
import backgroundPearShape from '../../assets/images/pear-shaped.svg'
import mobilePear from "../../assets/images/mobile-pear.svg"
import RepossessionCard from './RepossessionCard';
import cardImage1 from '../../assets/images/user-data 1.svg'
import cardImage2 from '../../assets/images/buildings 1.svg'
import cardImage3 from '../../assets/images/virtual 1.svg'
import ellipse from '../../assets/images/Ellipse 30.svg'
import rightSectionImage from '../../assets/images/Money stress-bro 2.svg'
import { useFormik } from "formik";
import CustomSelect from './CustomSelect'
import options from "../../config/SelectData"
import emailjs from '@emailjs/browser';


function StopRepossession() {
    const form = useRef();
    const [descriptionText, setDescriptionText] = useState('Selling your home made easy')
    const [currency, setCurrency] = useState(Eurosign);
    const [docsUpload, setDocsUpload] = useState('hidden');
    const [imgUpload, setImgUpload] = useState('hidden');
    const [formIndex, setFormIndex] = useState(2)
    const [warning, setWarning] = useState(false)
    const uploadImageRef = useRef()
    const country = useRef('')
    const iAmRef = useRef()
    const formik = useFormik({
        initialValues: {
            clientType: "",
            country: "",
            postcode: "",
            propertyType: "",
            numOfBedrooms: "",
            propertyState: "",
            lastRenovation: "",
            purchaseDate: "",
            standingLoan: "",
            purchasedPrice: "",
            file: "null",
            clientName: "",
            email: ""
        },
    })

    const viewUploadDoc = (e) => {
        e.target.classList.add('hidden')
        setDocsUpload('visible')
    }
    const viewUploadImg = (e) => {
        e.target.classList.add('hidden')
        setImgUpload('visible')
    }

    const setCountry = (e) => {
        country.current = e.target.value
    }

    const changeFromDescr = (e) => {
        if (e.target.value == 'Sell Fast') {
            setDescriptionText('Selling your Home made Easy... ')
        } else if (e.target.value == 'Rent Fast') {
            setDescriptionText('Finding your Perfect Tenant Made Easy...')
        } else if (e.target.value == 'Assited Fast') {
            setDescriptionText('Stopping Repossession made Easy ...')
        }
    }

    const handleDataCollection = () => {
        if (formIndex === 2) {
            if (formik.values.clientType === "") {
                setWarning(true)
                setTimeout(() => {
                    setWarning(false)
                }, 3000);
                iAmRef.current.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
                return
            }
            else if (uploadImageRef.current.state.files.length === 0) {
                const imageUpload = document.getElementById('image-upload')
                imageUpload.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
                imageUpload.setAttribute("style", "border-color: red")
                setTimeout(() => {
                    imageUpload.setAttribute('style', 'border-color: rgba(0, 0, 0, 0.08)')
                }, 1000);
                return
            }
            const templateParams = {
                clientType: formik.values.clientType,
                country: formik.values.country,
                postcode: formik.values.postcode,
                propertyType: formik.values.propertyType,
                numOfBedrooms: formik.values.numOfBedrooms,
                propertyState: formik.values.propertyState,
                lastRenovation: formik.values.lastRenovation,
                purchaseDate: formik.values.purchaseDate,
                standingLoan: formik.values.standingLoan,
                purchasedPrice: formik.values.purchasedPrice,
                file: formik.values.file.newState || undefined,
                clientName: formik.values.clientName,
                email: formik.values.email
            }
            emailjs.send('service_lrekcyz', 'template_r2bnnhn', templateParams, 'cm_1OMdDgstBwtAkp')
                .then((result) => {
                    console.log('SUCCESS!', result.status, result.text)
                    setFormIndex(formIndex + 1)
                }, (error) => {
                    console.log('FAILED...', error);
                });
            console.log(formik.values)
        }
        else {

            setFormIndex(formIndex + 1)
            if (formIndex < 2) {
                switch (formik.values.country) {
                    case 'Ghana':
                        setCurrency(Cedissign)
                        break;
                    case 'UK':
                        setCurrency(Eurosign)
                        break;
                    case 'NigersetFormIndexia':
                        setCurrency(Nairasign)
                        break;
                    default:
                        break;
                }
            }
        }
    }

    const reverseStep = () => {
        setFormIndex(formIndex - 1)
    }
    return (
        <>
            <section className="mobile-first-section">
                <h1 className='heading max-content'>Get a free no obligation “stop repossession” <span className='yellow-text'>offer</span>.</h1>
                <img src={rightSectionImage} alt='woman stressed in image' />
                <h5 className='sub-heading'>At absolutely no cost to you to request an offer to stop repossession on your home at The Property Vendee (TPV).</h5>
                <RepossessionCard image={cardImage1} text={"Immediate help that fits your needs"} backColor={"var(--white-background)"} foreColor={"var(--navbar-color)"} />
                <RepossessionCard image={cardImage2} text={"Save with stress with no additional fees"} backColor={"var(--navbar-color)"} foreColor={"var(--white-background)"} extra={ellipse} />
                <RepossessionCard image={cardImage3} text={"No more feeling alone"} backColor={"#FFECB2"} foreColor={"#111111"} />
                <div className='offer-sr-bg-pear'>
                    <img src={mobilePear} alt='bg-pear' />
                </div>
            </section>
            <section className="first-section offer-page">
                <div className='left-section'>
                    <h1 className='heading'>Get a free no obligation “stop repossession” <span className='yellow-text'>offer</span>.</h1>
                    <h5 className='sub-heading'>At absolutely no cost to you to request an offer to stop repossession on your home at The Property Vendee (TPV).</h5>
                    <RepossessionCard image={cardImage1} text={"Immediate help that fits your needs"} backColor={"var(--white-background)"} foreColor={"var(--navbar-color)"} />
                    <RepossessionCard image={cardImage2} text={"Save with stress with no additional fees"} backColor={"var(--navbar-color)"} foreColor={"var(--white-background)"} extra={ellipse} />
                    <RepossessionCard image={cardImage3} text={"No more feeling alone"} backColor={"#FFECB2"} foreColor={"#111111"} />
                </div>
                <div className='right-section'>
                    <img src={rightSectionImage} alt='woman stressed in image' />
                </div>
                <div className='offer-sr-bg-pear'>
                    <img src={backgroundPearShape} alt='bg-pear' />
                </div>
            </section>
            <section id='sr-cta' className='repossession-cta offer-page'>
                <div className='offer-card'>
                    <div className='drop-down' ref={iAmRef}>
                        <CustomSelect
                            placeholder={"I am"}
                            options={options.iAmData}
                            handleChange={(selectedValue) => formik.setFieldValue('clientType', selectedValue.value)}
                            classes={"i-am-dropdown"}
                            value={formik.values.clientType}
                            override
                        />
                        {warning && <div className='required-warning'>Please select the type of client you are</div>}
                    </div>
                    <div className='cta'>
                        <div className='sr-cta-heading'>
                            <span>Stopping Repossession made easy...</span>
                        </div>
                        <div className='cta-black-bg'>
                            <Cta
                                handleDataCollection={handleDataCollection}
                                formIndex={formIndex}
                                reverseStep={reverseStep} formik={formik}
                                formRef={form}
                                uploadImageRef={uploadImageRef} />
                        </div>
                    </div>
                </div>
                <div className='background-tiny-house'>

                </div>
            </section>

        </>
    );
}

export default StopRepossession;
