import { useState, useEffect, useRef } from "react";
import InputField from "./InputField"
import { Upload } from "@progress/kendo-react-upload";
import standingImg from "../../assets/images/standing.png"
import options from "../../config/SelectData"
import CustomSelect from "./CustomSelect";
import emailjs from '@emailjs/browser';
import submitArrow from '../../assets/images/submit-arrow.svg'

const Cta = ({ handleDataCollection, formIndex, reverseStep, formik, uploadImageRef }) => {
    const form = useRef();
    const saveUrl = "https://demos.telerik.com/kendo-ui/service-v4/upload/save";
    const removeUrl = "https://demos.telerik.com/kendo-ui/service-v4/upload/remove";
    const [index, setIndex] = useState(0)
    const [disableTag, setDisableTag] = useState(false);
    const [imgUpload, setImgUpload] = useState('hidden');

    useEffect(() => {
        let datePicker;
        let clientName;
        if (formIndex === 2) {
            clientName = document.querySelector("#clientName")
            clientName.addEventListener('keydown', preventNumberInput);
        }

        function preventNumberInput(e) {
            var keycode = (e.keycode ? e.keycode : e.which);
            if (keycode > 47 && keycode < 58 || keycode > 95 && keycode < 107) {
                e.preventDefault();
            }
        }

        const changeToDate = () => {
            datePicker.type = 'date'
        }
        if (formIndex === 1) {

            datePicker = document.getElementById("purchaseDate")
            datePicker.max = new Date().toLocaleDateString('fr-ca')
            datePicker.addEventListener('focus', changeToDate)
        }

        return () => {
            if (datePicker) {
                datePicker.removeEventListener('focus', changeToDate)
            }
            if (clientName) {
                clientName.removeEventListener('keydown', preventNumberInput)
            }
        }
    }, [formIndex])

    const handleSubmit = (event) => {
        // What to do with the form data [onSubmit] goes here
        event.preventDefault()
        setIndex(index + 1)
        // handleDataCollection()

        if (handleDataCollection()) {
            emailjs.sendForm('service_lrekcyz', 'template_r2bnnhn', form.current, 'cm_1OMdDgstBwtAkp')
                .then((result) => {
                    console.log('sent');

                }, (error) => {
                    console.log(error);
                    console.log('not sent');
                });
        }
    }
    const handleUpload = (fileObj) => {
        formik.setFieldValue('file', fileObj)
    }
    const checkPropertyType = (value) => {
        if (value == 'Land' || value == 'Commercial' || value == 'Commercial-Resi' || value == 'Investment Property') {
            setDisableTag(true)
            formik.setFieldValue("numOfBedrooms", "")
            console.log(value);
        } else {
            setDisableTag(false)
        }
    }
    const viewUploadImg = (e) => {
        e.target.classList.add('hidden')
        setImgUpload('visible')
    }
    const propertyTypeHandler = (selectedValue) => {
        formik.setFieldValue('propertyType', selectedValue.value)
        checkPropertyType(selectedValue.value)
    }

    const ctaData = [
        {
            0: [{
                id: 1, tag:
                    <CustomSelect
                        name="country"
                        placeholder={"Location"}
                        options={options.countries}
                        classes="custom-select"
                        handleChange={(selectedValue) => formik.setFieldValue('country', selectedValue.value)}
                        value={formik.values.country}
                    />

            },
            {
                id: 2,
                tag: <input name="postcode" type="text" id="postcode" placeholder="Address/Postcode" onChange={formik.handleChange} value={formik.values.postcode} required />
            },
            {
                id: 3,
                tag:
                    <CustomSelect
                        placeholder={"Property type"}
                        options={options.propertyTypes}
                        handleChange={(selectedValue) => { propertyTypeHandler(selectedValue) }}
                        value={formik.values.propertyType}
                        classes={"custom-select"}
                    />


            },
            {
                id: 4,
                tag:
                    <CustomSelect
                        disableTag={disableTag}
                        placeholder={"Property size"}
                        options={options.bedrooms}
                        handleChange={(selectedValue) => formik.setFieldValue('numOfBedrooms', selectedValue.value)}
                        classes={"custom-select"}
                        value={formik.values.numOfBedrooms}
                    />


            }]
        },
        {
            1: [
                {
                    id: 5, tag:
                        <CustomSelect
                            placeholder={"Property condition"}
                            options={options.propertyConditions}
                            classes={"custom-select"}
                            handleChange={(selectedValue) => formik.setFieldValue('propertyState', selectedValue.value)}
                            value={formik.values.propertyState}
                        />

                },
                {
                    id: 6, tag:
                        <CustomSelect
                            placeholder={"Last renovation done"}
                            options={options.renovations}
                            handleChange={(selectedValue) => formik.setFieldValue('lastRenovation', selectedValue.value)}
                            classes={"custom-select"}
                            value={formik.values.lastRenovation}
                        />
                },
                {
                    id: 7, tag:
                        <input value={formik.values.purchaseDate} className="purchase-date input-field data-field required" placeholder="Purchased date" name="purchaseDate" id="purchaseDate" type="text" onChange={formik.handleChange} required />
                },
                {
                    id: 8, tag:
                        <CustomSelect
                            placeholder={"Outstanding loan amount"}
                            value={formik.values.standingLoan}
                            options={options.loans}
                            classes={"custom-select"}
                            handleChange={(selectedValue) => formik.setFieldValue('standingLoan', selectedValue.value)}
                        />
                },
            ]
        },
        {
            2: [
                { id: 9, tag: <input value={formik.values.purchasedPrice} name="purchasedPrice" required className="purchase-prices input-field required" id="purchasedPrice" type="number" onChange={formik.handleChange} placeholder="Enter Market Price" /> },
                {
                    id: 10, tag:
                        <div className="upload-wrapper">
                            <Upload
                                name="file"
                                id='image-upload'
                                ref={uploadImageRef}
                                onStatusChange={handleUpload}
                                onRemove={handleUpload}
                                className="upload"
                                defaultFiles={[]}
                                withCredentials={false}
                                saveUrl={saveUrl}
                                removeUrl={removeUrl}
                                restrictions={{
                                    minFileSize: 1000,
                                    maxFileSize: 10000000,
                                    allowedExtensions: [".jpg", ".png", ".pdf"],
                                }}
                            />
                        </div>,
                    // name: 'file'
                },
                { id: 11, tag: <input required type="text" name="clientName" id="clientName" placeholder="Enter Name" onChange={formik.handleChange} value={formik.values.clientName} /> },
                { id: 12, tag: <input name="email" className="input-field required" id="email" type="email" placeholder="Input Email *" onChange={formik.handleChange} value={formik.values.email} required /> },
            ]
        }
    ]
    return (
        <div className="inner-cta">
            <SubmitForms formref={form} formikData={formik} />
            {
                // formIndex = 0 means beginning of the form
                // form ends at formIndex = 2

                formIndex !== 3 ? // if formIndex === 3, it means the submit button was clicked, show the submission message
                    (
                        <form className={`white-bg cta-form step step${formIndex} active`} onSubmit={handleSubmit}>
                            {
                                Object.values(ctaData[formIndex][`${formIndex}`]).map((inputRow, i) => {
                                    return (
                                        <InputField key={inputRow.id} number={inputRow.id} inputTag={inputRow.tag} />
                                    )
                                })
                            }

                            {
                                formIndex !== 2 ? // Show the "Next" button as long as it isn't the last form
                                    (<button type="submit" className=" cta-btn next"> Next <i className="fa fa-arrow-right" aria-hidden="true"></i></button>)
                                    :
                                    (<button type="submit" className=" cta-btn next" name="make_me_an_offer" id="submit-button">
                                        Submit
                                        <img src={submitArrow} alt="submit arrow" />
                                    </button>)
                            }
                            {/* Show the back button only if you aren't at the beginning of the form */}
                            {formIndex !== 0 && <button type="button" onClick={reverseStep} className=" cta-btn back"><i className="fa fa-arrow-left" aria-hidden="true"></i> Back</button>}
                        </form>
                    )
                    :
                    (
                        <div className='submission-complete' id="sr-submission-complete">
                            <div className='submission-message'>
                                <blockquote>
                                    Thank you for submitting your details.
                                    You will hear from us with a free no obligation
                                    offer within 48 hours.
                                </blockquote>
                            </div>
                        </div>
                    )
            }
            {formIndex === 3 && <img className="sr-standing-img" src={standingImg} alt="standing" />}
        </div>
    )
}


function SubmitForms({ formref, formikData }) {
    return (
        <form ref={formref} action="" style={{ display: "none" }}>
            <input name="clientType" type="hidden" value={formikData.values.clientType} />
            <input name="country" type="hidden" value={formikData.values.country} />
            <input name="postcode" type="hidden" value={formikData.values.postcode} />
            <input name="propertyType" type="hidden" value={formikData.values.propertyType} />
            <input name="numOfBedrooms" type="hidden" value={formikData.values.numOfBedrooms} />
            <input name="propertyState" type="hidden" value={formikData.values.propertyState} />
            <input name="lastRenovation" type="hidden" value={formikData.values.lastRenovation} />
            <input name="purchaseDate" type="hidden" value={formikData.values.purchaseDate} />
            <input name="standingLoan" type="hidden" value={formikData.values.standingLoan} />
            <input name="purchasedPrice" type="hidden" value={formikData.values.purchasedPrice} />
            <input name="file" type="hidden" value={''} />
            <input name="clientName" type="hidden" value={formikData.values.clientName} />
            <input name="email" type="hidden" value={formikData.values.email} />
        </form>
    )
}

export default Cta
