import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Cookies.css";

function Cookies() {
  const [activeNumber, setActiveNumber] = useState(3);
  const [expanded, setExpanded] = useState([]);

  const handleToggle = (index) => {
    setExpanded((prevExpanded) => {
      const newExpanded = [...prevExpanded];
      newExpanded[index] = !newExpanded[index];
      return newExpanded;
    });
    setActiveNumber(index );
  };

  const termData = [
    {
      text: "Xcelsz Limited",
      content:
        "Cookies are used on our website to recognize you from other users. This enables us to offer you a positive browsing experience while also allowing us to improve our website. By continuing to use the website, you consent to the use of cookies.",
    },
    {
      text: "What is a cookie?",
      content: (
        <div className="service-description">
          A cookie is a small piece of data transmitted by a web server or
          mobile application to a user's browser and subsequently saved on the
          user's computer hard drive or mobile device. Cookies are an effective
          way for websites to remember the user's activity on the website, which
          aids in optimizing your experience on the platform. <br />
          This anonymous information is stored separately and is not connected
          to the user information you provide to us. Accepting cookies is
          entirely optional; however, certain parts of the website, such as
          content and forms, may be inaccessible if cookies are not accepted.
        </div>
      ),
    },

    {
      text: "We use the following cookies",
      content: (
        <div className="eligibility-description">
          Strictly necessary or essential cookies These are cookies that are
          required for the operation of our website. They include, for example,
          cookies that enable you to use our online forms and make submissions.
        </div>
      ),
    },

    {
      text: "Analytical / Performance cookies",
      content: (
        <div className="use-of-website-description">
          They enable us to detect and count the number of visits as well as
          monitor how people navigate our website while using it.
          <br /> This allows us to enhance the functionality of our website,
          such as ensuring that people can simply locate what they are searching
          for.
        </div>
      ),
    },

    {
      text: "Functional Cookies",
      content: (
        <div className="user-content-description">
          We give you the opportunity to define your preferences for how this
          website functions when you use it so that we can provide you with an
          excellent user experience. We must install cookies to remember your
          preferences so that this information (such as your language or region
          preference) may be retrieved anytime you interact with a page that is
          affected by your preferences.
        </div>
      ),
    },

    {
      text: "Targeting Cookies",
      content: (
        <div className="intellectual-property-description">
          These cookies track your visit to our website, including the pages you
          see and the links you click. This information will be used to make our
          website and the advertisements shown on it more relevant to your
          interests. This information may also be shared with third parties for
          this reason. Please keep in mind that third parties (such as
          advertising networks and providers of external services such as online
          traffic i analysis services) may also employ cookies over which we
          have no control. These cookies are most likely analytical,
          performance, or targeting cookies.
        </div>
      ),
    },

    {
      text: "How to control cookies",
      content: (
        <div className="disclaimers-description">
          Cookies can be blocked by enabling the browser setting that allows you
          to deny the setting of all or certain cookies. You may also delete
          cookies from your browser's settings. You may be unable to access all
          or portions of our website if you use your browser settings to disable
          all cookies (including necessary cookies). Users may also manage
          cookies and opt out of targeted advertising using thirdparty tools.
        </div>
      ),
    },

    {
      text: "How long are cookies stored on your device",
      content: (
        <div className="limitation-description">
          Cookies, depending on the type, are stored on your device for a
          specific period of time. Session cookies are deleted when you close
          your browser, but persistent cookies are stored on your device for an
          extended period of time. Each cookie's expiry date is included in the
          cookie's file, which you can view in your browser settings.
        </div>
      ),
    },

    {
      text: "Technical Information",
      content: (
        <div className="indemnification-description">
          Technical information includes information about your device and
          network, such as your device type, operating system, IP address, and
          other technical information. This information helps us diagnose
          problems and provide better support.
        </div>
      ),
    },

    {
      text: "Changes to your cookie policy",
      content: (
        <div className="terms-conditions-description">
          Any updates to our cookie policy that we make in the future will be
          posted on this page.
        </div>
      ),
    },

    {
      text: "Contact",
      content: (
        <div className="governing-law-description">
          If we merge with another company, or if we are acquired by another
          company, your information i may be transferred to the new owner as a
          part of the transaction.
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="backgroundo"></div>
      <section className="cookie-top">
        <div className="cookie-top-left">
          <h1 className="cookie-heading">Cookie Policy</h1>
          <h1 className="cookie_text">
            We use cookies to ensure you
            <br /> get the best experience on our
            <br /> website
          </h1>
        </div>
      </section>

      <section className="types">
        <div className="types_border">
          <h2>Select Our Terms Type</h2>

          <div className="numbers">
            <Link
              to="./terms"
              className={`num ${activeNumber === 1 ? "active" : ""}`}
            >
              1
            </Link>
            <Link
              to="./privacyPolicy"
              className={`num ${activeNumber === 2 ? "active" : ""}`}
            >
              2
            </Link>
            <Link
              to="./cookies"
              className={`num ${activeNumber === 3 ? "active" : ""}`}
            >
              3
            </Link>
          </div>
        </div>
      </section>

      {/* <p className="below_text">
       Who We Are and Scope of this Privacy Policy
      </p> */}

      {termData.map((term, index) => (
        <React.Fragment key={index}>
          <div className={`term_border ${expanded[index] ? "expanded" : ""}`}>
            <div className="expand-btn" onClick={() => handleToggle(index)}>
              {expanded[index] ? (
                <span className="minus">-</span>
              ) : (
                <span className="plus">+</span>
              )}
            </div>
            <span className="text">{term.text}</span>
            {expanded[index] && (
              <div
                className={`dropdown_border ${
                  index === 1 ? "service-description-dropdown" : ""
                } ${index === 2 ? "eligibility-description-dropdown" : ""} ${
                  index === 3 ? "use-of-website-description-dropdown" : ""
                } ${index === 4 ? "user-content-description-dropdown" : ""} ${
                  index === 5
                    ? "intellectual-property-description-dropdown"
                    : ""
                } ${index === 6 ? "disclaimers-description-dropdown" : ""} ${
                  index === 7 ? "limitation-description-dropdown" : ""
                } ${
                  index === 8 ? "indemnification-description-dropdown" : ""
                } ${
                  index === 9 ? "terms-conditions-description-dropdown" : ""
                } ${index === 10 ? "governing-law-description-dropdown" : ""} `}
              >
                <span className="the_text">{term.content}</span>
              </div>
            )}
          </div>
          <div className="line"></div>
        </React.Fragment>
      ))}
    </>
  );
}

export default Cookies;
