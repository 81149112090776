import { useState, useRef, useEffect } from "react";
import InputField from "../stopRepossession/InputField"
import standingImg from "../../assets/images/standing.png"
import options from "../../config/SelectData"
import CustomSelect from '../stopRepossession/CustomSelect'
import submitArrow from '../../assets/images/submit-arrow.svg'
import { Upload } from "@progress/kendo-react-upload";
import emailjs from '@emailjs/browser';
import rightArrow from "../../assets/images/rightArrow.svg"
import leftArrow from "../../assets/images/leftArrow.svg"


const OfferCta = ({ handleDataCollection, formIndex, reverseStep, formik, uploadImageRef, uploadDocsRef }) => {
    const form = useRef();
    const [disableTag, setDisableTag] = useState(false);
    const [index, setIndex] = useState(0)

    useEffect(() => {
        if (formIndex === 3) {
            const imageUpload = document.getElementById('image-upload')
            const docsUpload = document.getElementById('docs-upload')
            imageUpload.innerText = "Upload property image"
            docsUpload.innerText = "Upload property documents"
        }
        let datePicker;
        const changeToDate = () => {
            datePicker.type = 'date'
        }

        if (formIndex === 1) {
            datePicker = document.getElementById("purchaseDate")
            datePicker.max = new Date().toLocaleDateString('fr-ca')
            datePicker.addEventListener('focus', changeToDate)
        } else if (formIndex === 3) {
            datePicker = document.getElementById("viewDate")
            datePicker.max = new Date().toLocaleDateString('fr-ca')
            datePicker.addEventListener('focus', changeToDate)
        }

        return () => {
            if (datePicker) {
                datePicker.removeEventListener('focus', changeToDate)
            }
        }
    }, [formIndex])

    const saveUrl = "https://demos.telerik.com/kendo-ui/service-v4/upload/save";
    const removeUrl = "https://demos.telerik.com/kendo-ui/service-v4/upload/remove";
    const checkPropertyType = (value) => {
        if (value == 'Land' || value == 'Commercial' || value == 'Commercial-Resi' || value == 'Investment Property') {
            setDisableTag(true)
            formik.setFieldValue("numOfBedrooms", "")
        } else {
            setDisableTag(false)
        }
    }

    const propertyTypeHandler = (selectedValue) => {
        formik.setFieldValue('propertyType', selectedValue.value)
        checkPropertyType(selectedValue.value)
    }
    const handleUpload = (fileObj) => {
        formik.setFieldValue('file', fileObj)
    }
    const handleSubmit = (event) => {
        // What to do with the form data [onSubmit] goes here
        event.preventDefault()
        setIndex(index + 1)
        // handleDataCollection()

        if (handleDataCollection()) {
            emailjs.sendForm('service_lrekcyz', 'template_r2bnnhn', form.current, 'cm_1OMdDgstBwtAkp')
                .then((result) => {
                    console.log('sent');

                }, (error) => {
                    console.log(error);
                    console.log('not sent');
                });
        }
    }
    const ctaData = [
        {
            0: [
                {
                    id: 1,
                    tag: <CustomSelect
                        placeholder={"I am"}
                        options={options.iAmData}
                        handleChange={(selectedValue) => formik.setFieldValue('clientType', selectedValue.value)}
                        classes={"custom-select"}
                        value={formik.values.clientType}
                        backgroundColor="--white-background"
                        override
                        borders="10px"
                    />
                },
                {
                    id: 2, tag:
                        <CustomSelect
                            name="country"
                            placeholder={"Country"}
                            options={options.countries}
                            classes="custom-select"
                            handleChange={(selectedValue) => formik.setFieldValue('country', selectedValue.value)}
                            value={formik.values.country}
                            backgroundColor="--white-background"
                            override
                            borders="10px"
                        />
                },
                {
                    id: 3,
                    tag:
                        <CustomSelect
                            placeholder={"Property type"}
                            options={options.propertyTypes}
                            handleChange={(selectedValue) => { propertyTypeHandler(selectedValue) }}
                            value={formik.values.propertyType}
                            classes={"custom-select"}
                            backgroundColor="--white-background"
                            override
                            borders="10px"
                        />
                },
                {
                    id: 4,
                    tag:
                        <CustomSelect
                            disableTag={disableTag}
                            placeholder={"No. of bedrooms"}
                            options={options.bedrooms}
                            handleChange={(selectedValue) => formik.setFieldValue('numOfBedrooms', selectedValue.value)}
                            classes={"custom-select"}
                            value={formik.values.numOfBedrooms}
                            backgroundColor="--white-background"
                            override
                            borders="10px"
                        />


                },
                {
                    id: 5, tag:
                        <CustomSelect
                            placeholder={"Property Condition"}
                            options={options.propertyConditions}
                            classes={"custom-select"}
                            handleChange={(selectedValue) => formik.setFieldValue('propertyState', selectedValue.value)}
                            value={formik.values.propertyState}
                            backgroundColor="--white-background"
                            override
                            borders="10px"
                        />

                },
                {
                    id: 6, tag:
                        <CustomSelect
                            placeholder={"Last renovation done"}
                            options={options.renovations}
                            handleChange={(selectedValue) => formik.setFieldValue('lastRenovation', selectedValue.value)}
                            classes={"custom-select"}
                            value={formik.values.lastRenovation}
                            backgroundColor="--white-background"
                            override
                            borders="10px"
                        />
                },

            ]
        },
        {
            1: [
                {
                    id: 7, tag:
                        <input value={formik.values.purchaseDate} className="purchase-date input-field data-field required" placeholder="Purchased date" name="purchaseDate" id="purchaseDate" type="text" onChange={formik.handleChange} required />
                },
                { id: 8, tag: <input value={formik.values.purchasedPrice} name="purchasedPrice" required className="purchase-prices input-field required" id="purchasedPrice" type="number" onChange={formik.handleChange} placeholder="Enter Market Price" /> },
                {
                    id: 9, tag:
                        <CustomSelect
                            placeholder={"Outstanding loan"}
                            value={formik.values.standingLoan}
                            options={options.loans}
                            classes={"custom-select"}
                            handleChange={(selectedValue) => formik.setFieldValue('standingLoan', selectedValue.value)}
                            backgroundColor="--white-background"
                            override
                            borders="10px"
                        />
                },
                { id: 10, tag: <input value={formik.values.buildYear} name="buildYear" required className="input-field required" id="buildYear" type="number" onChange={formik.handleChange} placeholder="Input build year" /> },
                {
                    id: 11, tag:
                        <CustomSelect
                            placeholder={"Already on the market"}
                            value={formik.values.onTheMarket}
                            options={options.market}
                            classes={"custom-select"}
                            handleChange={(selectedValue) => formik.setFieldValue('onTheMarket', selectedValue.value)}
                            backgroundColor="--white-background"
                            override
                            borders="10px"
                        />
                },
                { id: 12, tag: <input value={formik.values.sellingSoon} name="sellingSoon" required className="input-field required" id="sellingSoon" type="number" onChange={formik.handleChange} placeholder="How soon you're selling" /> },
            ]
        },
        {
            2: [
                {
                    id: 13, tag:
                        <CustomSelect
                            placeholder={"Reason for selling fast"}
                            value={formik.values.sellingFast}
                            options={options.sellingFast}
                            classes={"custom-select"}
                            handleChange={(selectedValue) => formik.setFieldValue('sellingFast', selectedValue.value)}
                            backgroundColor="--white-background"
                            override
                            borders="10px"
                        />
                },
                { id: 14, tag: <input value={formik.values.address} name="address" required className="input-field required" id="address" type="text" onChange={formik.handleChange} placeholder="Address / Digital Address" /> },
                { id: 15, tag: <input value={formik.values.streetName} name="streetName" required className="input-field required" id="streetName" type="text" onChange={formik.handleChange} placeholder="Street Name" /> },
                { id: 16, tag: <input value={formik.values.region} name="region" required className="input-field required" id="region" type="text" onChange={formik.handleChange} placeholder="Region" /> },
                { id: 17, tag: <input value={formik.values.district} name="district" required className="input-field required" id="district" type="text" onChange={formik.handleChange} placeholder="District" /> },
                { id: 18, tag: <input value={formik.values.area} name="area" required className="input-field required" id="area" type="text" onChange={formik.handleChange} placeholder="Area" /> },
            ]
        },
        {
            3: [
                { id: 19, tag: <input value={formik.values.name} name="name" required className="input-field required" id="name" type="text" onChange={formik.handleChange} placeholder="Input name" /> },
                { id: 20, tag: <input value={formik.values.email} name="email" required className="input-field required" id="email" type="email" onChange={formik.handleChange} placeholder="Input Email" /> },
                {
                    id: 21, tag:
                        <div className="upload-wrapper">
                            <Upload
                                ref={uploadImageRef}
                                name="file"
                                id='image-upload'
                                onStatusChange={handleUpload}
                                onRemove={handleUpload}
                                className="upload"
                                defaultFiles={[]}
                                withCredentials={false}
                                saveUrl={saveUrl}
                                removeUrl={removeUrl}
                                restrictions={{
                                    minFileSize: 1000,
                                    maxFileSize: 10000000,
                                    allowedExtensions: [".jpg", ".png", ".jpeg"],
                                }}
                            />
                        </div>,
                },
                {
                    id: 22, tag:
                        <div className="upload-wrapper">
                            <Upload
                                name="file"
                                id='docs-upload'
                                ref={uploadDocsRef}
                                onStatusChange={handleUpload}
                                onRemove={handleUpload}
                                className="upload"
                                defaultFiles={[]}
                                withCredentials={false}
                                saveUrl={saveUrl}
                                removeUrl={removeUrl}
                                restrictions={{
                                    minFileSize: 1000,
                                    maxFileSize: 10000000,
                                    allowedExtensions: [".jpg", ".png", ".pdf"],
                                }}
                            />
                        </div>,
                },
                {
                    id: 23, tag:
                        <input value={formik.values.viewDate} className="input-field data-field required" placeholder="Select date for virtual viewing" name="viewDate" id="viewDate" type="text" onChange={formik.handleChange} required />
                },
                {
                    id: 24, tag:
                        <CustomSelect
                            placeholder={"How did you hear about us?"}
                            value={formik.values.findOutAboutUs}
                            options={options.findOutAboutUs}
                            classes={"custom-select"}
                            handleChange={(selectedValue) => formik.setFieldValue('findOutAboutUs', selectedValue.value)}
                            backgroundColor="--white-background"
                            override
                            borders="10px"
                        />
                },
            ]
        }

    ]
    return (
        <div id="offer-page-cta" className="inner-cta">
            {
                // formIndex = 0 means beginning of the form
                // form ends at formIndex = 2

                formIndex !== 4 ? // if formIndex === 3, it means the submit button was clicked, show the submission message
                    (
                        <form className={`cta-form step step${formIndex} active`} onSubmit={handleSubmit}>
                            {
                                Object.values(ctaData[formIndex][`${formIndex}`]).map((inputRow, i) => {
                                    return (
                                        <InputField key={inputRow.id} inputClass="offer-page-fields" number={inputRow.id} inputTag={inputRow.tag} />
                                    )
                                })
                            }

                            {
                                formIndex !== 3 ? // Show the "Next" button as long as it isn't the last form
                                    (<button type="submit" className=" cta-btn next"> Next <img src={rightArrow} alt="next arrow" /></button>)
                                    :
                                    (<button type="submit" className=" cta-btn next" name="make_me_an_offer" id="submit-button">
                                        Submit
                                        <img src={submitArrow} alt="submit arrow" />
                                    </button>)
                            }
                            {/* Show the back button only if you aren't at the beginning of the form */}
                            {formIndex !== 0 && <button type="button" onClick={reverseStep} className=" cta-btn back"><img src={leftArrow} alt="back arrow" /> Back</button>}
                        </form>
                    )
                    :
                    (
                        <div className='submission-complete'>
                            <div className='submission-message'>
                                <blockquote id="offer-submission-message">
                                    Thank you for submitting your request. You will hear from us within 48 hours with a free no obligation offer.
                                    <span className="bold"><br></br>TPV<br></br></span>
                                    <span className="yellow-text">Making Problem Properties Work For You.</span>
                                </blockquote>
                            </div>
                        </div>
                    )
            }
            {formIndex === 4 && <img className="standing-img" src={standingImg} alt="standing" />}
        </div>
    )
}
export default OfferCta
