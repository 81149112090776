import "./SPDevelopers.css"
import curve from "../../assets/images/curve.png";
import data from "../../assets/images/data.png";
import buildings from "../../assets/images/buildings.png";
import virtual from "../../assets/images/virtual.png";
import nego from "../../assets/images/nego.png" ;
import frame from "../../assets/images/Frame.png";
import Money from "../../assets/images/Money.png";
import loaner from "../../assets/images/loaner.png";
import arrow from "../../assets/images/bi_arrow-right-circle-fill.svg";
import Ellipsey from "../../assets/images/Ellipsey.png";
import Ellipseb from "../../assets/images/Ellipseb.png";
import curny from "../../assets/images/curny.jpg";
import suffix from "../../assets/images/suffix.png"



function SPDevelopers(){


    return(

        <>
            <div className="firstwrap">
                <img src={curve} alt="" /> 
                <h1 className="keep"> Keeping Your Investor Partner Happy Made <br/><span className="easy"> Easy</span>
                    <img src={Money} alt="" /> 
                </h1>

                <div className="firstBox">
                    <img src={data} alt="" /><p className="para1">Quick cash flow and no commission fees</p>
                </div>

                <div className="secBox">

                    <img src={buildings} alt="" /><p className="para2">No more keeping your partner waiting</p>

                </div>    
                    
                <div className="thirdBox">
                    <img src={virtual} alt="" /><p className="para3">Fixed or payment guaranteed</p> 

                </div>


            </div>
            

            <div className="secondwrap"> 
               
                <p className="secondwrapPara1">Whatever fits your situation
                    <span className="dots3">...</span><span className="dots2">..</span>
                </p>

                <div className="image1">
                    <span class="dot1"></span>

                    <a href="#"><img src={arrow} style={{rotate: "180deg"}} alt="arrow" /></a>
               
                </div>

                <div className="image2">
                    <span class="dot2"></span>
                    <a href="#"><img src={arrow} alt="arrow" /></a>
                </div>

                <div className="subsecondwrap1">
                   
                    <p className="secondwrapPara2"> Why TPV is the right Choice? </p> 


                    <p className="secondwrapPara3"> We take on any property including under<br/>development properties</p>

                   

                  
                   <div className="subThreeBoxes"> 
                        <div className="boxA">

                            <p className="subFirstBox"><span className="image3"><img src={frame} alt=""style={{width:"40px"}} /></span> </p> 
                            <p className="subFirstBoxs">We have an outstanding customer conversion rate</p>
                     
                        </div> 

                        <div className="image">
                            <img src={nego} alt="" style={{width:"70px"}} /> 
                        </div>

                        <div className="boxB">

                            <p className="subSecondBox"><span className="image4"><img src={frame} alt="" style={{width:"40px"}}/></span> </p>
                            <p className="subSecondBoxs">property return in the same condition or even better </p> 
                    
                        </div> 

                        <div className="boxC">
                            <p className="subThirdBox"><span className="image5 "><img src={frame} alt="" style={{width:"40px"}} /></span> </p> 
                   
                            <p className="subThirdBoxs">we offer a chain free house sale</p> 

                        </div> 

                    </div>
                </div>

                <div className="circle">
                    
                    <span className="circle1"><img src={Ellipseb} alt="" /></span>
                    <span className="circle2"><img src={Ellipsey} alt="" /></span>
                    <span className="circle3"><img src={Ellipseb} alt="" /></span>
                </div>
                
            </div>

            <div className="thirdwrap"> 
               
                <p className="about"> About TPV</p>

                <div div className="prof">
                    <div className="loaner">
                        <img src={loaner} alt=""/>
                    </div> 
            
                 {/* <div className="curny"> */}
                    <div className="curny">
                        <p> 
                            We are a professional, independent 
                            Homebuyers and Relocation Specialist,
                            and because we market direct to you
                            the Home Owner. we can present you 
                            with great offers you can't miss, with
                            ZERO fees to you 
                        </p>
                        <a href="/about"><button>READ MORE</button></a>
                    </div>
                
                </div>
           

                <div className="suffix">
                    <div className="a">
                        <img src={suffix} alt=""/><p className="b"> Choose completion date suitable </p>
                    </div>
               
                    <div className="a">
                        <img src={suffix} alt=""/> <p className="b"> Get an offer within 48 hours</p>
                    </div>
          
                </div>
            </div>
        </>
    )
}
export default SPDevelopers;