import './HowItWork.css'
import { Link } from 'react-router-dom';

import owner from '../../assets/images/owner.webp'
import broker from '../../assets/images/broker.webp'
import property_owner from '../../assets/images/property_owner.webp'
import lenders from '../../assets/images/lenders.webp'
import landlord from '../../assets/images/landlord.webp'
import property_developers from '../../assets/images/property_developers.webp'
import home_seller from '../../assets/images/home_seller.webp'
import price_1 from '../../assets/images/price(1).webp'
import double_arrow from '../../assets/images/double-arrow.webp'
import house_repair from '../../assets/images/house-repair.webp'
import targeting from '../../assets/images/targeting.webp'
import confused from '../../assets/images/confused.webp'


import ownerpng from '../../assets/images/owner.png'
import lenderspng from '../../assets/images/lenders.png'
import property_ownerpng from '../../assets/images/property_owner.png'
import home_sellerpng from '../../assets/images/home_seller.png'
import brokerpng from '../../assets/images/broker.png'
import property_developerspng from '../../assets/images/property_developers.png'
import price_1png from '../../assets/images/price(1).png'
import house_repairpng from '../../assets/images/house-repair.png'
import targetingpng from '../../assets/images/targeting.png'
import confusedpng from '../../assets/images/confused.png'


function howItWork() {
    return (
        <>
            <div className="how_it_works">
                <h1>Our Fast Problem Property</h1>
                <h1>Solution is for...<span className="yellow_dot">.</span></h1>
                <div className="container-fluid">
                    <div className="first_column">
                        <a href="">
                            <div className="process">
                                <picture>
                                    <source type="image/webp" srcset={owner} />
                                    <source type="image/png" srcset={ownerpng} />
                                    <img src={owner} alt="home seller" />
                                </picture>
                                <p>Home Sellers</p>
                            </div>
                        </a>

                        <a href="">
                            <div className="process">
                                <picture>
                                    <source type="image/webp" srcset={home_seller} />
                                    <source type="image/png" srcset={home_sellerpng} />
                                    <img src={home_seller} alt="financial difficulty owner" />
                                </picture>
                                <p>Financial
                                    <span style={{ display: "block" }}>Difficulty Owners
                                    </span>
                                </p>
                            </div>
                        </a>

                    </div>

                    <div className="second_column">
                        <a href="">
                            <div className="process">
                                <picture>
                                    <source type="image/webp" srcset={broker} />
                                    <source type="image/png" srcset={brokerpng} />
                                    <img src={broker} alt="property agent" />
                                </picture>
                                <p>Property Agents</p>
                            </div>
                        </a>

                        <a href="">
                            <div className="process">
                                <picture>
                                    <source type="image/webp" srcset={property_owner} />
                                    <source type="image/png" srcset={property_ownerpng} />
                                    <img src={property_ownerpng} alt="property owner" />
                                </picture>
                                <p>Property Owners</p>
                            </div>
                        </a>


                        <a href="">
                            <div className="process lender">
                                <picture>
                                    <source type="image/webp" srcset={lenders} />
                                    <source type="image/png" srcset={lenderspng} />
                                    <img src={property_ownerpng} alt="lender" />
                                </picture>
                                <p>Lenders</p>
                            </div>
                        </a>

                    </div>

                    <div className="third_column">
                        <a href="">
                            <div className="process">
                                <picture>
                                    <source type="image/webp" srcset={property_developers} />
                                    <source type="image/png" srcset={property_developerspng} />
                                    <img src={property_developers} alt="property developer" />
                                </picture>
                                <p>Property Developers</p>
                            </div>
                        </a>

                        <a href="">
                            <div className="process">
                                <picture>
                                    <source type="image/webp" srcset={landlord} />
                                    <source type=" image/png" srcset="./assets/images/landlord.png" />
                                    <img src={landlord} alt="landlord" />
                                </picture>
                                <p>Landlords</p>
                            </div>
                        </a>

                    </div>
                </div>

                <div className="curved-dots-path"></div>
                <div className="do-to-pa"></div>
                <div className="do-to-l"></div>
            </div>

            <section className="benefits">
                <h1>The Benefits For <span className="yellow_dot">...</span>..</h1>
                <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
                    <div className="carousel-indicators">
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active"
                            aria-current="true" aria-label="Slide 1"></button>
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1"
                            aria-label="Slide 2"></button>
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2"
                            aria-label="Slide 3"></button>
                    </div>
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <div className="main-container">
                                <div className="compare-head">Property Owners</div>
                                <div className="cards-slide">

                                    <picture>
                                        <source type="image/webp" srcset={confused} />
                                        <source type="image/png" srcset={confusedpng} />
                                        <img src={confused} alt="confused person" />
                                    </picture>

                                    <div className="details">
                                        <div><img src={double_arrow} alt="arrow" />
                                            <p>Cash in pocket in a short time</p>
                                        </div>
                                        <div className="middle"><img src={double_arrow} alt="arrow" />
                                            <p>Less Hassle, no mid-night calls</p>
                                        </div>
                                        <div><img src={double_arrow} alt="arrow" />
                                            <p>Save Time & Money</p>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="carousel-item">
                            <div className="main-container">
                                <div className="compare-head head-ash">Property Agents</div>
                                <div className="cards-slide">
                                    <picture>
                                        <source type="image/webp" srcset={targeting} />
                                        <source type="image/png" srcset={targetingpng} />
                                        <img src={targeting} alt="" />
                                    </picture>

                                    <div className="details">
                                        <div><img src={double_arrow} alt="arrow" />
                                            <p>Fast closing, Less Work and no Voids</p>
                                        </div>
                                        <div className="middle"><img src={double_arrow} alt="arrow" />
                                            <p>Keep your commission</p>
                                        </div>
                                        <div><img src={double_arrow} alt="arrow" />
                                            <p>No more calls in middle of
                                                the night or bad tenants</p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <div className="main-container">
                                <div className="compare-head head-ash">Lenders</div>
                                <div className="cards-slide">
                                    <picture>
                                        <source type="image/webp" srcset={house_repair} />
                                        <source type="image/png" srcset={house_repairpng} />
                                        <img src={house_repair} alt="" />
                                    </picture>
                                    <div className="details">
                                        <div><img src={double_arrow} alt="arrow" />
                                            <p>Maintain customer Satisfaction</p>
                                        </div>
                                        <div className="middle"><img src={double_arrow} alt="arrow" />
                                            <p>Increase Customer Experience</p>
                                        </div>
                                        <div><img src={double_arrow} alt="arrow" />
                                            <p>Added Value to your Customer</p>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="find-out">
                <a href="mailto:tpv@xcelsz.com" target="_blank">
                    <button className="cta-btn">
                        <h1>Find how much your property is worth Now</h1>
                        <picture>
                            <source type="image/webp" srcset={price_1} />
                            <source type="image/png" srcset={price_1png} />
                            <img src={price_1png} alt="" />
                        </picture>
                    </button>
                </a>
            </section>
        </>
    );
}

export default howItWork;