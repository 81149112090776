import './about.css'
import four from "../../assets/images/about/4.jpg";
import five from "../../assets/images/about/5.jpg";
import six from "../../assets/images/about/6.jpg";
import seven from "../../assets/images/about/7.jpg";
import eight from "../../assets/images/about/8.jpg";

function about() {
    return (
        <>
             <div className="success-stories-wrapper"> 
                <section className="about-top success-stories-top">
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className="about-top-left success-top-left">
                                <h1 className='about-header fw-bolder text-uppercase text-center'>tpv story</h1>
                                <div className="header-divider"></div>
                                <h1 className='fw-bold align-middle ml-5'>
                                    Working together with You always.
                                </h1>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className="about-background"></div>
                        </div>
                    </div>
               </section>

                <section className="about-btm success-stories-bottom">
                    <div className='container-fluid'>
                        <div className='m-5 row'>
                            <div className='col-md-4'>
                                <div className='p-5 bs-card shadow-lg'>
                                    <p>
                                        We believe selling or renting out problem properties shouldn’t be challenging frustrating, or tough regardless of the circumstances around it or the condition it is in.
                                    </p>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='p-5 bs-card shadow-lg'>
                                    <p>
                                        Because of this, we created an interestingly superior solutions to make the process quick and easy for motivated and distressed owners who want to move on from a challenging property or challenging circumstances.
                                    </p>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='p-5 bs-card shadow-lg'>
                                    <p>
                                        We are your <b>GO-TO</b> fast problem property Solution. We aim to make your problem properties work for you. <br></br><br></br> TPV is trading brand Of <span className='text-primary'><u>Xcelsz</u></span> property solutions Ltd registered in Ghana and UK.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <div className='container-fluid'>
                    <div className="bg-primary m-4">
                        <div className='row'>
                            <div className='col-md-2'>
                                <div className='cash-back-background'></div>
                            </div>
                            <div className='col-md-10'>
                                <div className='row'>
                                    <div className='col-md-6 mb-4'>
                                        <div className='bg-dark-2'>
                                            <div className='row'>
                                                <div className='col-md-2'>
                                                    <img src={seven} className="img-holder-2" alt="financial difficulty owner" />
                                                </div>
                                                <div className='col-md-10 my-auto'>
                                                    <h2 className='text-white'>Operating since 2020</h2>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-6 mb-4'>
                                        <div className='bg-dark-2'>
                                            <div className='row'>
                                                <div className='col-md-2'>
                                                    <img src={four} className="img-holder-2" alt="financial difficulty owner" />
                                                </div>
                                                <div className='col-md-10 my-auto'>
                                                    <h2 className='text-white'>Any property, Any condition, Any location traded</h2>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-6 mb-4'>
                                        <div className='bg-dark-2'>
                                            <div className='row'>
                                                <div className='col-md-2'>
                                                    <img src={five} className="img-holder-2" alt="five" />
                                                </div>
                                                <div className='col-md-10 my-auto'>
                                                    <h2 className='text-white'>Capacity to complete 2-10 properties per month</h2>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-6 mb-4'>
                                        <div className='bg-dark-2'>
                                            <div className='row'>
                                                <div className='col-md-2'>
                                                    <img src={six} className="img-holder-2" alt="six" />
                                                </div>
                                                <div className='col-md-10 my-auto'>
                                                    <h2 className='text-white'>Completion date is up you. We are ready when you are.</h2>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='text-center mt-5 mb-5'>
                        <h1 className='fw-bolder text-uppercase'>Our History</h1>
                        <div className="header-divider"></div>
                    </div>
                    
                    <div className="bg-primary m-4">
                        <div className='row'>
                            <div className='about-his-card col-md-6'>
                                <div className='bg-dark-2 p-5 text-center'>
                                    <h2 className='text-primary fw-bold'>2020</h2>
                                    <div className='row'>
                                        <div className='col-md-2'>
                                            <img src={seven} className="img-holder" alt="financial difficulty owner" />
                                        </div>
                                        <div className='col-md-5'>
                                            <div className='section mb-5'>
                                                <h2 className='text-white'>Total Members</h2>
                                                <h2 className='text-white'>2</h2>
                                            </div>

                                            <div className='section mt-5'>
                                                <h2 className='text-white'>Total Helped</h2>
                                                <h2 className='text-white'>2 Owners</h2>
                                            </div>
                                        </div>
                                        <div className='col-md-5'>
                                            <div className='section mb-5'>
                                                <h2 className='text-white'>Total Transaction</h2>
                                                <h2 className='text-white'>2 Properties</h2>
                                            </div>

                                            <div className='section mt-5'>
                                                <h2 className='text-white'>Memberships</h2>
                                                <h2 className='text-white'>NAR, GREDA, GREPA</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='about-his-card col-md-6'>
                                <div className='bg-dark-2 p-5 text-center'>
                                    <h2 className='text-primary fw-bold'>2022</h2>
                                    <div className='row'>
                                        <div className='col-md-2'>
                                            <img src={eight} className="img-holder" alt="financial difficulty owner" />
                                        </div>
                                        <div className='col-md-5'>
                                            <div className='section mb-5'>
                                                <h2 className='text-white'>Total Members</h2>
                                                <h2 className='text-white'>4</h2>
                                            </div>

                                            <div className='section mt-5'>
                                                <h2 className='text-white'>Total Helped</h2>
                                                <h2 className='text-white'>10 Owners</h2>
                                            </div>
                                        </div>
                                        <div className='col-md-5'>
                                            <div className='section mb-5'>
                                                <h2 className='text-white'>Total Transaction</h2>
                                                <h2 className='text-white'>10 Properties</h2>
                                            </div>

                                            <div className='section mt-5'>
                                                <h2 className='text-white'>Memberships</h2>
                                                <h2 className='text-white'>NAR, GREDA, GREPA</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default about;