import { useEffect, useState } from "react";
import Typewriter from "typewriter-effect";

import "./HomePage.css";
import "./HeaderSection.css";

import Slider from "../../components/slider/Slider";
import maker from "../../assets/images/marker.png";
import ghIcon from "../../assets/images/ghana.webp";
import ukIcon from "../../assets/images/uk.png";
import nigeriaIcon from "../../assets/images/Flag_of_Nigeria.png";
import standing from "../../assets/images/standing.png";
import getStart from "../../assets/images/gettingStarted.svg";
import cancel from "../../assets/images/cancel.png";

function Homepage() {
  const [selectedCountry, setSelectedCountry] = useState("");

  const handleCountrySelect = (country) => {
    setSelectedCountry(country);
  };

  useEffect(() => {
    const selectCountry = document.querySelector(".selectCountry");
    const searchInput = document.querySelector(".myinput");

    selectCountry.addEventListener("click", function (e) {
      if (e.target.tagName === "A") {
        const country = e.target.id;
        if (country === "ghTab" || country === "ngTab") {
          searchInput.placeholder = "Enter your address";
        } else if (country === "ukTab") {
          searchInput.placeholder = "Enter your post code";
        }
      }
    });
  }, []);

  const [address, setAddress] = useState("");
  const [showForms, setShowForms] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [searchBarEmpty, setSearchBarEmpty] = useState(true);
  const [buttonState, setButtonState] = useState("start");
  const [checkboxChecked, setCheckboxChecked] = useState(false);
const [checkboxBorderColor, setCheckboxBorderColor] = useState('');


  const handleInputChange = (event) => {
    setAddress(event.target.value);
    setSearchBarEmpty(event.target.value === "");
  };

  const isValidGhanaAddress = (address) => {
    const addressRegex =
      /^(?=.*[A-Za-z0-9\s]+)(?=.*,[\s]?[A-Za-z0-9\s]+)(?=.*,[\s]?[A-Za-z0-9\s]+)$/;
    const digitalAddressRegex = /^GHA-\d{9}-\d$/;

    if (address.match(digitalAddressRegex)) {
      address = address.replace(digitalAddressRegex, "");
    }

    return addressRegex.test(address);
  };

  const isValidNigeriaAddress = (address) => {
    const addressRegex =
      /^(?=.*[A-Za-z0-9\s]+)(?=.*,[\s]?[A-Za-z0-9\s]+)(?=.*,[\s]?[A-Za-z0-9\s]+)(?=.*,[\s]?[A-Za-z0-9\s]+)$/;
    const streetNameRegex = /^.*[A-Za-z0-9\s]+,/;

    if (!address.match(streetNameRegex)) {
      return false;
    }

    return addressRegex.test(address);
  };

  const isValidUKAddress = (address) => {
    const postalCodeRegex = /^[A-Za-z0-9\s]+$/;
    return postalCodeRegex.test(address);
  };

  const setPlaceholderWithTimeout = (placeholder) => {
    let addressInput = document.getElementById("search");
    addressInput.placeholder = placeholder;
    setTimeout(() => {
      addressInput.placeholder = "Enter your address";
      addressInput.style.borderColor = "#ccc";
    }, 3000);
  };

  const handleStartSearch = () => {
    const addressValue = address.trim();
    const checkboxChecked = document.querySelector(".checkboxInput").checked;

    if (!searchBarEmpty) {
      let isValidAddress = false;

      if (selectedCountry === "GH") {
        isValidAddress = isValidGhanaAddress(addressValue);
      } else if (selectedCountry === "UK") {
        isValidAddress = isValidUKAddress(addressValue);
      } else if (selectedCountry === "NG") {
        isValidAddress = isValidNigeriaAddress(addressValue);
      }
      if(checkboxChecked){
      if (isValidAddress) {
        setButtonClicked(true);
        document.querySelector(".formsInputs").style.display = "block";
        document.querySelector(".fields").style.display = "block";
        setShowForms(true);
      } else {
        setPlaceholderWithTimeout("Please enter a valid address");
        return;
      }
    }else {
      setCheckboxBorderColor("red");

      setTimeout(() => {
        setCheckboxBorderColor("#fac710");
      }, 3000);
    }
    
      const formsInput = document.querySelector(".formsInputs");
      if (formsInput.style.display === "block") {
        setButtonState("clear");
        document.querySelector("#userAddress").style.display = "flex";
        document.getElementById("property-type").selectedIndex = 0;
        document.getElementById("property-condition").selectedIndex = 0;
        document.getElementById("owner-name").value = "";
        document.getElementById("owner-email").value = "";
      } else {
        setButtonState("start");
      }

    } else {
      setPlaceholderWithTimeout("Please enter a valid address");
    }

  };


  const handleContactMeNow = () => {
    const propertyType = document.getElementById("property-type").value;
    const propertyCondition = document.getElementById("property-condition").value;
    const phoneNumber = document.getElementById("phone-number").value;
    const ownerName = document.getElementById("owner-name").value;
    const ownerEmail = document.getElementById("owner-email").value;

    if (propertyType && propertyCondition && ownerName && ownerEmail) {
      document.querySelector(".fields").style.display = "none";
      document.querySelector(".success").style.display = "flex";
      document.querySelector("#userAddress").style.display = "none";
      document.querySelector(".standingImg").style.display = "flex";
    } else {
      if (!propertyType) {
        const propertyTypeField = document.getElementById("property-type");
        propertyTypeField.style.borderColor = "red";
        propertyTypeField.placeholder = "Please select property type";
        setTimeout(() => {
          propertyTypeField.style.borderColor = "";
          propertyTypeField.placeholder = "Select Property Type";
        }, 3000);
      }
      if (!propertyCondition) {
        const propertyConditionField =
          document.getElementById("property-condition");
        propertyConditionField.style.borderColor = "red";
        propertyConditionField.placeholder = "Please select property condition";
        setTimeout(() => {
          propertyConditionField.style.borderColor = "";
          propertyConditionField.placeholder = "Select Property Condition";
        }, 3000);
      }
      if (!ownerName) {
        const ownerNameField = document.getElementById("owner-name");
        ownerNameField.style.borderColor = "red";
        ownerNameField.placeholder = "Please enter name";
        setTimeout(() => {
          ownerNameField.style.borderColor = "";
          ownerNameField.placeholder = "Enter Name";
        }, 3000);
      }
      if (!phoneNumber) {
        const ownerPhoneNumberField = document.getElementById("phone-number");
        ownerPhoneNumberField.style.borderColor = "red";
        ownerPhoneNumberField.placeholder = "Please enter phone number";
        setTimeout(() => {
          ownerPhoneNumberField.style.borderColor = "";
          ownerPhoneNumberField.placeholder = "Enter Phone number";
        }, 3000);
      }
      if (!ownerEmail) {
        const ownerEmailField = document.getElementById("owner-email");
        ownerEmailField.style.borderColor = "red";
        ownerEmailField.placeholder = "Please enter a valid email";
        setTimeout(() => {
          ownerEmailField.style.borderColor = "";
          ownerEmailField.placeholder = "Enter Email";
        }, 3000);
      }
    }
  };

  const handleCloseFormsInput = () => {
    document.querySelector(".formsInputs").style.display = "none"
    document.querySelector(".success").style.display = "none";
    document.querySelector(".standingImg").style.display = "none";

    setButtonState("start");
  };

  return (
    <>
{/* Home Page First section implemented below, "header-section" */}
  
      <section className="header-section">
          <div className="one-half">
              <div className="top-part">
                  <h3 className="top">Sell or Rent Your House <span className="bottom text-primary">Fast</span></h3>
                  <h3 className="chanTxt">
                      <Typewriter
                      options={{
                      strings: [
                      "Any Property ...",
                      "Any Home ...",
                      "Any Location ...",
                      "Any Condition ...",
                      "Any Circumstance ...",
                      ],
                      autoStart: true,
                      loop: true,
                      delay: 100,
                      }}
                      />
                  </h3>
              </div>

              <img src={getStart} alt="getStart" className="getStartedImg-0" />
              
              <div className="searchbar">
                  <div className="selectCountry" required>
                      <img src={maker} alt="logo" className="logo" />
                      {selectedCountry !== "" ? (
                      <>
                      <img
                      src={
                      selectedCountry === "GH"
                      ? ghIcon
                      : selectedCountry === "UK"
                      ? ukIcon
                      : selectedCountry === "NG"
                      ? nigeriaIcon
                      : maker
                      }
                      alt="logo"
                      className="location"
                      />
                      <span className="country-text">{selectedCountry}</span>
                      </>
                      ) : (
                      <a href="#" className="country-link">
                      Select country
                      </a>
                      )}
                      <div className="mymenu">
                          <div className="a-second">
                              <a
                                  href="#"
                                  id="ghTab"
                                  className="menu-field"
                                  onClick={() => handleCountrySelect("GH")}
                              >
                              GH <img src={ghIcon} alt="logo" />
                              </a>
                              <div className="menu-link">
                                  <a
                                      href="#"
                                      id="ukTab"
                                      className="menu-field"
                                      onClick={() => handleCountrySelect("UK")}
                                  >
                                  UK <img src={ukIcon} alt="logo" />
                                  </a>
                              </div>
                              <a
                                  href="#"
                                  id="ngTab"
                                  className="menu-field"
                                  onClick={() => handleCountrySelect("NG")}
                              >
                              NG <img src={nigeriaIcon} alt="logo" />
                              </a>
                          </div>
                      </div>
                  </div>
                  <div className="searchInput">
                      <input
                          className="myinput"
                          type="text"
                          placeholder="Enter your address"
                          id="search"
                          value={address}
                          onChange={handleInputChange}
                          required
                          />
                      <div
                      className={`formsInputs ${
                      buttonClicked && showForms ? "visible" : ""
                      }`}
                      >
                      <div className="fields">
                          <span id="userAddress">
                          <a
                              href="#"
                              className="footBtn2"
                              id="footBtn2"
                              onClick={() => handleCloseFormsInput()}
                          >
                          <img src={cancel} alt="" />
                          </a>
                          </span>
                          <select className="fields-input" id="property-type">
                              <option value="">Select Property Type</option>
                              <option value="Residential">Residential</option>
                              <option value="Commercial">Commercial</option>
                              <option value="Land">Land</option>
                              <option value="Investment Property">
                                  Investment Property
                              </option>
                          </select>
                          <select className="fields-input" id="property-condition">
                              <option value="">Select Property Condition</option>
                              <option value="move_in_state">Move in state</option>
                              <option value="retired"> Retired</option>
                              <option value="light_refurb">
                                  Light refurb / renovation required
                              </option>
                              <option value="uncompleted_building">
                                  Uncompleted building
                              </option>
                              <option value="development_property">
                                  Development property (full refurb / renovation required)
                              </option>
                          </select>
                          <input
                              className="fields-input"
                              type="text"
                              id="owner-name"
                              placeholder="Enter Name"
                              />
                          <input
                              className="fields-input"
                              type="text"
                              id="phone-number"
                              placeholder="Enter Phone Number"
                              pattern=""
                              />
                          <input
                              className="fields-input"
                              type="text"
                              id="owner-email"
                              placeholder="Enter Email"
                              pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}"
                              />
                          <div className="footBtns">
                              <a
                                  href="#"
                                  className="footBtn1"
                                  id="BtnNext"
                                  onClick={() => handleContactMeNow()}
                              >
                              Contact Me Now
                              </a>
                          </div>
                      </div>
                      <div className="successPop">
                          <div className="success">
                              <a
                                  href="#"
                                  className="sucClose"
                                  id="cancelBtn"
                                  onClick={() => handleCloseFormsInput()}
                              >
                              <img src={cancel}></img>
                              </a>
                              <p>
                                  Thank you for submitting your details. TPV team will contact
                                  you within the next 48 hours.
                              </p>
                          </div>
                          <div className="standingImg">
                              <img src={standing} alt="Img" />
                          </div>
                      </div>
                  </div>
              </div>
              <div className="searchBtns">
                  <a href="#" id="start_search" onClick={handleStartSearch}>
                  {buttonState === "start" ? "START" : "CLEAR"}
                  </a>
              </div>
              </div>
              <div className="txtPrivacy">
                  <label className="checkboxLabel">
                  <input type="checkbox" className="checkboxInput" checked={checkboxChecked}
                      onChange={(e) => setCheckboxChecked(e.target.checked)} />
                  <span className="checkboxCustom"></span>
                  </label>
                  <p>
                      By completing this form, you consent to the data being <br />
                      used for the service requested for. See more in{" "}
                      <a href="#">Privacy</a>.
                  </p>
              </div>
          </div>
          <div class="second-half">
              <img src={getStart} alt="getStart" className="getStartedImg-1" />
          </div>
      </section>
      <div className="compare-section">
          <div className="compare-section-header">Areas where we differ</div>
          <Slider />
      </div>
    </>
  );
}

export default Homepage;
