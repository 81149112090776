import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Footer from "./components/footer/Footer";
import Navbar from "./components/navbar/Navbar";
import GettingStarted from "./views/gettingStarted/GettingStarted";
import Homepage from "./views/homePage/HomePage";
import WhoFor from "./views/whoForPage/WhoFor";
import WhatsIncluded from "./views/whatsIncluded/WhatsIncluded";
import Support from "./views/supportPage/Support";
import StopRepossession from "./views/stopRepossession/StopRepossession";
import HowItWork from "./views/howItWork/HowItWork";

import PropertyProfessionals from "./views/propertyProfessionals/PropertyProfessionals";

import FdOwners from "./views/fdOwners/FdOwners";
import FinancialInstitutions from "./views/financialInstitutions/FinancialInstitutions";

import MakeMeAnOffer from "./views/makeMeAnOffer/MakeMeAnOffer";
import SuccessStories from "./views/successStories/SuccessStories";

import TermsConditions from "./views/terms/TermsConditions"
import PrivacyPolicy from "./views/privacyPolicy/PrivacyPolicy";
import Cookies from "./views/cookies/Cookies";
import SPDevelopers from "./views/sPDevelopers/SPDevelopers"
import HomeSellers from "./views/homeSellers/HomeSellers";

import About from "./views/about/about";
import DistressedDevelopers from "./views/distressedDevelopers/DistressedDevelopers";
import LandLord from "./views/landlords/LandLord";



function App() {
  return (
    <>
      <Navbar />
      <Switch>
          <Route path="/" exact render={() => <Homepage />} />
        <Route path="/makeMeAnOffer" render={() => <MakeMeAnOffer />} />
        <Route path="/support" render={() => <Support />} />
        <Route path="/stopRepossession" render={() => <StopRepossession />} />
        <Route path="/howItWork" render={() => <HowItWork />} />
        <Route path="/gettingStarted" render={() => <GettingStarted />} />
        <Route path="/whatsIncluded" render={() => <WhatsIncluded />} />
        <Route path="/whoFor" render={() => <WhoFor />} />
        <Route path="/successStories/:subUrl" render={() => <SuccessStories />} />
        <Route path="/SPDevelopers" render={() => <SPDevelopers />} />
        <Route path="/propertyProfessionals" render={() => <PropertyProfessionals />} />
        <Route path="/distressedDevelopers" render ={() => <DistressedDevelopers />} />
        <Route path="/fdOwners" render={() => <FdOwners />} />
        <Route path="/about" render={() => <About />} />
        <Route path="/homeSellers" render={() => <HomeSellers />} />

        <Route path="/financialinstitutions" render={() => <FinancialInstitutions />} />
        <Route path="/terms" render={() => <TermsConditions />} />
        <Route path="/privacyPolicy" render={() => <PrivacyPolicy />} />
        <Route path="/cookies" render={() => <Cookies />} />

        <Route path="/landlord" render={() => <LandLord />} />
      </Switch>
      <Footer />
    </>
  );
}

export default App;
