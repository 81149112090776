import './FdOwners.css';
import { useState, useRef, useEffect } from "react";

import userData from '../../assets/images/user-data 1.svg';
import building from '../../assets/images/buildings 1.svg';
import virtual from '../../assets/images/virtual 1.svg';
import image5 from '../../assets/images/image 5.svg'
import assist from '../../assets/images/assistance 2.svg';
import dArrow from '../../assets/images/double-arrow.webp';
import standing from '../../assets/images/standing.png';
import close from '../../assets/images/closeBtn.png';
import navArrow from '../../assets/images/bi_arrow-right-circle-fill.svg';
import dropDownArrow from '../../assets/images/yellow-dropdown.svg';
import rectangle_why_us from '../../assets/images/Rectangle-fdo-why-us.png';
import loan_why_us from '../../assets/images/loan-why-us.png';
import why_us_get_offer from '../../assets/images/fdo-why-us-get-offer-img.png';

import emailjs from '@emailjs/browser';


function FdOwners () {

    const [index, setIndex] = useState(0);
    const [emailValid, setEmailValid] = useState();
    const [location, setLocation] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [textArea, setTextArea] = useState('');
    const [subConfirm, setSubConfirm] = useState();
    const [dropDown, setDropDown] = useState(0);

    const email = useRef();
    const text = useRef();
    const country = useRef();

    // Button slider
    const setFirstSlide = () => {
        setIndex(0)
    }

    const setSecondSlide = () => {
        setIndex(1)
    }

    const setThirdSlide = () => {
        setIndex(2)
    }


     //Next Btn function
     const nextSlide = () => {
        
        if (index === 2){
            setIndex(0)
        }
        else if (index < 2){
            setIndex(index+1)
        }   
    }
    

    //prev Btn function
    const prevSlide = () => {

        if (index > 0 ){
            setIndex(index-1)
        }
        else if (index === 0) {
            setIndex(2)
        }
    }

    const closeSubConfirm = (e) =>{
        e.preventDefault();
        console.log("Subscription confirm closed");
        setSubConfirm();
    }


    const ValidateEmail = (input) => {

        const validEmail = /^[A-Za-z\._\-0-9]*[@][A-Za-z]*[\.][a-z]{2,4}$/;
        const emailtxt = document.querySelector(".fdo-email");
        const error = document.querySelector(".fdo-error");

        if (input === "" || input.match(validEmail)) {
            error.style.display = "none";
            // emailtxt.style.outline = '1px solid #fac710';
            emailtxt.style.border = '2px solid #fac710';
            setEmailValid(1);
            // return true;
        } else {

          console.log("Invalid email address!");
          error.style.display = "block";
        //   emailtxt.style.outline = "2px solid red";
          emailtxt.style.border = '2px solid red';
          setEmailValid(0);
        //   return false;

        }

      }

    const clearField = () => {
        email.current.value = '';
        text.current.value = '';
        country.current.value = 'Select Location';
    }

    const form = useRef();

    const submitFdoForm = (e)=>{
        e.preventDefault();

        if (emailValid){

            emailjs.sendForm('service_lrekcyz', 'template_0sgyoak', form.current, 'cm_1OMdDgstBwtAkp')
            .then((result) => {
                console.log('sent');
                setSubConfirm(1);
                // clearField();
                setLocation('');

            }, (error) => {
                console.log(error);
                console.log('not sent');
            });

        }else{
            console.log("Invalid email")
        }

    }

    const locDropDown = () => {
        if (dropDown === 0){
            setDropDown(1);
        }else {
            setDropDown(0)
        }
    }

    const selectLocation = (i) => {
        if (i === 0){
            setLocation('Ghana');
        }
        else if (i === 1) {
            setLocation('United Kingdom');
        }
        else if (i === 2) {
            setLocation('Nigeria');
        }
        setDropDown(0);
    }

    const subForm = () => {
        if (emailValid && location && textArea){
            setSubConfirm(1);
            setLocation('');
        }
    }

    return(
        <>

            <div className="fdo-wrapper"> 
                <section className="fdo-top">
                    <div className="fdo-top-left">
                        <h1 className="fdo-heading">
                            Getting a Helping Hand
                            Made <span className="yellow"> Easy</span>  
                        </h1>
                        <div className="fdo-container">
                            <img src={userData} alt=""/>
                            <p>No need to wait until your home is repossessed</p>
                        </div>
                        <div className="fdo-container">
                            <div>
                                <img src={building} style={{borderRadius: '5px'}} alt=""/>
                            </div>
                            <p>No more worries of moving</p>
                        </div>
                        <div className="fdo-container">
                            <img src={virtual} alt=""/>
                            <p>No commission fees or legal costs to you at all</p>
                        </div>
                    </div>
                    <div className="fdo-background">

                    </div>
                     <div className="fdo-container-intro">
                        <p> Your perfect solution to stoppping repossession available right away.</p>

                     </div>

                </section>

                <section className="fdo-slide-section">
                    <div className="fdo-head">
                        <h2>
                        Immediate Help Tailored to your needs, at your convenience
                        <span className="yellow"> ....</span>....
                        </h2>
                    </div>
                    <div className='fdo-slider'>
                        {
                            index === 0 &&
                            <div className='fdo-slider-headers'>
                                <div className="fdo-slider-head">
                                    <p>You know someone who can use the Financial Difficulty Solution?</p>
                                </div>
                                <div className="fdo-slider-head" style={{background: '#fac710'}}>
                                    <p style={{width: '660px', fontSize: '35px', lineHeight: '42px'}}>Get them on the Financial Difficulty’s  VIP List Now</p>
                                </div>
                            </div>
                        }
                        {
                            index === 1 &&
                            <div className='fdo-slider-headers'>
                                <div className="fdo-slider-head" style={{background: '#fac710'}}>
                                <p style={{fontSize: '60px', lineHeight: '72px'}}>Why it Works?</p>
                                </div>
                                <div className="fdo-slider-head">
                                    <p>We take on any property, in any condition, and in any location.</p>
                                </div>
                            </div>
                        }
                        {
                            index === 2 &&
                            <div className='fdo-slider-headers'>
                                <div className="fdo-slider-head" style={{background: '#fac710'}}>
                                <p style={{fontSize: '41px', width: '100%'}}>Whatever your circumstance may be, Stop Repossession</p>
                                </div>
                                <div className="fdo-slider-head">
                                    <p style={{fontSize: '36px'}}>Avoid Stress based on what works for you</p>
                                </div>
                            </div>
                        }
                        <div className="fdo-main-slider">
                            <div className="fdo-slide-container" >
                                <div className="fdo-slide-inner-container">
                                    {
                                        index === 0 &&
                                        <div style={{width: 'content', height: 'content'}}>
                                            {
                                                !subConfirm &&
                                                <div className='fdo-customer-review-card' id=''style={{height: '429px', display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
                                                    <form className='fdo-form' ref={form} onSubmit={submitFdoForm}>
                                                        <div className='email-subject' style={{display: 'none'}}>
                                                            <input type='text' name='header' value={'Financial Difficulty Owner VIP'} readOnly />
                                                        </div>
                                                        <div className='fdo-input-field'>
                                                            <div className='fdo-case-number' style={{width: '80px', height: '60px'}}>1</div>
                                                            
                                                            {/* Default Dropdown Menu */}
                                                            {/* <select name='location' onChange={(e)=>setLocation(e.target.value)} required> 
                                                                <option value=''>Select Location</option>
                                                                <option value='Ghana'>Ghana</option>
                                                                <option value='United Kingdom'>United Kingdom</option>
                                                                <option value='Nigeria'>Nigeria</option>
                                                            </select> */}
                                                            
                                                            {/* Customized Dropdown Menu */}
                                                            <div className='select-field'>
                                                                <input type='text' name='location' className='select-input' value={location} onClick={locDropDown} placeholder='Select Location' readOnly required/>
                                                                <img className={dropDown === 1 ? 'activee' : ''} onClick={locDropDown} src={dropDownArrow} alt='dropdown' />
                                                                {
                                                                    dropDown === 1 &&
                                                                    <div className='fdo-select'>
                                                                        <ul className='fdo-ul-select'>
                                                                            <li className='select-option' onClick={(e)=>{selectLocation(0)}}>Ghana</li>
                                                                            <li className='select-option' onClick={(e)=>{selectLocation(1)}}>United Kingdom</li>
                                                                            <li className='select-option' onClick={(e)=>{selectLocation(2)}}>Nigeria</li>
                                                                        </ul>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className='fdo-input-field'>
                                                            <div className='fdo-case-number' style={{width: '80px', height: '60px'}}>2</div>
                                                            <input type='email' name='user_email' className='fdo-email' placeholder='Input Your Email' onChange={(e)=>setUserEmail(e.target.value)} onKeyUp={(e)=>ValidateEmail(e.target.value)} required />
                                                        </div>
                                                        <span className="fdo-error">please enter a valid email: example@domain.com</span>
                                                        <div className='fdo-input-field'>
                                                            <div className='fdo-case-number' style={{width: '80px', height: '60px'}}>3</div>
                                                            <textarea type='textarea' name='about' placeholder='About you and thier Situations' onChange={(e)=>{setTextArea(e.target.value)}} required></textarea>
                                                        </div>
                                                        {/* Uncomment line below to submit form to submit form directly to tpv@xcelsz.com*/}
                                                        <input type='submit' value='Send' />
                                                    </form>
                                                    {/* Comment this out when using form to submit directly to tpv@xcelsz.com */}
                                                    {/* <a style={{pointerEvents: `${emailValid && location && userEmail && textArea ? '' : 'none'}`}} href={`mailto:tpv@xcelsz.com?subject=Financial%20Difficulty%20VIP&body=Location:%20${location}%0DEmail:%20${userEmail}%0DAbout:%20${textArea}`} rel="noreferrer" target="_blank">
                                                        <button onClick={subForm} className={`fdo-btn ${emailValid && location && userEmail && textArea ? 'fdo-btn-h' : ''}`} type='submit'>Send</button>
                                                    </a> */}
                                                </div>
                                            }

                                            {
                                                subConfirm &&
                                                <div className='fdo-confirm-wrapper'>
                                                    <div className='fdo-cfm'>
                                                        <div className='fdo-cfm-inner'>
                                                            <button type="reset" onClick={closeSubConfirm}>
                                                                <img className="close-confirm" src={close} alt="cancel"/>
                                                            </button>
                                                            <p>Thank you for submitting your request. You'll hear from us with a free no obligation offer within 48 hours.</p>
                                                        </div>
                                                    </div>
                                                    <div className='fdo-cfm-img'>
                                                        <img src={standing} alt='' />
                                                    </div>

                                                </div>
                                            }
                                            
                                        </div>                                     
                                        
                                    }

                                    {
                                        index > 0 &&
                                        <div className='fdo-second-slide-main'>
                                            <div className='fdo-second-slide'>
                                                <div className='fdo-ss-head'>
                                                    {
                                                        index === 1 &&
                                                        <div>
                                                            <img src={dArrow} alt='' />
                                                        </div>
                                                    }
                                                    {
                                                        index === 2 &&
                                                        <p>Tailored Options</p>
                                                    }
                                                    
                                                </div>
                                                {
                                                    index === 1 &&
                                                    <p>
                                                        Close Immediately to avoid more hassle
                                                    </p>
                                                }
                                                {
                                                    index === 2 &&
                                                    <p>
                                                        Superb solutions Tailored to Your Needs
                                                    </p>
                                                }
                                                
                                            </div>
                                            {
                                                index === 1 &&
                                                <div className='fdo-second-slide-img'>
                                                    <img src={assist} alt='' />
                                                </div>
                                            }
                                            {
                                                index === 2 &&
                                                <div className='fdo-ss-img'>
                                                    <img src={image5} alt='' />
                                                </div>
                                            }
                                            
                                            <div className='fdo-second-slide'>
                                                <div className='fdo-ss-head'>
                                                    {
                                                        index === 1 &&
                                                        <div>
                                                            <img src={dArrow} alt='' />
                                                        </div>
                                                    }
                                                    {
                                                        index === 2 &&
                                                        <p>Tailored Offers</p>
                                                    }
                                                    
                                                </div>
                                                {
                                                    index === 1 &&
                                                    <p>
                                                        Free Home moving assistance when required
                                                    </p>
                                                }
                                                {
                                                    index === 2 &&
                                                    <p>
                                                        Great Offers uniquely design for you.
                                                    </p>
                                                }
                                                
                                            </div>
                                        </div>
                                    }
                                    
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="fdo-slide-btn">
                        <button className={index === 0 ? 'activee' : ''} onClick={setFirstSlide}></button>
                        <button className={index === 1 ? 'activee' : ''} onClick={setSecondSlide}></button>
                        <button className={index === 2 ? 'activee' : ''} onClick={setThirdSlide}></button>
                    </div>

                    <button className="fdo-control-previous" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev" onClick={prevSlide}>
                        <img alt="previous" className="fdo-leftslider"  src={navArrow}/>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="fdo-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next" onClick={nextSlide}>
                        <img alt="next" className="fdo-rightslider" src={navArrow}/>
                        <span className="visually-hidden">Next</span>
                    </button>
                </section>

                <section className='fdo-why-us-only-mb'>
                    <h2> Why Us?</h2>
                    <div className='fdo-why-us-bg-white'>
                        <div className='fdo-why-us-rectangle'>
                            <img src={rectangle_why_us} className='fdo-why-us-img'></img>
                            <img src={loan_why_us} className='fdo-why-us-img-loan'></img>
                            <p className='fdo-why-us-p'>We are professional independent homebuyers 
                            and  relocation specialist, and because we make direct to you the Home Owner, we can present 
                            you with great offers you can't miss, with <span className='fdo-why-us-span'>ZERO</span> fees to you.</p>
                            <p className='fdo-why-us-readmore'>
                                <a href='/about'>READ MORE</a>
                            </p>
                        </div>
                        <div className='fdo-why-us-get-offer'>
                                    <img src={why_us_get_offer} className='fdo-why-us-get-offer-img'></img>
                                    <p className='fdo-why-us-get-offer-p'> Get offer within 48 hours</p>
                        </div>
                        <div className='fdo-why-us-get-offer'>
                        <img src={why_us_get_offer} className='fdo-why-us-get-offer-img'></img>
                        <p className='fdo-why-us-get-offer-p'> Choose completion date suitable to YOU.</p>

                        </div>

                    </div>
                </section>

                

            </div>
        </>
    );

}

export default FdOwners;