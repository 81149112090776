export const innerData = [
    {
        title: "Legal Fees",
        leftContent: "Additional\nCharge",
        rightContent: "1-10% or more of sales/rental price"
    },
    {
        title: "Completion",
        leftContent: "Additional\nCharge",
        rightContent: "No commission payment or fee"
    },
    {
        title: "Commission",
        leftContent: "Within 3-6 months or more",
        rightContent: "Within 7-14 days guaranteed"
    },
    {
        title: "Maintenance",
        leftContent: "Additional Charge, roughly equal to 5-10% of Rental Income",
        rightContent: "Free reactive\nMaintainance\nrepairs"
    },
    {
        title: "Refurbishment",
        leftContent: "Additional\nCharge",
        rightContent: "No\nLegal Fees"
    },
    {
        title: "Rent Gap",
        leftContent: "Atleast 1 month or more",
        rightContent: "No\nVoids"
    },
    {
        title: "Valuation",
        leftContent: "Additional\nCharge",
        rightContent: "Free\nAppraisal"
    },
]