import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import "@progress/kendo-theme-default/dist/all.css";
import './index.css';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <Switch>
        <Route path="/404" exact render={() => <div>404 || Page Not Found</div>} />
        <Route path="/" component={App} />
      </Switch>
    </Router>
    {/* <App /> */}
  </React.StrictMode>
);

