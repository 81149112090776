import './CaseStudiesCard.css'


const CaseStudiesCard = ({ slide1, slide2 }) => {

    return (
        <>
            
            <div className="case-studies-card">
                <div className="case-studies-head">
                    <div className="case-number">{slide1.number}</div>
                    <div className="case-description">
                        <p >{slide1.description}</p>
                    </div>
                    
                </div>
                <div className="case-container">
                    <div className="case-problem">
                        <div className="case-problem-head">
                            <p>Problem</p>
                        </div>
                        <div className="problem">
                            <p>{slide1.problem}</p>
                        </div>

                    </div>
                    <div className="case-solution">
                        <div className="case-solution-head">
                            <p>Solution</p>
                        </div>
                        <div className="solution">
                            <p>{slide1.solution}</p>
                        </div>
                    </div>  
                </div>  
                <div className="case-year">{slide1.year}</div>

            </div>

            <div className="case-studies-card">
                <div className="case-studies-head">
                    <div className="case-number">{slide2.number}</div>
                    <div className="case-description">
                        <p >{slide2.description}</p>
                    </div>
                </div>
                <div className="case-container">
                    <div className="case-problem">
                        <div className="case-problem-head">
                            <p>Problem</p>
                        </div>
                        <div className="problem">
                            <p>{slide2.problem}</p>
                        </div>

                    </div>
                    <div className="case-solution">
                        <div className="case-solution-head">
                            <p>Solution</p>
                        </div>
                        <div className="solution">
                            <p>{slide2.solution}</p>
                        </div>
                    </div>  
                </div>  
                <div className="case-year">{slide2.year}</div>

            </div>

        </>
    )
}

export default CaseStudiesCard;