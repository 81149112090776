import './CustomerReviewCard.css';


const CustomerReviewCard = ({slide}) => {
   
    return (
        <>

            <div className="customer-review-card">
                <div className="review-head">
                    <div className="rating-number">2</div>
                    <p className="rating-details">Overall Ratings</p>
                    
                    <div className="overall-rating-stars">
                        <div className="ratings-border-yellow">
                            <div className="ratings-border-white">
                                <div className="rating-star">
                                    <div className="star"></div>
                                    <div className="star"></div>
                                    <div className="star"></div>
                                    <div className="star"></div>
                                    <div className="star"></div>
                                </div>
                            </div> 
                        </div>                      
                        
                    </div>
                </div>

                <div className="customer-review-container">
                    <div className="customer-review-container-inner">
                        <div className="customer-details">
                            <h3 className="review-number">{slide.number}</h3>
                            <h3 className="customer">{slide.name}, {slide.address}</h3>
                        </div>

                        <div className="customer-review">
                            <p><span>,, </span>{slide.review}<span> ,,</span></p>
                        </div>
                    </div>                    
                </div>
                <div className="review-star-container">
                    <div className="review-star">
                        <div className="small-star"></div>
                        <div className="small-star"></div>
                        <div className="small-star"></div>
                        <div className="small-star"></div>
                        <div className="small-star"></div>
                    </div>
                </div>
            </div>
                
        </>
    );
    

}

export default CustomerReviewCard;
