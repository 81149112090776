import { useState, useEffect } from 'react'
import WhatsupIcon from '../../assets/images/watsapp.png'
import phoneIcon from '../../assets/images/phone.png'
import emailIcon from '../../assets/images/email.png'
import logo from '../../assets/images/tpv logo svg-01.svg'
import flagUnited from '../../assets/images/Flag_of_the_United_Kingdom_(1-2).png'
import flagGhana from '../../assets/images/ghana.webp'

import { Link } from 'react-router-dom';
import DropDownMenu from './DropDownMenu'
import './NavBar.css'
function Navbar() {
    const [wvisiblility, setWvisibility] = useState(false)
    const [pvisiblility, setPvisibility] = useState(false)
    const whatsAppDropDown = [
        {
            id: 1, dropDownItem: <a href="https://wa.me/447580013780?text=This%20is%20TPV%20interested%20in%20your%20house%20sale"
                target="_blank">
                <img src={flagUnited} className='dropdown-icon' alt="logo" />
                <p className='child-span'>+447580013780</p>
            </a>
        },
        {
            id: 2, dropDownItem: <a href="https://wa.me/+23350133851?text=This%20is%20TPV%20interested%20in%20your%20house%20sale"
                target="_blank"
            >
                <img src={flagGhana} className='dropdown-icon' alt="logo" />
                <p className='child-span'>+23350133851</p>
            </a>
        }
    ]
    const phoneDropDown = [
        {
            id: 1, dropDownItem: <a href="tel:+01415544224">
                <img src={flagUnited} alt="logo" />
                <p className='child-span'>+01415544224</p>
            </a>
        },
        {
            id: 2, dropDownItem: <a href="tel:+233501338581">
                <img src={flagGhana} alt="logo" />
                <p className='child-span'>+233501338581</p>
            </a>
        }
    ]

    // this function makes whatsapp have a drop down which inturn shows the numbers and the flags//
    const showWhatsappContent = () => {
        setWvisibility(!wvisiblility)
        setPvisibility(false)
    }

    const showPhoneContent = () => {
        setWvisibility(false)
        setPvisibility(!pvisiblility)
    }

    // The following code makes the dropdown disappear when any other place
    // is clicked
    useEffect(() => {
        const handleWindowClick = (event) => {
            const isWhatsappLinkClicked = event.target.classList.contains('whatsAppIcon');
            const isPhoneLinkClicked = event.target.classList.contains('phoneIcon');
            if (!isWhatsappLinkClicked && !isPhoneLinkClicked) {
                setWvisibility(false);
                setPvisibility(false);
            }
        };
        window.addEventListener('click', handleWindowClick);
        return () => window.removeEventListener('click', handleWindowClick);
    }, []);

    return (
        <>
            <div class="emailAlert">
                <div class="emailSuccess">
                    <p>Thanks for subscribing to TPV monthly-insight letters</p>
                    <a href="#" id="emailBt"><i class="fa fa-plus"></i></a>
                </div>
            </div>

            <div class="nav-bar" id="nav-bar">
                <div class="nav-first text-white">
                    <ul>
                        <li><span>Talk to us on:</span></li>
                        <li>
                            <a onClick={showWhatsappContent} href="#whatsApp" className="whatsApp"><img src={WhatsupIcon} alt="whatsApp" className='whatsAppIcon' /></a>
                            {wvisiblility && <DropDownMenu items={whatsAppDropDown} />}
                        </li>
                        <li>
                            <a onClick={showPhoneContent} href="#" className="phone"><img src={phoneIcon} alt="phone" className='phoneIcon' /></a>
                            {pvisiblility && <DropDownMenu items={phoneDropDown} />}
                        </li>
                        <li><a href="mailto:tpv@xcelsz.com" style={{ color: '#808080' }}><img src={emailIcon} alt="telphone" /></a></li>
                    </ul>
                </div>
                <div class="nav-last bg-dark" id="nav-last">
                    <div className='nav-logo'>
                        {/* <a href="/"><img src={logo} alt="LOGO" /></a> */}
                        <Link to="/" class="logo"><img src={logo} alt="LOGO" /></Link>
                    </div>
                    <div class="navlist">
                        <div class="navitem HIW">
                            <a href="#" class="mydrop-down" id="howBtn">How it works <i style={{ color: "#fac710", fontSize: "20px" }} class="fa fa-angle-down"></i></a>
                            <div class="mydropdown-menu">
                                <div class="mydrop-second">
                                    <Link to="/gettingStarted" class="mya">Getting Started</Link>
                                    <Link to="/whatsIncluded" class="mya">What's Included</Link>
                                    <Link to="/whoFor" class="mya">Who For</Link>
                                    <Link to="/support" class="mya">Support</Link>
                                </div>
                            </div>
                        </div>
                        <div class="navitem HIW">
                            <a href="#" class="mydrop-down" id="howBtn">Solutions<i style={{ color: "#fac710", fontSize: "20px" }} class="fa fa-angle-down"></i></a>
                            <div class="mydropdown-menu">

                                <div class="mydrop-second">
                                    <Link to="/landlord" class="mya">Distressed Landlords</Link>
                                    <Link to="/homeSellers" class="mya">Distressed Home Sellers</Link>  
                                    <Link to="/fdOwners" class="mya">Financial Difficulty Owners</Link>
                                    <Link to="/distressedDevelopers" class="mya">Distressed Property Developers</Link>
                                    <Link to="/propertyProfessionals" class="mya">Distressed Property Professionals</Link>
                                    <Link to="/financialinstitutions" class="mya">Financial Institutions</Link>

                                </div>
                            </div>
                        </div>
                        <div class="navitem HIW">
                            <a href="#" class="mydrop-down" id="howBtn">Success Stories <i style={{ color: "#fac710", fontSize: "20px" }} class="fa fa-angle-down"></i></a>
                            <div class="mydropdown-menu">
                                <div class="mydrop-second">
                                    <Link to="/successStories/caseStudies" class="mya">Case Studies</Link>
                                    <Link to="/successStories/customerReviews" class="mya">Customer Reviews</Link>
                                    {/* <a href="/successStories" class="mya">Before and After</a> */}
                                    {/* <a href="/successStories/caseStudies" class="mya">Case Studies</a>
                                    <a href="/successStories/customerReviews" class="mya">Customer Reviews</a> */}
                                    

                                </div>
                            </div>
                        </div>

                        <div class="navitem">
                        </div>
                        <div class="navitem">
                        </div>
                        <div class="navitem">
                        </div>
                    </div>
                    <div class="nav-buttons">
                        <Link to="/stopRepossession" class="repossession" id="stop_repossession">Stop Repossesion</Link>
                        {/* <a href="mailto:tpv@xcelsz.com" class="navbtn" id="getOff">Make me an Offer</a> */}
                        <Link to='/makeMeAnOffer' className='navbtn' id='getOff'>Make me an Offer</Link>
                    </div>
                    <div class="menu-icon">
                        <a href="#" id="show-list">
                            <i class="fa fa-bars"></i>
                        </a>
                    </div>
                </div>
            </div>

            <div class="hidden-nav">
                <div class="as">
                    <div class="nav-cont">
                        <a href="#" class="seda" id="HIW">How it works<i class="fa fa-angle-down"></i></a>
                        <div class="Secod-dropdown-content">
                            <div class="ss-drop">
                                <a href="/gettingStarted.php" class="aa" id="process">Getting Started</a>
                                <a href="/howItWork.php" class="aa" id="process">Who for</a>
                                <a href="/support.php" class="mya">Support</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="right">
                    <a href="makeMeAnOffer.php">Make me an Offer</a>
                </div>
            </div>
        </>
    );
}

export default Navbar;
