import { useState, useRef } from 'react'
import './Support.css'

import estate from '../../assets/images/support/estate.png';
import estate2 from '../../assets/images/support/estate (1).png';
import query from '../../assets/images/support/query.png';
import homework from '../../assets/images/support/homework.png';
import review from '../../assets/images/support/review.png';
import calendar from '../../assets/images/support/calendar.png';
import getStart from "../../assets/images/support/Thinking face-bro.svg";


function Support() {
    const [percenteage, setPercenteage] = useState(0)
    // const [input, setPercenteage] = useState(0)
    const [inputField, setInputField] = useState({
        country: "",
        offer: "",
        condition: "",
    });

    const handleChange = (event) => {
        setInputField({
        ...inputField,
        [event.target.id]: event.target.value,
        });
        console.log(`${event.target.id} : ${event.target.value}`);
        calculate()
    };

    const finalValue = useRef(null)
    const value = useRef(null)

    function calculate() {
        var currenValue = value.current.value;
        if (inputField.offer === 'Guaranteed Rent') {
            switch (inputField.condition) {
                case 'move_in_state':
                    setPercenteage(95);
                    break;
                case 'retired':
                    setPercenteage(70);
                    break;
                case 'light_refurb':
                    setPercenteage(85);
                    break;

                default:
                    setPercenteage(0);
                    break;
            }

        }
        if (inputField.offer === 'Lease Option') {
            switch (inputField.condition) {
                case 'move_in_state':
                    setPercenteage(100);
                    break;
                case 'retired':
                    setPercenteage(75);
                    break;
                case 'light_refurb':
                    setPercenteage(85);
                    break;

                default:
                    setPercenteage(0);
                    break;
            }

        }
        if (inputField.offer === 'Direct Buy') {
            switch (inputField.condition) {
                case 'move_in_state':
                    setPercenteage(95);
                    break;
                case 'retired':
                    setPercenteage(85);
                    break;
                case 'light_refurb':
                    setPercenteage(55);
                    break;
                default:
                    setPercenteage(0);
                    break;
            }

        }
        if (inputField.offer === 'Assisted Sale') {
            switch (inputField.condition) {
                case 'move_in_state':
                    setPercenteage(90);
                    break;
                case 'retired':
                    setPercenteage(75);
                    break;
                case 'light_refurb':
                    setPercenteage(85);
                    break;
                case 'development_property':
                    setPercenteage(65);
                    break;
                case 'uncompleted_building':
                    setPercenteage(55);
                    break;

                default:
                    setPercenteage(0);
                    break;
            }

        }
        if (inputField.offer === 'Assisted Rent') {
            switch (inputField.condition) {
                case 'move_in_state':
                    setPercenteage(90);
                    break;
                case 'retired':
                    setPercenteage(75);
                    break;
                case 'light_refurb':
                    setPercenteage(85);
                    break;

                default:
                    setPercenteage(0);
                    break;
            }

        }

        if (inputField.offer === 'Land') {
            switch (inputField.condition) {
                case 'move_in_state':
                    setPercenteage(95);
                    break;
                case 'retired':
                    setPercenteage(70);
                    break;
                case 'light_refurb':
                    setPercenteage(85);
                    break;

                default:
                    setPercenteage(0);
                    break;
            }
        }

        var total = (percenteage / 100) * currenValue;
        console.log(total);
        document.getElementById("market_price").value = currenValue;
        document.getElementById("final-value").innerHTML = total.toFixed(2);
    }

    return (
        <>
        <section className="header-section halfone-bg">
            <div className="one-half">
                <div className="top-part">
                    <h3 className="top">Need Help Making Informed</h3>
                    <h3 className="top">Decisions <span className="text-primary">Easy</span></h3>
                </div>

                <img src={getStart} alt="getStart" className='mv-img'/>

                <div class="d-grid gap-2 buttons p-5">
                    <h4 className='pl-3 '>At absolutely no cost you can request and offer on you home at The Property Vendee (TPV)</h4>
                    <button class="button-item btn btn-lg shadow-lg btn-white text-start mb-3" type="button">
                        <img src={estate} className="btn-image" alt="getStart"/>                            
                        Calculate no obligations offers
                    </button>
                    
                    <button class="button-item btn btn-lg shadow-lg btn-dark text-start mb-3 text-white" type="button">
                        <img src={estate2} className="btn-image bg-white" alt="getStart"/>                            
                        Get Free Appraisal
                    </button>

                    <button class="button-item btn btn-lg shadow-lg btn-default text-start mb-3" type="button">
                        <img src={query} className="btn-image" alt="getStart"/>                            
                        Compare Offers
                    </button>
                </div>
            </div>

            <div class="second-half">
                <img src={getStart} alt="getStart"/>
            </div>
        </section>

            <section className="Steps-Away">
                <div className="header-3 mb-5 text-dark text-center fw-bolder">
                    <h1>Get a Free no obligation <br /> support <span className='text-primary'>...</span>...</h1>
                </div>

                <div className='container'>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="shadoww-2" >
                                <div className="bg-white">
                                    <div className="row p-3">
                                        <div className="col-md-3 col-sm-6 col-xs-6 my-auto">
                                            <h5 className="mr-5 pt-3">Select Support Type</h5>
                                        </div>

                                        <div className="col-md-6 col-sm-6 col-xs-6 my-auto mx-auto text-center">
                                            <ul className="nav nav-pills" id="pills-tab" role="tablist">
                                                <li className="nav-item" role="presentation">
                                                    <button className="nav-link show active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">1</button>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <button className="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">2</button>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <button className="nav-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">3</button>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <button className="nav-link" id="pills-compare-rent-offers-tab" data-bs-toggle="pill" data-bs-target="#pills-compare-rent-offers" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">4</button>
                                                </li>

                                            </ul>
                                        </div>

                                        <div className="col-md-3  col-sm-12 col-xs-12 float-end">
                                            <h5 className="pt-2">
                                                <small className="mr-5 mt-sm-5 mt-xs-5">Change Currency 
                                                    <select name="country-1" id="country-1" aria-labelledby="country" className='bg-dark text-primary ml-5' style={{ width: "50px" }} required>
                                                        <option value="">Select Currency</option>
                                                        <option value="uk" selected>£</option>
                                                        <option value="nigeria">N</option>
                                                        <option value="ghana">GHS</option>
                                                    </select>
                                                </small>
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-content bg-dark" id="pills-tabContent">
                                    <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-profile-tab">
                                        <div className="header my-auto pt-4">
                                            <h3 className="">Estimate potential Offer</h3>
                                        </div>
                                        <section className=" p-5">
                                            <form action="support.php" method="post" style={{ height: "auto" }}>
                                                <div className="row">
                                                    <div className="col-md-12 text-center mb-4">
                                                        <input type="text" name="name" id="name" className="form-control form-control-lg text-center" aria-labelledby="name" placeholder="Input Name" required />
                                                    </div>
                                                    <div className="col-md-12 text-center mb-4">
                                                        <input type="email" name="email" id="email" className="form-control form-control-lg text-center" aria-labelledby="email" placeholder="Input Email" required />
                                                    </div>
                                                    <div className="col-md-12 text-center mb-4">
                                                        <select name="country" id="country" onChange={handleChange} className="form-control form-control-lg" aria-labelledby="country" required>
                                                            <option value="">Select Country</option>
                                                            <option value="uk">UK</option>
                                                            <option value="nigeria">Nigeria</option>
                                                            <option value="ghana">Ghana</option>
                                                        </select>
                                                    </div>

                                                    <div className="col-md-12 text-center mb-4">
                                                        <select name="offer_type" onChange={handleChange} id="offer" className="form-control form-control-lg" aria-labelledby="offer_type" required>
                                                            <option value="">Select Offer Type</option>
                                                            <option value="Guaranteed Rent">Guaranteed Rent</option>
                                                            <option value="Lease Option">Lease Option</option>
                                                            <option value="Direct Buy / Referal Buy">Direct Buy / Referal Buy</option>
                                                            <option value="Assisted Sale">Assisted Sale</option>
                                                            <option value="Assisted Rent">Assisted Rent</option>
                                                            <option value="Land">Land</option>
                                                        </select>
                                                    </div>

                                                    <div className="col-md-12 text-center mb-4">
                                                        <select name="property_condition" onChange={handleChange} id="condition" aria-labelledby="property_condition" className="form-control form-control-lg" required>
                                                            <option value="">Select Property Condition</option>
                                                            <option value="move_in_state">Move in state</option>
                                                            <option value="retired"> Retired</option>
                                                            <option value="light_refurb"> Light refurb / renovation required</option>
                                                            <option value="uncompleted_building"> Uncompleted building</option>
                                                            <option value="development_property"> Development property (full refurb / renovation required)</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-md-12 text-center mb-4">
                                                        <label for="price-range" className=" text-white">Select Market Prince Range (100 - 1000000)</label>
                                                        {/* <input type="range" id="price-range" name="range" className="range form-range red" step={1} min={1} max={1000000} value="" onChange={calculate} /> */}
                                                        <input
                                                            ref={value}
                                                            type='range'
                                                            id="price-range"
                                                            name="range"
                                                            className="range form-range red"
                                                            onChange={calculate}
                                                            min={1}
                                                            max={400}
                                                            step={1}
                                                        />
                                                        <input type="text" id="market_price" name="market_price" className="market_price form-control mt-3 form-control-lg text-center" value="0" />
                                                    </div>

                                                    <div className="form-row mb-4">
                                                        <div className="contents bg-white p-3">
                                                            <span className="currency">£</span>
                                                            <span>Our Offer if roughly...</span>
                                                            <span id='final-value' className="text-primary fs-5 text-end float-end fw-bolder">0</span>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="text-center">
                                                    <p className='text-center'>
                                                        <div className="mb-5 text-center">
                                                            <button className="btn-mv btn btn-primary" name="estimate_offer">Submit</button>
                                                        </div>
                                                    </p>
                                                    <p className="text-white">By completing this form, you consent to your data being used for the service requested for. See more in <a href="#" className='text-primary'>Privacy</a>.</p>
                                                </div>
                                            </form>
                                        </section>
                                    </div>
                                    
                                    <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                                        <div className="header my-auto pt-4">
                                            <h3 className="">Estimate Property Value <br />
                                                <span className="text-primary">(Free Online Appraisal)</span>
                                            </h3>
                                        </div>
                                        <section className="content-comparison p-5 text-center">
                                            <div className="row">
                                                <div className="col-md-12 text-center mb-4">
                                                    <select name="country" className="form-control form-control-lg">
                                                        <option value="">Select Country</option>
                                                    </select>
                                                </div>
                                                <div className="col-md-12 mb-4">
                                                    <select name="solution_type" className="form-control form-control-lg">
                                                        <option value="">Select Solution Type</option>
                                                    </select>
                                                </div>
                                                <div className="col-md-12 mb-4">
                                                    <select name="property_size" className="form-control form-control-lg">
                                                        <option value="">Select Property Size</option>
                                                    </select>
                                                </div>
                                                <div className="col-md-12 mb-4">
                                                    <select name="property_state" className="form-control form-control-lg">
                                                        <option value="">Select Property State</option>
                                                    </select>
                                                </div>

                                                <div className="input-group mb-3 ">
                                                    <span className="input-group-text bg-dark text-white"><b>£</b></span>
                                                    <input type="text" className="form-control text-center form-control-lg" placeholder="Input Market Price" />
                                                </div>

                                                <div className="input-group mb-3 ">
                                                    <input type="text" className="form-control text-center form-control-lg" placeholder="Select Purchase Date" />
                                                    <span className="input-group-text bg-dark text-white"><img src="./assets/images/support/calendar.png" height="30" alt="" /></span>
                                                </div>

                                                <div className="col-md-12 mb-4">
                                                    <input type="file" className="form-control form-control-lg text-center" placeholder="Input Name" />
                                                </div>
                                                <div className="col-md-12 mb-4">
                                                    <input type="text" className="form-control form-control-lg text-center" placeholder="Input Email" />
                                                </div>
                                                <div className="col-md-12 mb-4">
                                                    <input type="text" className="form-control form-control-lg text-center" placeholder="£80,00" />
                                                </div>

                                                <div className="text-center">
                                                    <button className="btn-mv btn btn-lg btn-primary">Find Out More</button>
                                                </div>
                                            </div>
                                            <p className="text-white">By completing this form, you consent to your data being used for the service requested for. <br /> See more in <a href="#" className="text--primary">Privacy</a>.</p>
                                        </section>
                                    </div>
                                    
                                    <div className="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                                        <div className="header my-auto pt-4">
                                            <h3 className="">Compare Sales Offers</h3>
                                        </div>

                                        <section className="content-comparison p-5">
                                            <div className="row">
                                                <div className="col-md-6 text-center">
                                                    <div className="form-row mb-4">
                                                        <div className="contents p-3">
                                                            <span>&nbsp;</span>
                                                        </div>
                                                    </div>
                                                    <div className="form-row mb-4">
                                                        <div className="contents bg-white p-3">
                                                            <span>Property Market Value (Advertised Price)</span>
                                                        </div>
                                                    </div>
                                                    <div className="form-row mb-4">
                                                        <div className="contents bg-white p-3">
                                                            <span>Offer Accepted</span>
                                                        </div>
                                                    </div>
                                                    <div className="form-row mb-4">
                                                        <div className="contents bg-white p-3">
                                                            <span>Post Survey Negotiations(2.5%)</span>
                                                        </div>
                                                    </div>
                                                    <div className="form-row mb-4">
                                                        <div className="contents bg-white p-3">
                                                            <span>Post Agent Fees/Marketing Fees(2%)</span>
                                                        </div>
                                                    </div>
                                                    <div className="form-row mb-4">
                                                        <div className="contents bg-white p-3">
                                                            <span>Legal Fees</span>
                                                        </div>
                                                    </div>
                                                    <div className="form-row mb-4">
                                                        <div className="contents bg-white p-3">
                                                            <span>Mortgage Payments</span>
                                                        </div>
                                                    </div>
                                                    <div className="form-row mb-4">
                                                        <div className="contents bg-white p-3">
                                                            <span>Utility Bills + Tax</span>
                                                        </div>
                                                    </div>
                                                    <div className="form-row mb-4">
                                                        <div className="contents bg-white p-3">
                                                            <span>Property Clearance</span>
                                                        </div>
                                                    </div>
                                                    <div className="form-row mb-4">
                                                        <div className="contents bg-white p-3">
                                                            <span>Money in the Bank</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-3 mb-4">
                                                    <div className="form-row mb-4">
                                                        <div className="contents bg-white p-3 text-center">
                                                            <span>DIY/AGENTS/BROKERS</span><br />
                                                        </div>
                                                    </div>
                                                    
                                                    <div className="form-row mb-4">
                                                        <div className="contents bg-white p-3 text-center">
                                                            <span><b>6-9 months</b></span>
                                                        </div>
                                                    </div>

                                                    <div className="form-row mb-4">
                                                        <div className="contents bg-white p-3">
                                                            <span className="amount text-center">120,000</span>
                                                        </div>
                                                    </div>
                                                    <div className="form-row mb-4">
                                                        <div className="contents bg-white p-3">
                                                            <span className="amount text-center">114,000</span>
                                                        </div>
                                                    </div>
                                                    <div className="form-row mb-4">
                                                        <div className="contents bg-white p-3">
                                                            <span className="amount text-center">111,150</span>
                                                        </div>
                                                    </div>
                                                    <div className="form-row mb-4">
                                                        <div className="contents bg-white p-3">
                                                            <span className="amount text-center">108,484.40</span>
                                                        </div>
                                                    </div>
                                                    <div className="form-row mb-4">
                                                        <div className="contents bg-white p-3">
                                                            <span className="amount text-center">106,00</span>
                                                        </div>
                                                    </div>
                                                    <div className="form-row mb-4">
                                                        <div className="contents bg-white p-3">
                                                            <span className="amount text-center">98,000</span>
                                                        </div>
                                                    </div>
                                                    <div className="form-row mb-4">
                                                        <div className="contents bg-white p-3">
                                                            <span className="amount text-center">95,200</span>
                                                        </div>
                                                    </div>
                                                    <div className="form-row mb-4">
                                                        <div className="contents bg-white p-3">
                                                            <span className="amount text-center">94,200</span>
                                                        </div>
                                                    </div>
                                                    <div className="form-row mb-4">
                                                        <div className="contents bg-white p-3">
                                                            <span className="currency">£</span>
                                                            <span className="amount text-center">95,200</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-3 mb-4">
                                                    <div className="form-row mb-4 text-center">
                                                        <div className="contents bg-primary p-3">
                                                            <span>TPV</span><br />
                                                        </div>
                                                    </div>
                                                    
                                                    <div className="form-row mb-4 text-center">
                                                        <div className="contents bg-primary p-3">
                                                            <span><b>14 Days</b></span>
                                                        </div>
                                                    </div>

                                                    <div className="form-row mb-4">
                                                        <div className="contents bg-primary p-3">
                                                            <span className="amount text-center">120,000</span>
                                                        </div>
                                                    </div>
                                                    <div className="form-row mb-4">
                                                        <div className="contents bg-primary p-3">
                                                            <span className="amount text-center">96,000</span>
                                                        </div>
                                                    </div>
                                                    <div className="form-row mb-4">
                                                        <div className="contents bg-primary p-3">
                                                            <span className="amount text-center">96,000</span>
                                                        </div>
                                                    </div>
                                                    <div className="form-row mb-4">
                                                        <div className="contents bg-primary p-3">
                                                            <span className="amount text-center">96,000</span>
                                                        </div>
                                                    </div>
                                                    <div className="form-row mb-4">
                                                        <div className="contents bg-primary p-3">
                                                            <span className="amount text-center">96,000</span>
                                                        </div>
                                                    </div>
                                                    <div className="form-row mb-4">
                                                        <div className="contents bg-primary p-3">
                                                            <span className="amount text-center">96,000</span>
                                                        </div>
                                                    </div>
                                                    <div className="form-row mb-4">
                                                        <div className="contents bg-primary p-3">
                                                            <span className="amount text-center">96,000</span>
                                                        </div>
                                                    </div>
                                                    <div className="form-row mb-4">
                                                        <div className="contents bg-primary p-3">
                                                            <span className="amount text-center">96,000</span>
                                                        </div>
                                                    </div>
                                                    <div className="form-row mb-4">
                                                        <div className="contents bg-primary p-3">
                                                            <span className="currency">£</span>
                                                            <span className="amount text-center">96,000</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>

                                    </div>
                                    
                                    <div className="tab-pane fade" id="pills-compare-rent-offers" role="tabpanel" aria-labelledby="pills-compare-rent-offers-tab">
                                        <div className="header my-auto pt-4">
                                            <h3 className="">Compare Rent Offers</h3>
                                        </div>

                                        <section className="content-comparison p-5">
                                            <div className="row">
                                                <div className="col-md-6 text-center">
                                                    <div className="form-row mb-4">
                                                        <div className="contents p-3">
                                                            <span>&nbsp;</span>
                                                        </div>
                                                    </div>
                                                    <div className="form-row mb-4">
                                                        <div className="contents bg-white p-3">
                                                            <span>Property Market Value (Advertised Price)</span>
                                                        </div>
                                                    </div>
                                                    <div className="form-row mb-4">
                                                        <div className="contents bg-white p-3">
                                                            <span>Offer Accepted</span>
                                                        </div>
                                                    </div>
                                                    <div className="form-row mb-4">
                                                        <div className="contents bg-white p-3">
                                                            <span>Post Survey Negotiations(2.5%)</span>
                                                        </div>
                                                    </div>
                                                    <div className="form-row mb-4">
                                                        <div className="contents bg-white p-3">
                                                            <span>Post Agent Fees/Marketing Fees(2%)</span>
                                                        </div>
                                                    </div>
                                                    <div className="form-row mb-4">
                                                        <div className="contents bg-white p-3">
                                                            <span>Legal Fees</span>
                                                        </div>
                                                    </div>
                                                    <div className="form-row mb-4">
                                                        <div className="contents bg-white p-3">
                                                            <span>Mortgage Payments</span>
                                                        </div>
                                                    </div>
                                                    <div className="form-row mb-4">
                                                        <div className="contents bg-white p-3">
                                                            <span>Utility Bills + Tax</span>
                                                        </div>
                                                    </div>
                                                    <div className="form-row mb-4">
                                                        <div className="contents bg-white p-3">
                                                            <span>Property Clearance</span>
                                                        </div>
                                                    </div>
                                                    <div className="form-row mb-4">
                                                        <div className="contents bg-white p-3">
                                                            <span>Money in the Bank</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-3 mb-4">
                                                    <div className="form-row mb-4">
                                                        <div className="contents bg-white p-3 text-center">
                                                            <span>DIY/AGENTS/BROKERS</span><br />
                                                        </div>
                                                    </div>
                                                    
                                                    <div className="form-row mb-4">
                                                        <div className="contents bg-white p-3 text-center">
                                                            <span><b>6-9 months</b></span>
                                                        </div>
                                                    </div>

                                                    <div className="form-row mb-4">
                                                        <div className="contents bg-white p-3">
                                                            <span className="amount text-center">120,000</span>
                                                        </div>
                                                    </div>
                                                    <div className="form-row mb-4">
                                                        <div className="contents bg-white p-3">
                                                            <span className="amount text-center">114,000</span>
                                                        </div>
                                                    </div>
                                                    <div className="form-row mb-4">
                                                        <div className="contents bg-white p-3">
                                                            <span className="amount text-center">111,150</span>
                                                        </div>
                                                    </div>
                                                    <div className="form-row mb-4">
                                                        <div className="contents bg-white p-3">
                                                            <span className="amount text-center">108,484.40</span>
                                                        </div>
                                                    </div>
                                                    <div className="form-row mb-4">
                                                        <div className="contents bg-white p-3">
                                                            <span className="amount text-center">106,00</span>
                                                        </div>
                                                    </div>
                                                    <div className="form-row mb-4">
                                                        <div className="contents bg-white p-3">
                                                            <span className="amount text-center">98,000</span>
                                                        </div>
                                                    </div>
                                                    <div className="form-row mb-4">
                                                        <div className="contents bg-white p-3">
                                                            <span className="amount text-center">95,200</span>
                                                        </div>
                                                    </div>
                                                    <div className="form-row mb-4">
                                                        <div className="contents bg-white p-3">
                                                            <span className="amount text-center">94,200</span>
                                                        </div>
                                                    </div>
                                                    <div className="form-row mb-4">
                                                        <div className="contents bg-white p-3">
                                                            <span className="currency">£</span>
                                                            <span className="amount text-center">95,200</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-3 mb-4">
                                                    <div className="form-row mb-4 text-center">
                                                        <div className="contents bg-primary p-3">
                                                            <span>TPV</span><br />
                                                        </div>
                                                    </div>
                                                    
                                                    <div className="form-row mb-4 text-center">
                                                        <div className="contents bg-primary p-3">
                                                            <span><b>14 Days</b></span>
                                                        </div>
                                                    </div>

                                                    <div className="form-row mb-4">
                                                        <div className="contents bg-primary p-3">
                                                            <span className="amount text-center">120,000</span>
                                                        </div>
                                                    </div>
                                                    <div className="form-row mb-4">
                                                        <div className="contents bg-primary p-3">
                                                            <span className="amount text-center">96,000</span>
                                                        </div>
                                                    </div>
                                                    <div className="form-row mb-4">
                                                        <div className="contents bg-primary p-3">
                                                            <span className="amount text-center">96,000</span>
                                                        </div>
                                                    </div>
                                                    <div className="form-row mb-4">
                                                        <div className="contents bg-primary p-3">
                                                            <span className="amount text-center">96,000</span>
                                                        </div>
                                                    </div>
                                                    <div className="form-row mb-4">
                                                        <div className="contents bg-primary p-3">
                                                            <span className="amount text-center">96,000</span>
                                                        </div>
                                                    </div>
                                                    <div className="form-row mb-4">
                                                        <div className="contents bg-primary p-3">
                                                            <span className="amount text-center">96,000</span>
                                                        </div>
                                                    </div>
                                                    <div className="form-row mb-4">
                                                        <div className="contents bg-primary p-3">
                                                            <span className="amount text-center">96,000</span>
                                                        </div>
                                                    </div>
                                                    <div className="form-row mb-4">
                                                        <div className="contents bg-primary p-3">
                                                            <span className="amount text-center">96,000</span>
                                                        </div>
                                                    </div>
                                                    <div className="form-row mb-4">
                                                        <div className="contents bg-primary p-3">
                                                            <span className="currency">£</span>
                                                            <span className="amount text-center">96,000</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Support;