import './landlord.css'
import image from '../../assets/images/Frame 257.svg'
const LandlordCards = ({text}) => {
  return (
    <div className="landlord-card">
        <div className='card-header'>
            <img src={image} alt='small image'/>
        </div>
        <div className='card-text'>
            <span>{text}</span>
        </div>
    </div>
  )
}

export default LandlordCards
