import React, { useState } from "react";
import "./WhatsIncluded.css";
import truck from "../../assets/images/moving-truck.png";
import shackle from "../../assets/images/shackle.png";
import diamond from "../../assets/images/diamond.png";
import testament from "../../assets/images/testament.png";
import debt from "../../assets/images/debt.png";
import prop from "../../assets/images/prop.png";
import pension from "../../assets/images/pension.png";
import fast from "../../assets/images/fast.png";
import loan from "../../assets/images/loan.png";
import confirmation from "../../assets/images/confirmationScreen.png";

function WhatsIncluded() {
  const [selectedOption, setSelectedOption] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const [propertyAddress, setPropertyAddress] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState("");
  // const [isAddressDropdownOpen, setIsAddressDropdownOpen] = useState(false);
  const [name, setName] = useState(""); // Define the 'name' state variable
  const [email, setEmail] = useState(""); // Define the 'email' state variable

  // Function to handle the button click and open the modal
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  // Function to handle closing the modal
  const handleCloseModal = () => {
    setSelectedOption("");
    setSelectedDate("");
    setSelectedAddress("");
    setIsModalOpen(false);
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleOptionSelection = (option) => {
    setSelectedOption(option);
    setIsDropdownOpen(false);
  };

  const handleDateClick = (event) => {
    event.target.type = "date";
  };

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  const handleNameChange = (event) => {
    const enteredName = event.target.value;
    const namePattern = /^[A-Za-z]+$/;

    // Remove numbers and special characters from the entered name
    const formattedName = enteredName.replace(/[^A-Za-z]/g, "");

    // Check if the formatted name matches the pattern (contains only alphabets)
    if (!namePattern.test(formattedName)) {
      // Invalid name format
      event.target.style.borderColor = "red";
      event.target.style.color = "red";
      setNameErrorMessage("Please enter a valid name with alphabets only");
    } else {
      // Valid name format
      event.target.style.borderColor = "initial";
      event.target.style.color = "initial";
      setNameErrorMessage("");
    }

    // Update the name state variable with the formatted name
    setName(formattedName);
  };

  const handleEmailChange = (event) => {
    const enteredEmail = event.target.value;
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Check if the entered email matches the pattern
    if (!emailPattern.test(enteredEmail)) {
      // Invalid email format
      event.target.style.borderColor = "red";
      event.target.style.color = "red";
      setEmailErrorMessage("Please enter a valid email: example@domain.com");
    } else {
      // Valid email format
      event.target.style.borderColor = "initial";
      event.target.style.color = "initial";
      setEmailErrorMessage("");
    }

    // Update the email state variable
    setEmail(enteredEmail);
  };


  const handleAddressChange = (event) => {
    setPropertyAddress(event.target.value);
  };

  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [nameErrorMessage, setNameErrorMessage] = useState("");

  const handleSubmit = () => {
    setShowConfirmation(true);
    handleCloseModal();

    const emailContent = `
    Request Quick Offer
    I am: ${selectedOption}
    Completion date: ${selectedDate}
    Property Address: ${propertyAddress}
    Name: ${name}
    Email: ${email}
  `;

    const mailtoLink = `mailto:tpv@xcelsz.com?subject=Quick Offer Request&body=${encodeURIComponent(
      emailContent
    )}`;

    window.location.href = mailtoLink;
  };

  return (
    <>
      <div className="container-whats-included">
        <section className="whats_included">
          <h1 className="heading_whats_included">
            Rent or Sell Your Home
            <br /> Skip the <span style={{ color: "#fac710" }}>Hard Parts</span>
          </h1>

          <p className="cut_text">
            We cut out the unnecessary steps to <br /> make selling or renting
            your distressed <br /> home easy, fast, and stress-free.
          </p>

          <div className="mobile-image"> </div>

          <p className="support_text">
            We can support with any
            <br /> <span className="circumstance">circumstance</span>
            <span className="dotts" style={{ color: "#fac710" }}>
              ....
            </span>
          </p>

          <div className="container">
            <div className="form-picture">
              {/* <a href="#" style= {{ color: "black" }}> */}
              <div
                className="process"
                style={{ position: "relative", right: "3.5rem" }}
              >
                <img src={truck} alt="form" />
                <h2 className="box-header">Moving Abroad</h2>
              </div>
              {/* </a> */}
            </div>

            <div className="negotiation">
              <div className="process">
                <img src={shackle} alt="negotiation" />
                <h2 className="box-header">Stuck in a Chain</h2>
                {/* <p>
                  Receive an offer for your <br /> property within 72 hours
                </p> */}
              </div>
            </div>

            <div className="price">
              <div
                className="process"
                style={{ position: "relative", right: "-3.2rem" }}
              >
                <img src={diamond} alt="price" />
                <h2 className="box-header">Divorce or Separation</h2>
                {/* <p>
                  Accept Offer, sit back, <br /> enjoy money in the bank.
                </p> */}
              </div>
            </div>
          </div>
        </section>

        <section className="make-me-an-offer">
          <div className="quick-offer-box">
            <h3>Choose the completion date that suits you</h3>
            <button className="btnnn" onClick={handleOpenModal}>
              <p className="offer">Request Quick Offer</p>
            </button>
          </div>
        </section>

        {isModalOpen && (
          <div className="modal">
            <div className="modal-content">
              <span className="close" onClick={handleCloseModal}>
                &times;
              </span>
              <div className="modal-top">Quick Offer Request</div>
              <div className="modal-inputs">
                <div className="dropdown">
                  <input
                    type="text"
                    value={selectedOption}
                    onChange={handleOptionChange}
                    onClick={() => setIsDropdownOpen(true)}
                    className="title"
                    placeholder="I am"
                    readOnly
                  />
                  {isDropdownOpen && (
                    <ul className="dropdown-options">
                      <li onClick={() => handleOptionSelection("Landlord")}>
                        Landlord
                      </li>
                      <li onClick={() => handleOptionSelection("Home Seller")}>
                        Home Seller
                      </li>
                      <li
                        onClick={() =>
                          handleOptionSelection("Financial Difficulty Owner")
                        }
                      >
                        Financial Difficulty Owner
                      </li>
                      <li
                        onClick={() =>
                          handleOptionSelection("Property Developer")
                        }
                      >
                        Property Developer
                      </li>
                      <li
                        onClick={() =>
                          handleOptionSelection("Property Professional")
                        }
                      >
                        Property Professional
                      </li>
                      <li
                        onClick={() =>
                          handleOptionSelection("Financial Institution")
                        }
                      >
                        Financial Institution
                      </li>
                    </ul>
                  )}
                </div>

                <input
                  type="text"
                  value={selectedDate}
                  onClick={handleDateClick}
                  onChange={handleDateChange}
                  className="calendar"
                  placeholder="Select Completion date"
                ></input>

                <input
                  type="text"
                  value={propertyAddress}
                  onChange={handleAddressChange}
                  className="property_address"
                  placeholder="Enter Property Address or Postcode"
                ></input>

                <input
                  type="text"
                  placeholder="Enter Name"
                  className="name"
                  value={name} // Bind the 'name' state variable to the input value
                  onChange={handleNameChange} // Handle name input change
                />
                 
                 <input
                  type="text"
                  placeholder="Enter Email"
                  className="email"
                  value={email}
                  onChange={handleEmailChange}
                />
                 <div className="error-message">{emailErrorMessage}</div>
                <input
                  type="submit"
                  className="submit_btn"
                  onClick={handleSubmit}
                />
              </div>
            </div>
          </div>
        )}

        {showConfirmation && (
          <div className="confirmation-modal">
            <span className="close" onClick={() => setShowConfirmation(false)}>
              &times;
            </span>
            <img
              src={confirmation}
              alt="Confirmation"
              className="confirmation-image"
              onClick={() => setShowConfirmation(false)}
            />
          </div>
        )}

        <section className="choice">
          <h1
            style={{
              textAlign: "center",
              fontWeight: "bold",
              fontFamily: "Arial, Helvetica, sans-serif",
            }}
          >
            It doesn't matter the situation, <br />
            Get money{" "}
            <span
              className=""
              style={{
                color: "#fac710",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              FAST
            </span>{" "}
            in the bank
          </h1>

          <div className="master-container">
            <div
              className="card-border"
              style={{ position: "relative", left: "-18px" }}
            >
              <div className="number">1</div>
              <div className="card-image">
                <img src={testament} alt="to-do" />
              </div>
              <div className="image-text">
                Inherited bad <br /> Property
              </div>
            </div>

            <div
              className="card-border"
              style={{
                borderColor: "white",
                position: "relative",
                left: "15px",
              }}
            >
              <div className="number">2</div>
              <div className="card-image">
                <img src={debt} alt="housing" />
              </div>
              <div className="image-text">
                Stopping
                <br />
                Reprogression
              </div>
            </div>

            <div
              className="card-border"
              style={{ position: "relative", left: "39px" }}
            >
              <div className="number">3</div>
              <div className="card-image">
                <img src={prop} alt="savannah" />
              </div>
              <div className="image-text">Tired Property</div>
            </div>
          </div>

          <div className="second">
            <div
              className="card-border"
              style={{ position: "relative", left: "-18px" }}
            >
              <div className="number">4</div>
              <div className="card-image">
                <img src={pension} alt="to-do" />
              </div>
              <div className="image-text">
                Retirement or
                <br /> drownsizing
              </div>
            </div>

            <div
              className="card-border"
              style={{
                borderColor: "white",
                position: "relative",
                left: "15px",
              }}
            >
              <div className="number">5</div>
              <div className="card-image">
                <img src={fast} alt="fee" />
              </div>
              <div className="image-text">Need Quick Cash</div>
            </div>

            <div
              className="card-border"
              style={{ position: "relative", left: "39px" }}
            >
              <div className="number">6</div>
              <div className="card-image">
                <img src={loan} alt="customer" />
              </div>
              <div className="image-text">Negative Equity</div>
            </div>
          </div>
        </section>
      </div>

      <div className="line-segment"></div>
    </>
  );
}

export default WhatsIncluded;
