export const pageData = [
    {
        card: "caseStudies",
        data: [
            {
                number: "1",
                description: "Atif was able to keep his Property Owners Happy",
                problem: "Agent needed to convert quickly to keep their property owners satisfied.",
                solution: "Guaranteed Rent over 5 years with 30 day on request with no VOIDS offered for 2 properties",
                year: "2019",
            },
            {
                number: "2",
                description: "Albert became hands-off Landlord using  TPV",
                problem: "Landlord has bad tenant and struggled to find the perfect tenant, with peace of mind and little work.",
                solution: "Guaranteed Rent offer over 3.5 years within 30 day on request with no Voids offered.",
                year: "2023",
            },
            {
                number: "3",
                description: "John was able to move smoothly abroad last minute with TPV",
                problem: "Landlord needed to relocate abroad last minute and was looking for someone to take on their property",
                solution: "Guaranteed Rent over 5 years offer within 48 days",
                year: "2018",
            },
            {
                number: "4",
                description: "Gavin saved himself the hassle and Commission Fees using TPV",
                problem: "Landlord was looking for Hassle-free Letting and save money and time to spend on things that matters most to him",
                solution: "Guaranteed Rent over 5 years offer within 30 days, and re-fresh done",
                year: "2018",
            },
            {
                number: "5",
                description: "Brandon was able to be stationed outside Scotland with a peace of mind",
                problem: "Landlord was going away on assignment and needed a perfect tenant that guaranteed a peace of mind.",
                solution: "Guaranteed Rent over 3 years offer within a week based on owner request",
                year: "2018",
            },
            {
                number: "6",
                description: "Vimal become hands- off Landlord using  TPV",
                problem: "Landlord needed someone to assist him to rent out his property given they were new the market.",
                solution: "Assisted Rent solution over 3 years offered and hold Owners hands until they got on their feet",
                year: "2018",
            },
            {
                number: "7",
                description: "Pastor Seth  was able to get money quick in the bank",
                problem: "Property Owner had some debts to settle and wanted to sell their uncompleted property quick.",
                solution: "Closed the property and money in the pocket within a week on Owner’s request",
                year: "2017",
            },
            {
                number: "8",
                description: "Kwasi was able to settle some debt quickly",
                problem: "Property Owner had some debts to settle and wanted to sell their property quick.",
                solution: "Closed the property and money in the pocket within a 48 days",
                year: "2018",
            },
        ]
    },
    {
        card: "customerReviews",
        data: [
            {
                number: "01",
                name: "Brendon",
                address: "Glasgow (UK)",
                review: "TPV took over the management of my flat. They are competent and I felt assured leaving them to run the property."
            },
            {
                number: "02",
                name: "Gavin",
                address: "Glasgow (UK)",
                review: "TPV made me market rent offer for my Apartment. They saved me commissions payment, and I end up with higher net cashflow."   
            }
        ]
    },
    // REPLACE WITH CORRECT DATA
    {
        card: "beforeAndAfter",
        data: [
            {
                title: "Legal Fees2",
                leftContent: "Additional\nCharge 2",
                rightContent: "1-10% or more of sales/rental price2"
            },
            {
                title: "Completion2",
                leftContent: "Additional\nCharge2",
                rightContent: "No commission payment or fee2"
            },
            {
                title: "Commission2",
                leftContent: "Within 3-6 months or more22",
                rightContent: "Within 7-14 days guaranteed2"
            }
        ]
    }
]


export const pageHeaders = [
   
    {
        card: "caseStudies",
        heading: "Read what others are getting with the help of TPV",
        title: "Read case studies"
    },
    {
        card: "customerReviews",
        heading: "Read what others are saying about thier experience with TPV",
        title: "Customer Experiences"
    },        
    {
        card: "beforeAndAfter",
        heading: "Seeing is believing. See how TPV uses properties",
        title: "Rent Fast Offer, Glasgow(UK)"
    }
]


// export const caseStudiesData = [
//     {
//         number: "1",
//         description: "Atif was able to keep his Property Owners Happy",
//         problem: "Agent needed to convert quickly to keep their property owners satisfied.",
//         solution: "Guaranteed Rent over 5 years with 30 day on request with no VOIDS offered for 2 properties",
//         year: "2019",
//     },
//     {
//         number: "2",
//         description: "Albert became hands-off Landlord using  TPV",
//         problem: "Landlord has bad tenant and struggled to find the perfect tenant, with peace of mind and little work.",
//         solution: "Guaranteed Rent offer over 3.5 years within 30 day on request with no Voids offered.",
//         year: "2023",
//     },
//     {
//         number: "3",
//         description: "John was able to move smoothly abroad last minute with TPV",
//         problem: "Landlord needed to relocate abroad last minute and was looking for someone to take on their property",
//         solution: "Guaranteed Rent over 5 years offer within 48 days",
//         year: "2018",
//     },
//     {
//         number: "4",
//         description: "Gavin saved himself the hassle and Commission Fees using TPV",
//         problem: "Landlord was looking for Hassle-free Letting and save money and time to spend on things that matters most to him",
//         solution: "Guaranteed Rent over 5 years offer within 30 days, and re-fresh done",
//         year: "2018",
//     },
//     {
//         number: "5",
//         description: "Brandon was able to be stationed outside Scotland with a peace of mind",
//         problem: "Landlord was going away on assignment and needed a perfect tenant that guaranteed a peace of mind.",
//         solution: "Guaranteed Rent over 3 years offer within a week based on owner request",
//         year: "2018",
//     },
//     {
//         number: "6",
//         description: "Vimal become hands- off Landlord using  TPV",
//         problem: "Landlord needed someone to assist him to rent out his property given they were new the market.",
//         solution: "Assisted Rent solution over 3 years offered and hold Owners hands until they got on their feet",
//         year: "2018",
//     },
//     {
//         number: "7",
//         description: "Pastor Seth  was able to get money quick in the bank",
//         problem: "Property Owner had some debts to settle and wanted to sell their uncompleted property quick.",
//         solution: "Closed the property and money in the pocket within a week on Owner’s request",
//         year: "2017",
//     },
//     {
//         number: "8",
//         description: "Kwasi was able to settle some debt quickly",
//         problem: "Property Owner had some debts to settle and wanted to sell their property quick.",
//         solution: "Closed the property and money in the pocket within a 48 days",
//         year: "2018",
//     },
// ]

// export const customerReviewsData = [
//     {
//         number: "01",
//         name: "Brendon",
//         address: "Glasgow (UK)",
//         review: "TPV took over the management of my flat. They are competent and I felt assured leaving them to run the property."
//     },
//     {
//         number: "02",
//         name: "Gavin",
//         address: "Glasgow (UK)",
//         review: "TPV made me market rent offer for my Apartment. They saved me commissions payment, and I end up with higher net cashflow."   
//     }
// ]